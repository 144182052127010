<template>
  <div class="step" :class="{ active }" :style="cssProps">
    <figure
      v-if="first || (!first && !last)"
      class="step__line"
      :class="{ active }"
    />
    <span>{{ pos }}</span>
  </div>
</template>

<script>
export default {
  props: {
    first: { type: Boolean, default: false },
    last: { type: Boolean, default: false },
    type: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    height: { type: String, default: "2em" },
    pos: Number,
  },
  computed: {
    cssProps() {
      return {
        '--step-height': this.height,
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/_variables.scss";

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd9e7;
  width: 3.125rem;
  height: 3.125rem;
  color: #8f81b2;
  font-weight: 700;
  font-size: 1.5rem;
  position: relative;
  background: url("/images/hexagon.svg") no-repeat;
  background-position: center;
  background-size: 100% 100%;
  transition: all .35s;
  &.active {
    background: url("/images/hexagon-active.svg") no-repeat;
    background-position: center;
    background-size: 100% 100%;
    color: white;
  }

  &__line {
    width: 0.375rem;
    height: var(--step-height);
    background: #ddd9e7;
    position: absolute;
    top: 3rem;
    transition: all .35s;

    &.active {
      background: $main-color;
    }
  }
}
</style>