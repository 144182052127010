<template>
  <div class="kpis-screen">
    <h1>Resultados</h1>
    <table class="kpis-screen__kpis">
      <thead>
        <tr>
          <th>Data</th>
          <th style="text-align: right">Resultado</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(k, i) in kpis" :key="i">
          <td width="55%">{{ format(k.data, "dd/MM/yyyy") }}</td>
          <td width="45%" class="result">
            {{ k.result }}/{{ k.goal }}
            <span>{{ k.performance.toString().padStart(3, 0) }}%</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  name: "SummaryKpis",
  props: {
    resumeData: { type: Object, required: false }
  },
  data() {
    return {
      format: format, // I haven't discovered how to use imported functions directly in the template but this works
      kpis: [
        { data: new Date(), result: 100, goal: 300, performance: 0 },
        { data: new Date(), result: 100, goal: 300, performance: 0 },
        { data: new Date(), result: 100, goal: 300, performance: 0 },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.kpis-screen {
  display: flex;
  flex-direction: column;
  padding: 2.875rem;
  align-items: center;
  text-transform: uppercase;
  align-items: center;

  & > h1 {
    font-weight: 700;
    color: white;
    font-size: 1.75rem;
    margin-bottom: 2.625rem;
  }

  &__kpis {
    width: 36rem;
    border-collapse: separate;
    border-spacing: 0 0.625rem;

    & th {
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.01em;
      color: white;
      padding: 0.375rem 1.125rem;
      text-transform: uppercase;
    }
    & td {
      background: #ffffff;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.6875rem;
      color: #403c56;
      padding: 0.375rem 1.125rem;
    }
    & td.result {
      color: #ffffff;
      background: #221b38;
      text-align: right;
    }
    & td.result span {
      color: #3fc1d2;
      margin-left: 0.625rem;
    }
  }
}
</style>
