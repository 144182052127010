<template>
  <div
    class="wrapper"
    :class="{ locked: !active }"
    @click="
      $emit('clicked-content', {
        content_id: item.content_id,
        mission_id: item.mission_id,
      })
    "
  >
    <!-- <content-step :pos="pos + 1" :first="first" :last="last" :active="active" /> -->
    <content-step :pos="item.id + 1" :first="first" :last="last" :active="active" />
    <div class="line">
      <div class="line__details">
        <div class="line__details__type-image">
          <HtmlIcon
            v-if="['HTML', 'SCORM'].includes(item.type)"
            :color="active ? '#1EB28D' : undefined"
            class="line__details__type-image__svg"
          />

          <ImageIcon
            v-else-if="['IMAGEM', 'IMAGE'].includes(item.type)"
            :color="active ? '#1EB28D' : undefined"
            class="line__details__type-image__svg"
          />

          <VideoIcon
            v-else-if="['VIDEO'].includes(item.type)"
            :color="active ? '#1EB28D' : undefined"
            class="line__details__type-image__svg"
          />

          <PdfIcon
            v-else-if="['PDF'].includes(item.type)"
            :color="active ? '#1EB28D' : undefined"
            class="line__details__type-image__svg"
          />
          
          <QuizzIcon
            v-else-if="['QUIZZ'].includes(item.type)"
            :color="active ? '#1EB28D' : undefined"
            class="line__details__type-image__svg"
          />

          <ContentIcon
            v-else
            :color="active ? '#1EB28D' : undefined"
            class="line__details__type-image__svg"
          />

          <span :class="{ active: active }">
            {{ item.type }}
          </span>
        </div>
        <div class="line__details__info">
          <img
            :src="
              getMissionImageUrl(item) ||
              require('../../assets/images/content/default_content.svg')
            "
            alt=""
          />
          <span>{{ item.content_title }}</span>
        </div>
      </div>
      <div class="line__locked">
        <img
          v-if="item.locked"
          src="../../assets/images/content/locked.svg"
          alt="bloqueado"
        />
        <img
          v-else
          src="../../assets/images/content/check.svg"
          alt="desbloqueado"
        />
        <div class="line__locked__info">
          <span class="line__locked__info__status">{{
            item.locked ? "Bloqueado" : "Desbloqueado"
          }}</span>
          <span class="line__locked__info__date">{{
            !item.locked ? item.date || "-" : "-"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HtmlIcon from "../Utils/Icons/HtmlIcon.vue";
import ContentIcon from "../Utils/Icons/ContentIcon.vue";
import VideoIcon from "../Utils/Icons/VideoIcon.vue";
import ImageIcon from "../Utils/Icons/ImageIcon.vue";
import PdfIcon from "../Utils/Icons/PdfIcon.vue";
import QuizzIcon from "../Utils/Icons/QuizzIcon.vue";
import ContentStep from "./ContentStep.vue";
export default {
  components: {
    HtmlIcon,
    ContentIcon,
    VideoIcon,
    ImageIcon,
    PdfIcon,
    ContentStep,
  },
  props: {
    active: { type: Boolean, default: false },
    item: Object,
    pos: Number,
    first: { type: Boolean, default: false },
    last: { type: Boolean, default: false },
  },
  data() {
    return {
      backOfficeUrl: process.env.VUE_APP_BO_BASE_URL,
    };
  },
  methods: {
    getMissionImageUrl(mission) {
      if (mission.imageUrl === null || mission.imageUrl === "") return null;
      else return this.backOfficeUrl + "/storage/campaigns/" + mission.imageUrl;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/_variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.18rem;
  cursor: pointer;
}
.wrapper.locked {
  pointer-events: none;
  cursor: not-allowed !important;
}

.line {
  background-color: white;
  padding: 1.7rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__details {
    display: flex;
    gap: 3.62rem;
    align-items: center;

    &__type-image {
      width: 2.8rem;
      height: 2.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & > svg {
        flex-shrink: 0;
        width: 2rem;
      }

      & > span {
        font-weight: 700;
        font-size: 0.875rem;
        text-transform: uppercase;
        color: #8f81b2;
      }

      & > span.active {
        color: $main-color;
      }
    }

    &__info {
      display: flex;
      justify-content: center;
      gap: 1.125rem;

      & > img {
        width: 6.8rem;
        height: 3.75rem;
        background-color: #0e1a2e;
      }

      & > span {
        font-size: 1.25rem;
        font-weight: 700;
      }
    }
  }

  &__locked {
    display: flex;
    align-items: center;
    gap: 1rem;
    min-width: 11rem;

    & > img {
      width: 2.3rem;
      height: 2.3rem;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 0.875rem;

      &__status {
        font-weight: 700;
        color: black;
        text-transform: uppercase;
      }

      &__date {
        font-weight: 400;
        color: #403c56;
      }
    }
  }
}
</style>
