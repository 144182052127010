<template>
  <div class="wrapper">
    <h4>Últimos 3 a visualizar</h4>
    <div class="users">
      <div class="user" v-for="(u, i) in users" :key="i">
        <img src="@/assets/images/badges/hero_placeholder.svg" alt="user image" />
        <div class="user__info">
          <p class="user__info__name">{{ u.name }}</p>
          <p class="user__info__team">{{ u.team }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [
        { name: "Ivo Jaime", team: "Nome da equipa" },
        { name: "Ivo Jaime", team: "Nome da equipa" },
        { name: "Ivo Jaime", team: "Nome da equipa" },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  display: flex;
  background-color: #221b38;
  box-shadow: 0.25rem 0.1875rem 0rem #8a69ca;
  min-width: 18.25rem;
  height: fit-content;
  padding: 0.625rem 1.125rem 56px 1.125rem;
  flex-direction: column;

  h4 {
    font-weight: 700;
    font-size: 1.5rem;
    color: white;
    margin-bottom: 1.875rem;
  }

  .users {
    display: flex;
    gap: 1.375rem;
    flex-direction: column;

    .user {
      display: flex;
      align-items: center;
      gap: 1.375rem;

      img {
        width: 1.875rem;
        height: 1.875rem;
      }

      &__info {
        letter-spacing: 0.01em;
        color: white;

        &__name {
          font-weight: 700;
          font-size: 18px;
        }

        &__team {
          font-weight: 400;
          font-size: 0.8125rem;
        }
      }
    }
  }
}
</style>
