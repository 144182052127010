<template>

  <div class="product-component box box-product has-text-centered">
    <div class="img-container">
      <img :src="backOfficeUrl + '/storage/products/' + product.image" alt="">
    </div>
    <h3 class="product-title">{{ product.name }}</h3>

    <div v-if="!acquired && product.stock > 0" style="width: 100%; height: 100%; justify-content: center; align-items: center; gap: 10px; display: inline-flex; margin-bottom: 20px">
      <div style="justify-content: flex-start; align-items: center; gap: 5px; display: flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
          <path d="M6.00001 0.766602L11.5479 4.2666V11.2666L6.00001 14.7666L0.452132 11.2666V4.2666L6.00001 0.766602Z" fill="#1EB28D"/>
        </svg>
        <div style="text-align: center; color: white; font-size: 14px; font-family: Oswald; font-weight: 700; text-transform: uppercase; letter-spacing: 0.14px; word-wrap: break-word">em stock</div>
      </div>
      <div style="height: 21px; justify-content: flex-start; align-items: center; gap: 10px; display: flex">
        <div style="color: white; font-size: 14px; font-family: Oswald; font-weight: 700; text-transform: uppercase; word-wrap: break-word">({{ product.stock }})</div>
      </div>
    </div>

    <div v-if="!acquired && product.stock <= 0" style="width: 100%; height: 100%; justify-content: center; align-items: center; gap: 10px; display: inline-flex; margin-bottom: 20px">
      <div style="justify-content: flex-start; align-items: center; gap: 5px; display: flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
          <path d="M6.00001 0.766602L11.5479 4.2666V11.2666L6.00001 14.7666L0.452132 11.2666V4.2666L6.00001 0.766602Z" fill="#EF375D"/>
        </svg>
        <div style="text-align: center; color: white; font-size: 14px; font-family: Oswald; font-weight: 700; text-transform: uppercase; letter-spacing: 0.14px; word-wrap: break-word">Esgotado</div>
      </div>
    </div>

    <BuyButton v-if="!acquired" :isExpensive="!canPurchase(product)" :disabled="!canPurchase(product)" :price="parseFloat(product.points)" :acquired="acquired"
      @click.native="$emit('purchase', product)">
      Comprar
    </BuyButton>

    <div v-if="acquired" class="status-container">
      <span class="status-subtitle">Estado</span>
      <span class="status" :class="product.getStatusText().toLowerCase()">{{ product.getStatusText() }}</span>
    </div>
    <div v-if="acquired" class="purchase-date">
      <span>{{ statusDate }}</span>
    </div>

  </div>

</template>
<script>
import { mapGetters } from 'vuex';
import BuyButton from "../Utils/BuyButton.vue";
import { format, parse } from "date-fns";

export default {
  components: { BuyButton },
  name: "ProductComponent",
  data() {
    return {
      backOfficeUrl: process.env.VUE_APP_BO_BASE_URL
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    statusDate() {
      return format(parse(this.product.status_date, "yyyy-MM-dd HH:mm:ss", new Date()), "dd/MM/yyyy");
    }
  },
  props: {
    product: Object,
    acquired: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    canPurchase(product) {
      return product.stock > 0 && this.userProfile.points_sum >= parseFloat(product.points);
    }
  }
}
</script>

<style scoped>
.product-component {
  height: 100%;
  border-radius: 0;
  background-color: #221B38;
  padding: 1.25rem 1.25rem 2.5rem;
  box-shadow: 0.25rem 0.1875rem #8A69CA;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.group-user-5-combo {
  margin-top: 0.625rem;
  justify-content: center;
}

.group-user-5-combo div:not(:first-child) {
  margin-left: -0.5rem;
}

.img-container {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12rem;
}

.img-container img {
  height: 12rem;
}

@media(max-width: 48rem) {
  .img-container {
    width: 15rem;
    margin: 0 auto;
  }

  .img-container img {
    height: 15rem;
  }
}

.product-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.6875rem;
  letter-spacing: 0.01em;
  margin-bottom: 0.8125rem;
  margin-top: 0.625rem;
}

.product-description {
  color: #fff;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  letter-spacing: 0.01em;
  margin-bottom: 2.5rem;
}

.top {
  color: #8F81B2;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  letter-spacing: 0.01em;
}

.status-subtitle {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-right: 0.625rem;
}

.status {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  text-transform: uppercase;
  color: #8F81B2;
}

.status.entregue {
  color: #1EB28D;
}

.status.rejeitada {
  color: #F53D48;
}

.status.aceite {
  color: #3FC1D2;
}

.purchase-date {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  margin-top: 0.625rem;
}

</style>