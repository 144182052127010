<template>
  <div class="hero-container">
    <div class="hero-images" :class="{ 'invert-image': invert }" ref="heroImages">
      <div class="hero-image-main" ref="heroMain">
        <avatar
          :gear="
            !inLounge && getUserEquippedGear.full_profile_picture ? getUserEquippedGear.full_profile_picture : avatar
          "
        />
      </div>
      <img
        class="hero-image-normal"
        v-show="percentage < 100"
        :src="`/heros/${defaultAvatar}.svg`"
        alt="hero1 normal"
        ref="heroNormal"
      />
      <img
        class="hero-image-outline"
        v-show="percentage > 100"
        src="../../../assets/images/Heros/hero1-outline.svg"
        alt="hero1 outline"
      />
      <img
        class="hero-divider"
        v-show="percentage > 0 && percentage < 100"
        src="../../../assets/images/Heros/hero-divider.svg"
        alt="hero-divider"
        ref="heroDivider"
      />
    </div>
    <div v-if="!inLounge" class="bar-container">
      <div class="bar">
        <img class="bar-icon" src="../../../assets/images/Heros/power-icon.svg" alt="power icon" />
        <div class="bar-background" ref="powerBar" />
        <div class="bar-percentage" ref="powerBarPercentage" />
      </div>
      <div class="bar">
        <img class="bar-icon" src="../../../assets/images/Heros/health-icon.svg" alt="health icon" />
        <div class="bar-background health-bar" ref="health" />
        <div class="bar-percentage health-bar health-bar-percentage" ref="healthPercentage" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AvatarService from "../../../services/avatar.service";
import Avatar from "../../Profile/Avatar.vue";

export default {
  components: { Avatar },
  props: {
    percentage: { type: Number, required: true },
    health: { type: Number, default: 0 },
    inLounge: { type: Boolean, default: false },
    invert: { type: Boolean, default: false },
    defaultAvatar: { type: String, default: "hero-dummy" },
    avatar: { type: String, default: null },
  },
  data() {
    return {
      windowHeight: window.innerHeight,
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowHeight);
    if (!this.getUserEquippedGear.full_profile_picture) {
      AvatarService.getGearItemsList()
        .then((response) => {
          this.$store.dispatch("user/updateUserEquippedGear", response.user_equipped_gear);
        })
        .catch((error) => {
          this.$noty.error(error.message);
        });
    }

    this.calculateEverything();
  },
  methods: {
    calculateEverything() {
      this.calculateHeroCut(this.$refs.heroMain, this.percentage);
      this.calculateHeroCut(this.$refs.heroNormal, this.percentage, false);
      this.calculateHeroDividerPos(this.percentage);
      if (!this.inLounge) {
        this.calculateBarHeight(this.$refs.powerBarPercentage, this.percentage);
        this.calculateBarHeight(this.$refs.healthPercentage, this.health);
      }
    },
    calculateHeroCut(elem, percentage, top = true) {
      if (top) elem.style["clip-path"] = `inset(${100 - percentage}% 0% 0% 0%)`;
      else elem.style["clip-path"] = `inset(0% 0% ${percentage}% 0%)`;
    },
    calculateHeroDividerPos(p) {
      p = 100 - p; // Invert percentage
      const heroImages = this.$refs.heroImages;
      const heroDivider = this.$refs.heroDivider;
      heroDivider.style["top"] = (heroImages.offsetHeight * p) / 100 - 1 + "px"; // Minus 1 because it's off by one pixel
    },
    calculateBarHeight(elem, percentage) {
      if (percentage > 100) percentage = 100;
      elem.style["height"] = (this.$refs.heroImages.offsetHeight * percentage) / 100 + "px";
    },
    updateWindowHeight() {
      this.windowHeight = window.innerHeight;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowHeight);
  },
  computed: {
    ...mapGetters("user", ["getUserEquippedGear"]),
  },
  watch: {
    windowHeight() {
      this.calculateEverything();
    },
  },
};
</script>

<style scoped lang="scss">
.hero-container {
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-end;
}

.hero-images {
  position: relative;
  height: 100%;
  width: 100%;
}

.hero-image-normal,
.hero-image-outline,
.hero-image-main {
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
}

.hero-image-main {
  z-index: 4;
}

.hero-image-normal {
  z-index: 3;
}

.hero-image-outline {
  z-index: 2;
}

.hero-divider {
  position: absolute;
  z-index: 5;
  left: 4.3rem;
  width: 75%;
  justify-self: center;
  pointer-events: none;
}

.bar-container {
  display: flex;
  gap: 0.3rem;
  height: 100%;
}

.bar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar-icon {
  filter: drop-shadow(0.125rem 0.125rem 0rem black);
  height: 0.8rem;
  width: 0.8rem;
  position: absolute;
  top: -1.1rem;
}

.bar-background {
  width: 1.2rem;
  background: url("../../../assets/images/Heros/bar-background.png");
  background-color: black;
  height: 100%;
}

.bar-percentage {
  position: absolute;
  bottom: 0;
}

.bar-percentage {
  background: #ba9dfe;
  width: 1.2rem;
}

.health-bar {
  width: 0.8rem;
}

.health-bar-percentage {
  background: #8b5ef4;
}

.invert-image {
  transform: scaleX(-1);
  left: 1.5rem;
}
</style>
