<template>
  <svg width="100%" height="100%" viewBox="0 0 30 25" version="1.1" :fill="color" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-16,0)">
      <rect x="29.93" y="14.006" width="1.807" height="10.994" />
      <path d="M16,10.27C16,9.638 16.632,9.201 17.224,9.425L28.745,13.786C29.096,13.919 29.328,14.255 29.328,14.631L29.328,23.692C29.328,24.325 28.696,24.761 28.105,24.538L16.584,20.176C16.232,20.043 16,19.707 16,19.331L16,10.27Z" />
      <path d="M45.668,10.27C45.668,9.638 45.036,9.201 44.445,9.425L32.923,13.786C32.572,13.919 32.34,14.255 32.34,14.631L32.34,23.692C32.34,24.325 32.972,24.761 33.563,24.538L45.084,20.176C45.436,20.043 45.668,19.707 45.668,19.331L45.668,10.27Z" />
      <path d="M18.805,8.371L29.33,13.328L19.334,7.374C19.18,7.283 18.991,7.27 18.826,7.339C18.376,7.529 18.362,8.162 18.805,8.371Z" />
      <path d="M21.225,7.13L29.708,13.102L21.842,6.338C21.722,6.235 21.56,6.194 21.405,6.229C20.979,6.323 20.868,6.879 21.225,7.13Z" />
      <path d="M24.135,6.262L30.006,12.952L24.784,5.744C24.706,5.636 24.581,5.572 24.447,5.572C24.09,5.572 23.9,5.993 24.135,6.262Z" />
      <path d="M42.641,8.371L32.115,13.328L42.111,7.374C42.265,7.283 42.454,7.27 42.619,7.339C43.07,7.529 43.083,8.162 42.641,8.371Z" />
      <path d="M40.22,7.13L31.737,13.102L39.603,6.338C39.724,6.235 39.885,6.194 40.04,6.229C40.466,6.323 40.577,6.879 40.22,7.13Z" />
      <path d="M37.31,6.262L31.439,12.952L36.661,5.744C36.739,5.636 36.865,5.572 36.998,5.572C37.355,5.572 37.546,5.993 37.31,6.262Z" />
      <path d="M30.759,10.542C33.67,10.542 36.03,8.182 36.03,5.271C36.03,2.36 33.67,0 30.759,0C27.848,0 25.488,2.36 25.488,5.271C25.488,8.182 27.848,10.542 30.759,10.542ZM30.759,9.187C32.88,9.187 34.6,7.467 34.6,5.346C34.6,3.225 32.88,1.506 30.759,1.506C28.638,1.506 26.919,3.225 26.919,5.346C26.919,7.467 28.638,9.187 30.759,9.187Z" />
      <path d="M29.882,6.927L29.882,6.014C30.094,5.848 30.285,5.664 30.455,5.461C30.625,5.259 30.76,5.044 30.859,4.817C30.958,4.586 31.007,4.348 31.007,4.102C31.007,3.928 30.974,3.803 30.908,3.727C30.847,3.647 30.755,3.607 30.632,3.607C30.519,3.607 30.429,3.64 30.363,3.706C30.302,3.772 30.271,3.864 30.271,3.982C30.271,4.058 30.278,4.126 30.293,4.187C30.311,4.244 30.333,4.305 30.356,4.371L29.316,4.612C29.278,4.499 29.247,4.39 29.224,4.286C29.205,4.178 29.195,4.065 29.195,3.947C29.195,3.531 29.32,3.208 29.571,2.977C29.825,2.741 30.189,2.623 30.661,2.623C31.137,2.623 31.503,2.748 31.758,2.998C32.017,3.248 32.147,3.605 32.147,4.067C32.147,4.35 32.1,4.619 32.005,4.874C31.911,5.129 31.772,5.369 31.588,5.596C31.409,5.822 31.189,6.035 30.93,6.233L30.93,6.927L29.882,6.927ZM29.847,8.434L29.847,7.337L30.972,7.337L30.972,8.434L29.847,8.434Z" />
    </g>
  </svg>
</template>

<script>
export default {
    props: { 
        color: { 
            type: String, 
            default: "#8F81B2" 
        } 
    },
};
</script>
<style scoped lang="scss">
</style>