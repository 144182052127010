<template>
    <div>
        <img v-if="mission.c_type === 'QUIZZ'" src="../../../assets/images/missions/icons/quizz.png" />
        <img v-else-if="['VIDEO', 'IMAGEM', 'TEXTO', 'PDF', 'SCORM'].includes(mission.c_type)" src="../../../assets/images/missions/icons/content.png" />
        <img v-else src="../../../assets/images/missions/icons/others.png" />
    </div>
</template>

<script>
export default {
    name: 'MissionIcon',
    props: { 
        mission: Object 
    },
}
</script>

