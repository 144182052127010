<template>
  <base-modal :show="show" maxWidth="500px" @close="handleClose">
    <template>
      <div class="body">
        <img src="../../assets/images/store/product_placeholder.png" alt="">
        <h3 class="product-title">{{ product.name }}</h3>
        <p class="product-description">
          {{ product.description }}
        </p>
        <div class="buy-btn" :class="{ acquired: acquired }" @click.once="$emit('confirmPurchase')">
          <span v-if="!acquired" class="buy-text">Comprar</span>
          <div class="price-tag">
            <img src="../../assets/images/store/currency_icon.svg" alt="">
            <span class="price">{{ parseInt(product.points) }}</span>
          </div>
        </div>
      </div>
    </template>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/BaseModal";

export default {
  name: "ProductDetailModal",
  components: { BaseModal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    acquired: {
        type: Boolean,
        default: false
    },
    product: {
        type: Object,
        required: true
    }
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.body {
    padding: 8px 20px;
    text-align: center;
}
.product-title {
  color: #403C56;
  font-weight: 500;
  font-size: 32px;
  line-height: 47px;
  letter-spacing: 0.01em;
  margin-bottom: 13px;
}
.product-description {
  color: #403C56;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.01em;
  margin-bottom: 40px;
}

.buy-btn {
  max-width: 220px;
  margin: 5px auto;
  padding: 7px;
  background: #6C4BAC;
  cursor: pointer;
  text-align: center;
}
.buy-btn.acquired {
  cursor: grab;
}
.buy-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  color: #FFFFFF;
  /* margin-bottom: 2px; */
}
.price-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8A69CA;
}
.price-tag .price {
  margin-left: 5px;
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  color: #ffffff;
}
</style>