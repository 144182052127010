<template>
  <section class="badges-screen">
    <div class="badges-screen__header">
      <h2>
        Total: <em class="counter">{{ new String(owned_badges).padStart(3, "0") }}</em>
      </h2>
    </div>
    <div class="badges-screen__container">
      <div class="badges-screen__container__badge" v-for="badge in badges" :key="badge.id">
        <badge-component :badge="badge"/>
      </div>
    </div>
  </section>
</template>
<script>
import BadgeComponent from "@/components/Badges/Badge";
import AchievementsService from "@/services/achievements.service";

import { mapGetters } from "vuex";

export default {
  name: "BadgesScreen",
  components: {
    BadgeComponent,
  },
  props: [],
  data() {
    return {
      badges: [],
      loading: false,
      owned_badges: 0,
    };
  },
  computed: {
    ...mapGetters("user", ["authUser"]),
  },
  created() {
    this.fetchBadges();
  },
  mounted() {},
  methods: {
    async fetchBadges() {
      this.loading = true;
      // this.$store.dispatch("achievements/getList");

      AchievementsService.getAllBadges()
        .then((resp) => {
          this.badges = resp;

          let user_badges_count = resp.reduce((acc, badge) => {
            // console.log("badge");
            // console.log(badge);
            if (badge.user_has_badge) {
              acc += 1;
            }
            return acc;
          }, 0);

          this.owned_badges = user_badges_count;
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixins.scss";

.badges-screen {
  display: flex;
  flex-direction: column;
  padding: 2.1875rem 11.5rem;

  &__header {
    margin-bottom: 2rem;

    & > h2 {
      font-weight: 400;
      font-size: 1.75rem;
      text-shadow: 0.25rem 0.1875rem 0rem #1e1e1e;
      color: white;

      & em.counter {
        font-weight: 700;
        font-style: normal;
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &__badge {
      flex: none;
      width: 50%;
      padding: 0.75rem;

      @include screen-min($screen-lg) {
        width: 20%;
      }
    }
  }
}
</style>
