var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-content"},[(_vm.mission)?_c('div',{staticClass:"message-content__text"},[_c('div',{staticClass:"mission-detail"},[_c('h2',{staticClass:"secondary-title dark"},[_vm._v("Detalhe da Missão")]),_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-image"},[(_vm.mission.image)?_c('img',{staticClass:"mt-2",staticStyle:{"width":"18.75rem","height":"12.5rem","background":"#221b38"},attrs:{"src":_vm.backOfficeUrl +
                            '/storage/campaigns/' +
                            _vm.mission.image}}):_c('div',{staticClass:"mt-2",staticStyle:{"width":"18.75rem","height":"12.5rem","background":"#221b38"}})]),_c('div',{staticClass:"media-content pl-4"},[_c('p',[_vm._v("Válido até "+_vm._s(_vm.mission.enddate))]),_c('h1',{staticClass:"title mt-2"},[_vm._v(_vm._s(_vm.mission.name))]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.mission.description)+" ")]),_c('div',{staticClass:"columns mt-3"},[_c('div',{staticClass:"column is-9"},[_vm._v(" Progresso da missão "+_vm._s(_vm.mission.status == 1 ? parseInt(_vm.mission.date_progress || 0) : 100)+"% "),_c('ProgressBar',{staticStyle:{"margin-top":"0.625rem"},attrs:{"progress":_vm.mission.status == 1
                                        ? parseInt(
                                              _vm.mission.date_progress || 0
                                          )
                                        : 100}})],1),_c('div',{staticClass:"column is-3"},[(
                                    [
                                        'FEEDBACK',
                                        'QUIZZ',
                                        'SURVEY',
                                        'CONTENT',
                                        'CONTEÚDO + QUIZ',
                                        'CONTENT_QUIZ',
                                        'HTML',
                                        'TEXTO',
                                        'PDF',
                                        'VIDEO',
                                        'IMAGEM',
                                        'SCORM' ].includes(_vm.mission.c_type) &&
                                    _vm.mission.status == 1
                                )?_c('button',{staticClass:"button is-secondary mt-4 is-fullwidth main-btn",staticStyle:{"height":"3rem"},on:{"click":function($event){return _vm.$emit('start-mission', _vm.mission)}}},[_vm._v(" Começar ")]):_vm._e()])])])]),_c('hr'),_c('div',{staticClass:"columns"},[(
                        ![
                            'FEEDBACK',
                            'QUIZZ',
                            'SURVEY',
                            'CONTENT',
                            'CONTEÚDO + QUIZ',
                            'HTML',
                            'TEXTO',
                            'PDF',
                            'VIDEO',
                            'IMAGEM',
                            'SCORM' ].includes(_vm.mission.c_type)
                    )?_c('div',{staticClass:"column is-4"},[_c('h3',{staticClass:"subtitle"},[_vm._v(" KPI em Missão: #"+_vm._s(_vm.mission.kpis.length)+" ")]),_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.mission.kpis),function(kpi,i){return _c('div',{key:i,staticClass:"column is-6"},[_c('h5',{staticClass:"kpi-name"},[_vm._v(_vm._s(kpi.friendlyname))]),_c('div',[_c('h4',{staticClass:"kpi-value"},[_vm._v(" "+_vm._s(kpi.result == null ? 0 : kpi.result)+" "+_vm._s(kpi.units)+" ")]),(kpi.goal != 0)?_c('p',{staticClass:"kpi-goal"},[_vm._v(" / "+_vm._s(kpi.goal)+" "+_vm._s(kpi.units)+" ")]):_vm._e()])])}),0)]):_c('div',{staticClass:"column is-4"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Estado da missão")]),(_vm.mission.mission_content)?_c('div',{staticClass:"mission-status"},[_c('div',{staticClass:"mission-status__header"},[_c('mission-icon'),_c('div',{staticClass:"mission-status__header__info"},[_c('p',{staticClass:"mission-status__header__info__title"},[_vm._v(" "+_vm._s(_vm.mission.mission_content.name)+" ")]),_c('p',{staticClass:"mission-status__header__info__description",attrs:{"title":"Descrição da missão"}},[_vm._v(" "+_vm._s(_vm.mission.mission_content.description)+" ")])])],1),_c('div',{staticClass:"mission-status__progress"},[_c('progress',{staticClass:"progress",attrs:{"max":"100"},domProps:{"value":_vm.mission.mission_content.performance *
                                    100}},[_vm._v(" "+_vm._s(_vm.mission.mission_content.performance * 100)+"% ")]),_c('figure',{staticClass:"hex-icon"},[(
                                        _vm.mission.mission_content
                                            .performance *
                                            100 >=
                                        100
                                    )?_c('check-hex-icon'):_c('hex-icon',{staticClass:"incomplete"}),(
                                        _vm.mission.mission_content
                                            .performance *
                                            100 <
                                        100
                                    )?_c('figcaption',[_vm._v(" "+_vm._s(_vm.mission.mission_content .performance * 100)+"% ")]):_vm._e()],1)])]):_vm._e(),(_vm.mission.mission_quizz)?_c('div',{staticClass:"mission-status"},[_c('div',{staticClass:"mission-status__header"},[_c('mission-icon'),_c('div',{staticClass:"mission-status__header__info"},[_c('p',{staticClass:"mission-status__header__info__title"},[_vm._v(" "+_vm._s(_vm.mission.mission_quizz.name)+" ")]),_c('p',{staticClass:"mission-status__header__info__description",attrs:{"title":"Descrição da missão"}},[_vm._v(" "+_vm._s(_vm.mission.mission_quizz.description)+" ")])])],1),_c('div',{staticClass:"mission-status__progress"},[_c('progress',{staticClass:"progress",attrs:{"max":"100"},domProps:{"value":_vm.mission.mission_quizz.performance * 100}},[_vm._v(" "+_vm._s(_vm.mission.mission_quizz.performance * 100)+"% ")]),_c('figure',[(
                                        _vm.mission.mission_quizz.performance *
                                            100 >=
                                        100
                                    )?_c('check-hex-icon'):_c('hex-icon',{staticClass:"incomplete"}),(
                                        _vm.mission.mission_quizz.performance *
                                            100 <
                                        100
                                    )?_c('figcaption',[_vm._v(" "+_vm._s(_vm.mission.mission_quizz.performance * 100)+"% ")]):_vm._e()],1)])]):_vm._e()]),_c('div',{staticClass:"column is-4"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Leaderboard")]),_c('div',{staticClass:"leaderboard-container"},[_c('table',{staticClass:"leaderboard-table"},_vm._l((_vm.mission.leaderboard),function(winner,idx){return _c('tr',{key:idx,class:{
                                    active:
                                        winner.id == _vm.authUser.id ||
                                        winner.id ==
                                            _vm.userProfile.current_group_id,
                                }},[_c('td',[_vm._v(_vm._s(idx + 1))]),_c('td',{staticClass:"is-flex is-align-items-center"},[(winner.profile_picture)?_c('img',{staticClass:"image is-32x32 mr-2 rounded",attrs:{"src":(_vm.backOfficeUrl + "/storage/users/" + (winner.profile_picture)),"onerror":"this.src='/images/user-image.svg'"}}):_c('img',{staticClass:"image is-32x32 mr-2",attrs:{"src":require("@/assets/images/misc/user-image.svg")}}),_vm._v(" "+_vm._s(winner.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(winner.total_performance == null ? 0 : parseInt( winner.total_performance ))+"% ")])])}),0)])]),_c('div',{staticClass:"column is-4"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Recompensas")]),_c('div',{staticClass:"rewards-container"},_vm._l((_vm.mission.rewards),function(reward,idx){return _c('div',{key:idx,staticClass:"media reward"},[_c('div',{staticClass:"media-image"},[(reward.type === 'POINTS')?_c('img',{staticClass:"mt-1 image reward-size mr-2",attrs:{"alt":_vm.rewardDisplayValue(reward),"src":require("@/assets/images/misc/currency_icon_no_circle.svg")}}):_c('img',{staticClass:"mt-1 image reward-size mr-2",attrs:{"src":_vm.rewardDisplayImage(reward),"alt":_vm.rewardDisplayValue(reward)}})]),_c('div',{staticClass:"media-content"},[_c('h5',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(_vm.rewardDisplayValue(reward))+" ")]),_c('p',[_vm._v(_vm._s(reward.type))])])])}),0)])])])]):_c('div',{staticClass:"message-content__empty"},[_c('computer-icon',{staticClass:"message-content__empty__laptop"}),_c('p',[_vm._v(" "+_vm._s(_vm.missionsCount ? "Nenhuma missão selecionada" : "Sem Missões")+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }