<template>
  <div class="stats-item">
    <circular-progress :result="result" :units="units" :percentage="percentage" :size="'5.5rem'" :color="color" :colorExtra="colorExtra" />
    <div class="stats-item-description">
      <p class="stats-item-name" :title="name.toUpperCase()">{{ name.toUpperCase() }}</p>
      <p v-if="type === 'mission'" class="stats-item-remaining">{{ remaining }}</p>
      <p v-else-if="type === 'kpi'" class="stats-item-remaining">/<span>{{ goal }}</span></p>
    </div>
  </div>
</template>

<script>
import CircularProgress from "../../Utils/CircularProgress.vue";
export default {
  components: { CircularProgress },
  props: {
    name: { type: String, required: true },
    percentage: { type: Number, required: true },
    result: { type: Number, required: true },
    units: { type: String, default: "" },
    remaining: { type: String, default: "" },
    color: { type: String, default: "#ba9dfe" },
    colorExtra: { type: String, default: "#8b5ef4" },
    result: { type: String | Number, default: 0 },
    goal: { type: String | Number, default: 0 },
    type: { type: String, default: "mission" },
  },
};
</script>

<style lang="scss">
.stats-item {
  display: flex;
  gap: 1.2rem;
  padding-left: 1.2rem;
  min-width: 17.5rem;
}

.stats-item-description {
  color: white;
}

.stats-item-name {
  font-size: 1.1rem;
  white-space: nowrap;
  display:inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  width:150px;
  width:9rem;
}

.stats-item-remaining {
  font-size: 0.9rem;
  margin-left: 0.3rem;

  &>span {
    font-size: 0.75rem;
  }
}

.stats-item-description {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>