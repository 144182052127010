<template>
    <div class="ranking-podium">
        <h1 class="ranking-podium-title">O Melhor dos melhores MyArena</h1>
        <div class="podium">
            <div v-for="user in podium" :key="user.id" class="podium-position">
                <div class="podium-avatar">
                    <span>{{ user.position }}º</span>
                    <img v-if="user.avatar" :src="user.avatar" alt="User Avatar" onerror="this.src='/images/user-image.svg'" />
                    <img v-else src="@/assets/images/misc/user-image.svg" alt="User Avatar" />
                </div>
                <p>{{ user.username }}</p>
                <p>{{ user.performance }} %</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        podium: { type: Array, required: true },
    },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

.ranking-podium {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.ranking-podium-title {
    position: relative;
    font-weight: bold;
    font-size: 3rem;
    text-shadow: 2px 2px 0px black;
    text-align: center;
    margin-bottom: 1rem;

    &::before {
        content: "";
        position: absolute;
        width: 90%;
        height: 0.15rem;
        bottom: 0;
        left: 5%;
        justify-self: center;
        background-color: $main-color;
        border-radius: 100px;
    }
}

.podium {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.podium-position {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;

    & > p:nth-of-type(1) {
        font-size: 1.1rem;
    }

    & > p:nth-of-type(2) {
        font-size: 0.8rem;
        color: $yellow;
    }
}

.podium-avatar {
    position: relative;
    width: 4rem;
    height: 4rem;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

    & > span {
        position: absolute;
        top: -0.6rem;
        left: -0.2rem;
        font-size: 0.75rem;
    }

    & > img {
        border-radius: 100%;
        width: 100%;
    }
}

.podium-position:nth-of-type(2) {
    bottom: 0.5rem;
}

.podium-position:nth-of-type(2) > .podium-avatar {
    border: $main-color 0.2rem solid;
}
</style>
