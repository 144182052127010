const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;

export default class GeneralResourcesService {
    /**
       * Get FAQ's
       * @returns 
       */
    static getFAQs() {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/rh/faqs`)
                .then((response) => {
                    let faqs = response.data;
                    resolve(faqs);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }
}