<template>
  <div v-if="show" class="splash-screen">
    <h1 class="splash-screen__title">Resumo do dia {{ today }}</h1>

    <div class="splash-screen__slider">
      <button class="splash-screen__slider__arrow" @click="moveLeft" :disabled="index <= 0">
        <img src="@/assets/images/icons/chevron-left.svg" alt="chevron left" />
      </button>
      <div class="splash-screen__slider__content">
        <component :is="summaries[index].component" :resumeData="resumeData"></component>
      </div>
      <button class="splash-screen__slider__arrow" @click="moveRight" :disabled="index >= summaries.length - 1">
        <img src="@/assets/images/icons/chevron-right.svg" alt="chevron right" />
      </button>
    </div>

    <div class="splash-screen__steps">
      <button
        class="splash-screen__steps__step"
        :class="{ active: index === i }"
        v-for="(s, i) in summaries"
        :key="i"
        @click="index = i"
      ></button>
    </div>

    <button class="main-btn splash-screen__button" @click="handleClose">Fechar</button>
  </div>
</template>
<script>
import SummaryKpis from "./SummaryKpis.vue";
import SummaryPerformance from "./SummaryPerformance.vue";
import SummaryRewards from "./SummaryRewards.vue";

import { format } from "date-fns";

export default {
  name: "DailySummary",
  components: { SummaryRewards, SummaryPerformance, SummaryKpis },
  props: {
    show: { type: Boolean, default: false },
    resumeData: { type: Object, required: false },
  },
  data() {
    return {
      index: 0,
      summaries: [
        { text: "Lorem ipsum dolor sit.", component: SummaryRewards },
        { text: "Lorem ipsum dolor sit.", component: SummaryKpis },
        { text: "Lorem ipsum dolor sit.", component: SummaryPerformance },
      ],
    };
  },
  computed: {
    today() {
      return format(Date.now(), "dd/MM/yyyy")
    }
  },
  methods: {
    moveLeft() {
      if (this.index == 0) return;
      this.index = this.index - 1;
    },
    moveRight() {
      if (this.index == this.summaries.length - 1) return;
      this.index = this.index + 1;
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #282828;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__title {
    font-size: 2.25rem;
    font-weight: 700;
    color: white;
    margin-bottom: 1.0625rem;
  }

  &__slider {
    display: flex;
    gap: 0.4375rem;
    align-items: center;

    &__content {
      width: 57rem;
      height: 25.5rem;
      background: #6b3ac7;
      margin-bottom: 1.875rem;
      background-image: url("../../assets/images/avatar-selection-background.svg");
      background-size: cover;
    }

    &__arrow {
      width: 2.9375rem;
      height: 6.75rem;
      background-color: #221b38;

      display: flex;
      justify-content: center;
      align-items: center;

      &:disabled {
        cursor: not-allowed;
      }

      & > img {
        width: 1.5rem;
        color: white;
      }
    }
  }

  &__steps {
    display: flex;
    gap: 1.125rem;
    margin-bottom: 1.875rem;
    align-items: center;
    min-height: 1.7325rem;

    &__step {
      width: 1.575rem;
      height: 0.7875rem;
      background: white;
      transition: all 0.25s ease;
      cursor: pointer;

      &.active {
        width: 2.4rem;
        height: 1.7325rem;
        background: $main-color;
      }
    }
  }

  &__button {
    max-width: 18.25rem;
  }
}
</style>
