<template>
    <div class="leaderboard-wrapper">
        <div class="leaderboard-header">
            <h2>Leaderboard</h2>
            <div class="leaderboard-header-filters">
                <MSelect
                    :options="cycles"
                    @updated-select="$emit('cycle-filter-updated', $event)"
                />
                <MSelect
                    :options="rankingType"
                    @updated-select="
                        $emit('rankingType-filter-updated', $event)
                    "
                />
                <!-- <MSelect :options="jobs" :defaultOption="jobs[0]" :isVisible="rankingTypeFilterIsDisabled" @updated-select="$emit('jobs-filter-updated', $event)"/> -->
                <MSelect
                    :options="groupTypes"
                    :defaultOption="groupTypes[0]"
                    :isVisible="individualFilterSelected"
                    @updated-select="$emit('groupTypes-filter-updated', $event)"
                />
                <MSelect
                    :options="centers"
                    :defaultOption="userCenter"
                    :isDisabled="centerGroupTypesSelected"
                    @updated-select="$emit('centers-filter-updated', $event)"
                />
            </div>
        </div>
        <div class="leaderboard-table-wrapper">
            <table class="leaderboard-table">
                <thead>
                    <tr>
                        <th>POS</th>
                        <th class="leaderboard-table-username">User</th>
                        <th>Performance</th>
                        <th v-for="(kpi, idx) in kpis" :key="idx">
                            {{ kpi.friendlyname }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="user in data"
                        :class="{ 'active-user-row': authUser.id == user.id || authUser.group_id == user.id  }"
                        :key="user.id"
                    >
                        <td>{{ user.position }}º</td>
                        <td>
                            <div class="leaderboard-table-username">
                                <img v-if="user.avatar" :src="user.avatar" alt="User Avatar" onerror="this.src='/images/user-image.svg'"/>
                                <img v-else src="@/assets/images/misc/user-image.svg" alt="User Avatar" />
                                <span>{{ user.username }}</span>
                            </div>
                        </td>
                        <td>
                            {{
                                user.performance != null
                                    ? user.performance.toString().padStart(3, 0)
                                    : 0
                            }}%
                        </td>
                        <td class="kpi" v-for="(kpi, idx) in kpis" :key="idx">
                            {{ getKpiPerformance(user, kpi.id) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import MSelect from "../Utils/MSelect.vue";

export default {
    components: { MSelect },
    data() {
        return {
            firstSelect: ["Individual", "Foo", "Bar"],
            secondSelect: [
                { value: 2, displayName: "Foo" },
                { value: 1, displayName: "Todas Funções" },
                { value: 3, displayName: "Bar" },
            ],
            thirdSelect: ["Todos Centros", "Foo", "Bar"],
        };
    },
    methods: {
        getKpiPerformance(user, kpi_id) {
            if (user.top_kpis.length > 0) {
                if (user.top_kpis[kpi_id] !== undefined) {
                    return user.top_kpis[kpi_id].performance;
                }
            }

            return 0;
        },
    },
    props: {
        data: { type: Array, required: true },
        kpis: { type: Array, required: true },
        cycles: { type: Array, required: true },
        jobs: { type: Array, required: true },
        userCenter: { type: Number | String, required: true },
        centers: { type: Array, required: true },
        groupTypes: { type: Array, required: true },
        rankingType: { type: Array, required: true },
        rankingTypeFilterIsDisabled: { type: Boolean, required: true },
        individualFilterSelected: { type: Boolean, required: true },
        centerGroupTypesSelected: { type: Boolean, required: true },
    },
    computed: {
        ...mapGetters("user", ["authUser"]),
    },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

$row-horizontal-padding: 8rem;

.leaderboard-wrapper {
    width: 100%;
}

.leaderboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 3rem;
    margin: 1rem 0;
    font-size: 2rem;

    & > h2 {
        color: white;
        font-size: 0.9em;
        text-shadow: 2px 2px 0px black;
    }
}

.leaderboard-header-filters {
    display: flex;
    gap: 1rem;
    align-items: center;
    height: 100%;

    .main-select {
        width: 15rem;
    }
}

.leaderboard-table-wrapper {
    background-color: #221b38;
    margin-bottom: 3rem;
}

.leaderboard-table {
    width: 100%;
    background-color: #221b38;
    color: white;
    text-align: center;

    & * {
        border: 0;
        font-weight: bold;
    }

    & .leaderboard-table-username {
        text-align: left;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        & > img {
            border-radius: 100%;
            width: 2rem;
        }
    }

    & tr > td:first-of-type,
    & tr > th:first-of-type {
        padding-left: $row-horizontal-padding;
    }

    & tr > td:last-of-type,
    & tr > th:last-of-type {
        padding-right: $row-horizontal-padding;
    }

    & > thead > tr > th {
        padding: 1rem 0;
    }

    & > thead th {
        color: #a9b0b4;
        font-weight: 400;
        text-transform: uppercase;
    }

    & > tbody td.kpi {
        color: $yellow;
    }

    & > tbody td {
        vertical-align: middle;
    }

    & > tbody tr > td {
        padding: 1.3rem 0;
    }

    & > tbody tr.active-user-row > td {
        position: sticky;
        bottom: 0;
        top: $m-navbar-height;
        background-color: #493471;
    }
}
</style>
