<template>
    <div class="stats-main has-horizontal-scroll">
        <div class="stats-wrapper">
            <div
                v-if="missions.length"
                class="stats-section"
                @click="goToMissions()"
            >
                <img
                    class="dialog"
                    src="../../../assets/images/Dashboard/missions-dialog.svg"
                    alt="missions-dialog"
                />
                <StatsItem
                    v-for="(mission, i) in missions"
                    :key="i + mission.name"
                    :name="mission.name"
                    :percentage="mission.percentage"
                    :result="mission.percentage"
                    :remaining="mission.remaining"
                    :units="'%'"
                    :color="'#ba9dfe'"
                    :colorExtra="'#8b5ef4'"
                    :type="'mission'"
                />
            </div>
            <figure class="divider" />
            <div v-if="kpis.length" class="stats-section" @click="goToKpis()">
                <img
                    class="dialog"
                    src="../../../assets/images/Dashboard/kpis-dialog.svg"
                    alt="kpis-dialog"
                />
                <StatsItem
                    v-for="(kpi, i) in kpis"
                    :key="i + kpi.name"
                    :name="kpi.friendlyname"
                    :percentage="kpi.percentage"
                    :remaining="kpi.remaining"
                    :color="'#F9EA03'"
                    :colorExtra="'#f3c500'"
                    :goal="kpi.goal"
                    :result="kpi.result"
                    :units="kpi.units"
                    :type="'kpi'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import KPISService from "@/services/kpis.service";
import MissionsService from "@/services/missions.service";
import DateHelper from "@/tools/DateHelper";
import { mapGetters } from "vuex";
import StatsItem from "./StatsItem.vue";

export default {
    name: "BottomStatsComponent",
    data() {
        return {
            missions: [],
            kpis: [],
        };
    },
    components: { StatsItem },
    created() {
        this.fetchMissions();
        this.fetchKPIS();
    },
    computed: {
        ...mapGetters("user", ["currentCycleId"]),
    },
    methods: {
        // @TODO(Nuno) do this properly getKpiGoal getKpiResult getKpiPerformance
        getKpiResult(kpi) {
            if (kpi.evolution.length > 0) {
                if (kpi.evolution[kpi.evolution.length - 1].result != null) {
                    return kpi.evolution[kpi.evolution.length - 1].result
                        .result;
                }
            }

            return 0;
        },
        getKpiPerformance(kpi) {
            if (kpi.evolution.length > 0) {
                if (kpi.evolution[kpi.evolution.length - 1].result != null) {
                    return kpi.evolution[kpi.evolution.length - 1].result
                        .performance;
                }
            }

            return 0;
        },
        getKpiGoal(kpi) {
            if (kpi.evolution.length > 0) {
                if (kpi.evolution[kpi.evolution.length - 1].result != null) {
                    return kpi.evolution[kpi.evolution.length - 1].result.goal;
                }
            }

            return 0;
        },
        fetchKPIS() {
            KPISService.getUserTopKpis({ cycle_id: this.currentCycleId })
                .then((resp) => {
                    this.kpis = resp.map((kpi) => {
                        return {
                            name: kpi.name,
                            friendlyname: kpi.friendlyname,
                            percentage: parseFloat(this.getKpiPerformance(kpi)),
                            result: this.getKpiResult(kpi),
                            units: kpi.units,
                            goal: this.getKpiGoal(kpi),
                        };
                    });
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        fetchMissions() {
            MissionsService.getUserCampaignsList()
                .then((resp) => {
                    let active_missions = resp.data.filter(
                        (missions) => missions.status == 1
                    );

                    this.missions = active_missions.map((mission) => {
                        return {
                            name: mission.title,
                            percentage: parseInt(mission.date_progress),
                            result: parseInt(mission.date_progress),
                            remaining: DateHelper.getDateDiff(
                                new Date(),
                                mission.enddate
                            ),
                        };
                    });

                    // console.log(this.missions);
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        goToKpis() {
            this.$router.push({ name: "KPIs" }).catch(() => {});
        },
        goToMissions() {
            this.$router.push({ name: "Missions" }).catch(() => {});
        },
    },
};
</script>

<style scoped lang="scss">
.stats-main {
    overflow-x: auto;
    display: flex;
    align-items: flex-end;
    mask-image: radial-gradient(circle, #fff 90%, rgba(0, 0, 0, 0) 100%);
}

.stats-wrapper {
    display: flex;
    align-items: flex-end;
    padding-left: 4rem;
    margin-bottom: 1rem;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    animation-duration: 200s;
    -webkit-animation-duration: 200s;
}

.stats-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.dialog {
    position: absolute;
    top: -2.2rem;
    left: 0;
    width: 5.0938rem;
    height: 2.375rem;
}

.divider {
    align-self: center;
    width: 4.625rem;
    height: 0.1875rem;
    background-color: white;
    transform: rotate(120deg);
}

@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-95%, 0, 0);
        transform: translate3d(-95%, 0, 0);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-95%, 0, 0);
        transform: translate3d(-95%, 0, 0);
    }
}
</style>
