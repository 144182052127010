export default class KudosRecord {
    constructor({
        kudos_id,
        title,
        image,
        description,
        date,
        giver_id,
        receiver_id,
        total,
        givers,
        receivers
    }) {
        this.kudos_id = kudos_id;
        this.title = title;
        this.image = image;
        this.description = description;
        this.date = date;
        this.giver_id = giver_id;
        this.receiver_id = receiver_id;
        this.total = total;
        this.givers = givers;
        this.receivers = receivers;
    }
}