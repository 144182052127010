<template>
  <div class="kudos-screen">
    <div class="kudos-screen__container">
      <h1 class="main-title">Timeline de Recebidos: {{ totalReceivedKudos }}</h1>
      <div class="kudos-screen__container__kudos">
        <div v-for="(receivedKudo, i) in receivedKudos" :key="i" @click="selectedKudos = receivedKudo">
          <div class="kudos-screen__container__kudos__content">
            <figure>
              <kudos-image class="kudos-image" />
              <kudos-bubble class="kudos-image-bubble" :total="receivedKudo.givers.length" />
            </figure>

            <p class="subtitle">{{ receivedKudo.title }}</p>
            <p class="datetime">{{ receivedKudo.date }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="kudos-screen__container">
      <h1 class="main-title">Timeline de Atribuídos: {{ totalGivenKudos }}</h1>
      <div class="kudos-screen__container__kudos">
        <div v-for="(givenKudo, i) in givenKudos" :key="i" @click="selectedKudos = givenKudo">
          <div class="kudos-screen__container__kudos__content">
            <figure>
              <kudos-image class="kudos-image" />
              <kudos-bubble class="kudos-image-bubble" :total="givenKudo.receivers.length" />
            </figure>

            <p class="subtitle">{{ givenKudo.title }}</p>
            <p class="datetime">{{ givenKudo.date }}</p>
          </div>
        </div>
      </div>
    </div>

    <kudos-detail-modal
      @close="closeKudosModal"
      :show="selectedKudos != null"
      v-if="selectedKudos"
      :kudos="selectedKudos"
    />
  </div>
</template>

<script>
import KudosService from "@/services/kudos.service";
import KudosBubble from "@/components/Kudos/KudosBubble.vue";
import KudosDetailModal from "@/components/Kudos/KudosDetailModal.vue";
import KudosImage from "../../components/Kudos/KudosImage.vue";

export default {
  name: "Kudos",
  components: {
    KudosBubble,
    KudosDetailModal,
    KudosImage,
  },
  data() {
    return {
      loading: false,
      givenKudos: [],
      receivedKudos: [],
      showKudosDetailModal: false,
      selectedKudos: null,
    };
  },
  created() {
    this.getKudosList();
  },
  methods: {
    getKudosList() {
      this.loading = true;
      KudosService.getUserKudos()
        .then((resp) => {
          this.givenKudos = resp.given_kudos;
          this.receivedKudos = resp.received_kudos;
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
        .finally(() => (this.loading = false));
    },
    openKudosModal() {
      this.showKudosDetailModal = true;
    },
    closeKudosModal() {
      this.showKudosDetailModal = false;
      this.selectedKudos = null;
    },
  },
  computed: {
    totalReceivedKudos() {
      let total = 0;
      return this.receivedKudos.reduce((accumulator, currentValue) => accumulator + currentValue.givers.length, total);
    },
    totalGivenKudos() {
      let total = 0;
      return this.givenKudos.reduce((accumulator, currentValue) => accumulator + currentValue.receivers.length, total);
    },
  },
};
</script>

<style scoped lang="scss">
.kudos-screen {
  padding: 2.18rem 11.5rem 0 11.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.25rem;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__kudos {
      display: flex;
      gap: 1.875rem;
      flex-wrap: wrap;

      &__content {
        background: #221b38;
        background-image: url("/bg_kudos.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top left;
        padding: 1.25rem 2rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: column;
        min-width: 17rem;

        & > figure {
          margin-bottom: 1.7813rem;
          position: relative;
        }

        // is-size-4 mt-2 mb-0
        & > p.subtitle {
          color: white;
          margin-top: 0.5rem;
          margin-bottom: 0;
          font-size: 1.5rem;
        }

        & > p.datetime {
          color: #8f81b2;
          font-weight: 700;
          font-size: 0.875rem;
        }
      }
    }
  }
}

.kudos-image {
  width: 5.625rem;
  height: 5.625rem;
}

.kudos-image-bubble {
  width: 5.625rem;
  height: 5rem;
  left: 4.3rem;
  position: absolute;
}
</style>
