<template>
  <div class="notification-wrapper">
    <Notification v-if="lastMessage">
      <template v-slot:lefticon>
        <img src="../../../assets/images/Notifications/notification-lady.svg" alt="notification lady">
        <!-- <img src="../../../assets/images/Notifications/xmas_eva.svg" alt="notification lady" /> -->
      </template>
      <p v-html="this.lastMessage.body"></p>
    </Notification>
    <Notification v-if="highlightedProduct">
      <template v-slot:lefticon>
        <img src="../../../assets/images/Notifications/notification-store.svg" alt="notification store">
      </template>
      <Countdown class="countdown" />
      <p style="font-weight: bold;">Produto em Destaque</p>
      <p><span style="font-weight: bold;">500</span>pts</p>
      <template v-slot:righticon>
        <img src="../../../assets/images/Notifications/notification-favorite.svg" alt="favorite store">
      </template>
    </Notification>
  </div>
</template>

<script>
import MessagesService from "@/services/messages.service";
import Countdown from "../../Utils/Countdown.vue";
import Notification from "./Notification.vue";
export default {
  components: { Notification, Countdown },
  data() {
    return {
      lastMessage: null,
      highlightedProduct: null
    }
  },
  created() {
    this.getLastMessage();
  },
  methods: {
    getLastMessage() {
      MessagesService.getUserLatestMessage()
        .then((resp) => {
          this.lastMessage = resp.last_message[0];
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
        .finally(() => (this.loading = false));
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixins.scss";

.notification-wrapper {
  padding-top: 2rem;
  padding-left: 3rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include screen-min($screen-lg) {
    gap: 0.4rem;
  }
}

.countdown {
  @include screen-min($screen-lg) {
    margin-bottom: 0.3125rem;
  }
}
</style>