<template>
  <div class="lounge-content">
    <HeroStats :percentage="parseFloat(hero.performance)" :heroName="heroName" :badges="heroBadges" class="hero-stats" />
    <Hero :percentage="parseFloat(hero.performance)" :avatar="hero.full_avatar" :defaultAvatar="hero.default_avatar" :inLounge="true" class="hero" :invert="invert" />
    <CommentIcon @kudos-button-clicked="handleKudosButton" class="comment" :hero="hero" />
  </div>
</template>

<script>
import StringManipulator from "@/tools/StringManipulator";
import Hero from "../Hero/Hero.vue";
import HeroStats from "../Hero/HeroStats.vue";
import CommentIcon from "./CommentIcon.vue";
export default {
  components: { HeroStats, CommentIcon, Hero },
  props: {
    hero: { type: Object, required: true },
    invert: { type: Boolean, default: false },
  },
  computed: {
    heroName() {
      return StringManipulator.getFirstAndLastName(this.hero.name);
    },
    heroBadges() {
      if(this.hero.badges !== undefined && this.hero.badges != null)
        return JSON.parse(this.hero.badges).slice(0, 2);
      else
        return null;
    }
  },
  methods: {
    handleKudosButton(lounge_user) {
      this.$emit('open-kudos-modal', lounge_user);
    }
  }
};
</script>

<style scoped lang="scss">
.lounge-content {
  position: relative;
  display: flex;
}

.hero {
  height: 180%;
  left: -3rem;
}

.comment {
  position: absolute;
  bottom: 0.6rem;
}

.first .comment {
  right: 3.2rem;
}

.second .comment {
  right: 0.7rem;
}
</style>