<template>
    <div class="missions-preview" :class="{ active: active }">
        <div class="media">
            <div class="mission-icon">
                <mission-icon :mission="mission" class="mt-2" />
            </div>
            <div class="media-content pl-4 pb-2">
                <h3 class="mission-title" :title="mission.title">
                    {{ maxSubStr(mission.title, max_title_length) }}
                </h3>
                <p class="mission-description" :title="mission.description">
                    {{ maxSubStr(mission.description, max_description_length) }}
                </p>
            </div>
        </div>

        <!-- Mission progress bar based on timely progress -->
        <div class="progress-bar is-relative">
            <div class="is-pulled-right progress-metric">
                {{
                    mission.status == 1
                        ? parseInt(mission.date_progress || 0)
                        : 100
                }}%
            </div>
            <ProgressBar
                style="width: 78%"
                :progress="
                    mission.status == 1
                        ? parseInt(mission.date_progress || 0)
                        : 100
                "
            />
        </div>

        <p class="mission-date mt-4">Valido até {{ mission.enddate }}</p>
    </div>
</template>

<script>
import ProgressBar from "@/components/Missions/ProgressBar.vue";
import MissionIcon from "@/components/Utils/Icons/MissionIcon.vue";
import CheckHexIcon from "@/components/Utils/Icons/CheckHexIcon.vue";
import HexIcon from "@/components/Utils/Icons/HexIcon.vue";

export default {
    name: "MissionPreview",
    components: { MissionIcon, CheckHexIcon, HexIcon, ProgressBar },

    props: { mission: Object, active: { type: Boolean, default: false } },
    data() {
        return {
            max_title_length: 25,
            max_description_length: 40,
        };
    },
    methods: {
        maxSubStr(str, max_length) {
            if (str.length > max_length) {
                return str.substr(0, max_length) + "..";
            }

            return str;
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
.main-box {
    background: #221b38;
    border-radius: 0;
    padding: 0;
    max-height: 44.125rem;
    height: 100%;
}

.page-title {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.9375rem;
    color: #ffffff;
    text-shadow: 0.25rem 0.1875rem 0rem #1e1e1e;
}

.secondary-title {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.9375rem;
    color: #ffffff;
    text-shadow: 0.25rem 0.1875rem 0rem #1e1e1e;
}

.secondary-title.dark {
    color: #221b38;
    text-shadow: none;
    font-weight: 700;
}

.subtitle {
    font-size: 1.5rem;
    line-height: 2.9375rem;
    color: #221b38;
    font-weight: 700;
}

.box.is-active {
    background: #362a52;
    color: white;
}

.progress {
    border-radius: 0;
}

.progress-bar .progress {
    width: calc(100% - 3rem);
    height: 0.625rem;
}

.progress-metric {
    position: absolute;
    right: 0;
    top: -1.125rem;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    background-image: url("/hex_white.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.is-tab-button {
    background: transparent;
    border: none;
    border-radius: 0;
    color: white;
    outline: none !important;
}

.is-tab-button:focus {
    outline: none !important;
}

.is-tab-button.is-active {
    border-bottom: 0.125rem solid white;
}

.mission-detail-column {
    background: #ffffff;
    padding: 1.5rem;
}

.mission-box {
    border-radius: 0;
    box-shadow: none;
    background: #221b38;
    color: #ffffff;
    margin-bottom: 0;
}

.mission-box:not(:last-child) {
    border-bottom: 0.0625rem solid #362a52;
}

.mission-title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem;
}

.mission-description,
.mission-date {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
}

.kpi-name {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #222e42;
}

.kpi-value {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.5625rem;
    color: #1eb28d;
}

.leaderboard-container,
.rewards-container {
    max-height: 12.5rem;
    overflow-y: auto;
}

.leaderboard-table {
    width: 100%;
}

.leaderboard-table tr.active {
    border-bottom: 0.125rem solid #1eb28d;
    background: #ddd9e7;
}

.leaderboard-table tr {
    border-top: 0.125rem solid #ddd9e7;
}

.leaderboard-table tr:last-of-type {
    border-bottom: 0.125rem solid #ddd9e7;
}

.leaderboard-table tr td {
    padding: 0.625rem;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    letter-spacing: 0.01em;
    color: #221b38;
}

.reward h5 {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    letter-spacing: 0.01em;
    color: #221b38;
}

.reward p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    letter-spacing: 0.01em;
    color: #221b38;
}

.mission-status {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        align-items: center;
        gap: 1.25rem;

        & > svg {
            width: 2.6rem;
            flex-shrink: 0;
            color: #221b38;
        }

        &__info {
            color: #221b38;
            width: 100%;

            &__title {
                font-size: 1.25rem;
                font-weight: 700;
            }

            &__description {
                display: inline-block;
                font-weight: 500;
                font-size: 0.875rem;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 80%;
                white-space: nowrap;
            }
        }
    }

    &__progress {
        display: flex;
        gap: 0.3rem;
        align-items: center;
        color: $main-color;
        margin-top: -0.625rem;

        & > progress {
            height: 0.625rem;
            margin-bottom: 0;
            color: $main-color;
            background-color: #ddd9e7;

            &::-moz-progress-bar,
            &::-webkit-progress-value {
                background: $main-color;
            }
        }

        figure {
            position: relative;
            display: flex;

            & > .incomplete {
                color: #ddd9e7;
            }

            & > figcaption {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #221b38;
                font-weight: 400;
                font-size: 0.75rem;
            }
        }
    }
}

.mission-icon {
    width: 2.5rem;
}
</style>
