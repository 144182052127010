<template>
    <section class="conteudo">
        <div class="conteudo__header">
            <p>
                Total
                <span>{{ displayContent.length }}</span>
            </p>
            <div class="conteudo__header__filters">
                <MSelect
                    :options="typeFilter"
                    :defaultOption="typeFilter[0]"
                    @updated-select="filterByType($event)"
                />
                <!-- <MSelect :options="filterOptions2" /> -->
            </div>
        </div>
        <div class="conteudo__main">
            <!-- <content-item
                v-for="(c, i) in displayContent"
                :key="i"
                :pos="i"
                :item="c"
                :active="!c.locked"
                :first="i == 0 && apiContent.length > 1"
                :last="i == apiContent.length - 1"
                @clicked-content="openContent($event)"
            /> -->
            <content-item
                v-for="(c, i) in displayContent"
                :key="i"
                :pos="i"
                :item="c"
                :active="!c.locked"
                :first="i == 0 && displayContent.length > 1"
                :last="i == displayContent.length - 1"
                @clicked-content="openContent($event)"
            />
        </div>
    </section>
</template>
<script>
import ContentItem from "../../components/Content/ContentItem.vue";
import ContentStep from "../../components/Content/ContentStep.vue";
import MSelect from "../../components/Utils/MSelect.vue";
import MissionsService from "@/services/missions.service";

export default {
    components: { MSelect, ContentItem, ContentStep },
    name: "Content",
    data() {
        return {
            total: 0,
            typeFilter: [
                {
                    value: "*",
                    displayName: "Todos os Tipos",
                },
                {
                    value: "HTML",
                    displayName: "HTML",
                },
                {
                    value: "PDF",
                    displayName: "PDF",
                },
                {
                    value: "IMAGE",
                    displayName: "Imagem",
                },
                {
                    value: "VIDEO",
                    displayName: "Vídeo",
                },
                {
                    value: "CONTENT_QUIZZ",
                    displayName: "Conteúdo e Quiz",
                },
                {
                    value: "SCORM",
                    displayName: "SCORM",
                },
            ],
            filterOptions2: ["Por Tema"],
            apiContent: [],
            displayContent: [],
        };
    },
    created() {
        this.getContentsList();
    },
    methods: {
        getContentsList() {
            MissionsService.getUserContentCampaignsList()
                .then((resp) => {
                    this.apiContent = resp.map(function (c) {
                        return {
                            mission_id: c.id,
                            content_id: c.content_id,
                            type: c.content_type,
                            imageUrl: "",
                            title: c.title,
                            locked: c.status,
                            date: c.startdate,
                            content_title: c.content_title,
                        };
                    });

                    this.apiContent.sort(function (a, b) {
                        if (a.locked == false) {
                            if (b.locked == false) {
                                return a.startdate > b.startdate; // TODO compare dates
                            }

                            return -1;
                        } else if (a.locked == true && b.locked == false) {
                            return 1;
                        }
                    });

                    this.displayContent = this.apiContent
                        .slice()
                        .reverse()
                        .map((c, i) => ({
                            ...c,
                            id: this.apiContent.length - 1 - i,
                        }));

                    //this.displayContent = this.apiContent;
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        filterByType(e) {
            const filterOption = e.target.value;

            this.displayContent = this.apiContent.filter((c) => {
                if (filterOption === "*") {
                    return true;
                }
                return c.type === filterOption;
            });
        },
        openContent(data) {
            this.$router
                .push({
                    name: "Content Details",
                    params: {
                        content_id: data.content_id,
                        campaign_id: data.mission_id,
                        timestamp: new Date().getTime(), // Force reload
                    },
                })
                .catch((err) => {
                    // Ignore the vuex err regarding  navigating to the page they are already on.
                    if (
                        err.name !== "NavigationDuplicated" &&
                        !err.message.includes(
                            "Avoided redundant navigation to current location"
                        )
                    ) {
                        // But print any other errors to the console
                        logError(err);
                    }
                });
        },
    },
};
</script>
<style scoped lang="scss">
.conteudo {
    padding: 1.6rem 11rem;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.68rem;

        & > p {
            font-weight: 400;
            font-size: 1.75rem;
            color: white;
            text-shadow: 0.125rem 0.125rem 0px #1e1e1e;

            & > span {
                font-weight: 700;
            }
        }

        &__filters {
            display: flex;
            gap: 1.875rem;
        }
    }

    &__main {
        background-color: #f5f6fb;
        display: flex;
        padding: 1.875rem;
        padding-left: 3rem;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow: hidden;

        & * {
            z-index: 1;
        }

        &::before {
            content: "";
            background: url("../../assets/images/messages/circles-message.svg");
            background-size: 100% 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 17rem;
            height: 26rem;
            z-index: 0;
        }
    }
}
</style>
