<template>
  <div class="message-preview" :class="{ active: active }">
    <figure v-show="unread" class="message-preview__unread"></figure>
    <img
      v-if="!needImageFallback"
      class="message-preview__icon"
      :src="apiUrl + '/' + message.icon"
      @error="changeToFallback"
    />
    <div v-else class="message-preview__icon">
      <MessageDefaulIcon />
    </div>
    <div class="message-preview__details" :class="{ unread: unread }">
      <p class="message-preview__details__title">{{ message.subject }}</p>
      <p class="message-preview__details__type">{{ message.type }}</p>
      <p class="message-preview__details__date">{{ message.date }}</p>
    </div>
  </div>
</template>

<script>
import MessageDefaulIcon from "../Utils/Icons/MessageDefaulIcon.vue";
export default {
  props: {
    message: Object,
    active: { type: Boolean, default: false },
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_BO_BASE_URL,
      needImageFallback: false,
    };
  },
  computed: {
    unread() {
      return this.message.status === "UNREAD";
    },
  },
  methods: {
    changeToFallback() {
      this.needImageFallback = true;
    },
  },
  components: { MessageDefaulIcon },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
$gap: 0.625rem;

.message-preview {
  display: flex;
  gap: $gap;
  position: relative;
  cursor: pointer;

  $unreadSize: 0.93rem;

  &__unread {
    width: $unreadSize;
    height: $unreadSize;
    background-color: $main-color;
    border-radius: 100%;
    position: absolute;
  }

  &__icon {
    width: 3rem;
    height: 3rem;
    background-color: white;
    border-radius: 100%;
    margin-left: calc($unreadSize + $gap);
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #403c56;
  }

  &__details {
    color: #8f81b2;
    font-size: 1.125rem;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    font-weight: 300;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 1.5rem;

    &__title {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    &__date {
      font-size: 0.8rem;
    }
  }
}

.unread {
  color: white;

  & > p.message-preview__details__title {
    font-weight: 700;
  }

  & > p.message-preview__details__type {
    font-weight: 400;
  }
}

.active {
  background-color: #493471;
  padding: 0.8rem 1.25rem;

  & .message-preview__details {
    padding-right: 0;
  }
}
</style>
