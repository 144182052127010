<template>
    <svg width="100%" height="100%" viewBox="0 0 47 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.85714 3.82394V26.2049H43.5714V3.82394H2.85714ZM1.42857 0.966797C0.639593 0.966797 0 1.60639 0 2.39537V27.6335C0 28.4224 0.639593 29.062 1.42857 29.062H45C45.789 29.062 46.4286 28.4224 46.4286 27.6335V2.39537C46.4286 1.60639 45.789 0.966797 45 0.966797H1.42857Z" :fill="color"/>
        <path d="M3.89697 34.7764C3.89697 34.1189 4.42997 33.5859 5.08745 33.5859H40.6533C41.3108 33.5859 41.8438 34.1189 41.8438 34.7764C41.8438 35.4339 41.3108 35.9669 40.6533 35.9669H5.08745C4.42997 35.9669 3.89697 35.4339 3.89697 34.7764Z" :fill="color"/>
        <path d="M12 11.9668V14.0256M12 18.9668V14.0256M12 14.0256H14.0408C15.1454 14.0256 16.0408 14.9211 16.0408 16.0256V18.9668M20.0816 11.9668V17.1433C20.0816 17.6956 20.5293 18.1433 21.0816 18.1433H21.8776M17.8367 14.0256H22.3265M24.1224 12.7903V14.4374M24.1224 18.9668V14.4374M24.1224 14.4374H27.7143M27.7143 14.4374V18.9668M27.7143 14.4374H30.3061C30.8584 14.4374 31.3061 14.8851 31.3061 15.4374V18.9668M34 11.9668V18.9668" :stroke="color" stroke-width="1.5"/>
    </svg>
</template>

<script>
export default {
    props: { 
        color: { 
            type: String, 
            default: "#8F81B2",
        } 
    },
};
</script>

<style scoped lang="scss">
</style>
