<template>
  <div class="rewards-screen">
    <h1>Recompensas</h1>
    <div class="rewards-screen__rewards" v-if="rewards.length">
      <div class="rewards-screen__rewards__reward" v-for="(r, i) in rewards" :key="i">
        <figure>
          <img :src="backOfficeUrl + '/storage/badges/' + r.image" alt="reward img" />
        </figure>
        <p>{{ r.title }}</p>
      </div>
    </div>
    <div v-else>
      <p>Nenhuma recompensa foi atribuída</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryRewards",
  props: {
    resumeData: { type: Object, required: false }
  },
  data() {
    return {
      backOfficeUrl: process.env.VUE_APP_BO_BASE_URL
    }
  },
  computed: {
    rewards() {
      if (!this.resumeData) {
        return []
      }
  
      return this.resumeData.badges;
    }
  },
};
</script>
<style scoped lang="scss">
.rewards-screen {
  display: flex;
  flex-direction: column;
  padding: 2.875rem;
  align-items: center;
  text-transform: uppercase;

  & > h1 {
    font-weight: 700;
    color: white;
    font-size: 1.75rem;
    margin-bottom: 4rem;
  }

  p {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 4rem;
  }

  &__rewards {
    display: flex;
    gap: 4.5rem;

    &__reward {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > figure {
        width: 6.25rem;
        height: 6.25rem;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.625rem;

        & > img {
          width: 4rem;
        }
      }

      & > p {
        font-weight: 500;
        font-size: 1.375rem;
        color: white;
      }
    }
  }
}
</style>
