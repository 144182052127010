const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;

export default class KPISService {

    /**
     * Get list of top kpis for user.
     * @param {*} params 
     * @returns 
     */
    static getUserTopKpis(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/kpis/top`, { ...params })
                .then((response) => {
                    let kpis = response.data;
                    resolve(kpis);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Get list of all top kpis.
     * @param {*} params 
     * @returns 
     */
    static getTopKpis(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/kpis/list/top`, { ...params })
                .then((response) => {
                    let top_kpis = response.data.top_kpis;
                    resolve(top_kpis);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static getUserTopKpiDetail(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/kpis/top/child/detail`, { ...params })
                .then((response) => {
                    let kpi = response.data;
                    resolve(kpi);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }
}