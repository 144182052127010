const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;

export default class AchievementsService {
    static getAllBadges() {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/achievements/list`)
                .then((response) => {
                    let badges = response.data;
                    resolve(badges);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
