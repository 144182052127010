<template>
    <section class="missions-screen">
        <div class="missions-screen__header">
            <h1>Missões</h1>
            <div class="missions-screen__header__tabs">
                <button
                    class="missions-screen__header__tabs__tab"
                    :class="[{ 'is-active': currentTab === 'in_progress' }]"
                    @click="changeTab('in_progress')"
                >
                    Em Curso
                </button>
                <button
                    class="missions-screen__header__tabs__tab"
                    :class="[{ 'is-active': currentTab === 'archive' }]"
                    @click="changeTab('archive')"
                >
                    Arquivo
                </button>
            </div>
        </div>

        <div class="missions-screen__container">
            <div class="missions-screen__container__list card-folded">
                <h4>Lista de Missões</h4>
                <div class="missions-screen__container__list__missions">
                    <MissionPreview
                        v-for="mission in showMissions"
                        :mission="mission"
                        :selected="mission.id === missionSelected"
                        :key="mission.id"
                        @click.native="() => selectMission(mission.id)"
                        :class="{ 'is-active': mission.id === missionSelected }"
                        class="missions-screen__container__list__missions__mission cursor-pointer"
                    >
                    </MissionPreview>
                </div>
            </div>
            <div class="missions-screen__container__mission-details">
                <MissionDetail
                    :mission="selectedMission"
                    :missionsCount="showMissions.length"
                    @start-mission="launchMission($event)"
                />
            </div>
        </div>
    </section>
    <!-- <div class="container is-fluid">
      <

      <div class="columns">
        <div class="column is-12" style="height: 100%">
          <div class="box fullscreen-box main-box card-folded">
            <div class="columns" style="margin-left: 0; height: 100%">
              <div class="column is-3" style="padding: 0.625rem 0 0 0.625rem">
                <h4 class="secondary-title ml-2">Lista de Missões</h4>
                <div
                  class="is-scrollable"
                  style="padding: 0.625rem 1.25rem 0.625rem 0.625rem; max-height: 38.8125rem"
                >
                  <MissionPreview
                    v-for="(mission, i) in showMissions"
                    :mission="mission"
                    :selected="mission.id === missionSelected"
                    :key="i"
                    @click.native="() => selectMission(mission.id)"
                    :class="{ 'is-active': mission.id === missionSelected }"
                    class="box is-clickable mission-box"
                  >
                  </MissionPreview>
                </div>
              </div>

              <div class="column is-9 mission-detail-column">
                <MissionDetail
                  :mission="selectedMission"
                  :missionsCount="showMissions.length"
                  @start-mission="launchMission($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- @TODO(Nuno) Remove after testing, put in correct flow -->
    <!-- <MissionResultsModal v-if="selectedMission" @close="closeMissionResultsModal" :show="true"
            :mission="selectedMission" /> -->
</template>
<script>
import MissionResultsModal from "@/components/Missions/MissionResultsModal";
import MissionPreview from "@/components/Missions/MissionPreview";
import MissionDetail from "@/components/Missions/MissionDetail";
import MissionIcon from "../../../components/Utils/Icons/MissionIcon.vue";
import CheckHexIcon from "../../../components/Utils/Icons/CheckHexIcon.vue";
import HexIcon from "../../../components/Utils/Icons/HexIcon.vue";

import MissionsService from "@/services/missions.service";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
    name: "Missions",
    components: {
        MissionResultsModal,
        MissionIcon,
        CheckHexIcon,
        HexIcon,
        MissionPreview,
        MissionDetail,
    },
    data() {
        return {
            missions: [],
            missionSelected: 3,
            currentTab: "in_progress",
            max_title_length: 25,
            max_description_length: 40,
            selectedMission: null,
        };
    },
    computed: {
        ...mapGetters("user", ["authUser"]),
        activeMissions() {
            if (!this.missions) return [];
            const missions = this.missions.filter(
                (missions) => missions.status == 1
            );
            const ids = missions.map((m) => m.id);
            return missions.filter(({ id }, i) => !ids.includes(id, i + 1));
        },
        archivedMissions() {
            if (!this.missions) return [];
            const missions = this.missions.filter(
                (missions) => missions.status == 0
            );
            const ids = missions.map((m) => m.id);
            return missions.filter(({ id }, i) => !ids.includes(id, i + 1));
        },
        showMissions() {
            if (this.currentTab === "in_progress") return this.activeMissions;

            return this.archivedMissions;
        },
    },
    mounted() {
        this.fetchMissions();
    },
    methods: {
        selectMission(mission) {
            this.missionSelected = mission;
            this.fetchMissionDetail(mission);
        },
        changeTab(tab) {
            this.currentTab = tab;
            this.missionSelected =
                this.showMissions.length > 0 ? this.showMissions[0].id : null;
            if (this.missionSelected) {
                this.selectMission(this.missionSelected);
            } else {
                this.selectedMission = null;
            }
        },
        fetchMissions() {
            MissionsService.getUserCampaignsList()
                .then((response) => {
                    this.missions = response.data;
                    //   const firstMission = this.missions.filter(
                    //     (missions) => missions.status == 1
                    //   )[0];
                    //   this.missionSelected = firstMission?.id || -1;
                    //   this.selectedMission = firstMission || null;

                    this.missionSelected =
                        this.showMissions.length > 0
                            ? this.showMissions[0].id
                            : null;

                    if (this.missionSelected)
                        this.selectMission(this.missionSelected);
                })
                .catch((error) => console.log(error));
        },
        fetchMissionDetail(id) {
            MissionsService.getUserCampaignDetail(id)
                .then((response) => {
                    this.selectedMission = response;
                })
                .catch((error) => {
                    this.$noty.error(error.message);
                })
                .finally(() => {
                    // this.loaders.whatever = false;
                });
        },
        maxSubStr(str, max_length) {
            if (str.length > max_length) {
                return str.substr(0, max_length) + "..";
            }

            return str;
        },
        launchMission(mission) {
            console.log(mission);
            if (["FEEDBACK", "QUIZZ", "SURVEY"].includes(mission.c_type)) {
                this.$router.push({
                    name: "Quiz",
                    params: {
                        quizz_id: mission.quizz_id,
                        campaign_id: mission.id,
                        review_mode: false,
                    },
                });
                // Launch quizz
            } else if (mission.c_type == "CONTEÚDO + QUIZ") {
                // Check if content is done
                if (mission.mission_content.performance == 0) {
                    this.$router
                        .push({
                            name: "Content Details",
                            params: {
                                content_id: mission.mission_content.id,
                                campaign_id: mission.id,
                                review_mode: false,
                                timestamp: new Date().getTime(), // Force reload
                            },
                        })
                        .catch((err) => {
                            // Ignore the vuex err regarding  navigating to the page they are already on.
                            if (
                                err.name !== "NavigationDuplicated" &&
                                !err.message.includes(
                                    "Avoided redundant navigation to current location"
                                )
                            ) {
                                // But print any other errors to the console
                                logError(err);
                            }
                        });
                }
                // Else launch quizz
                this.$router.push({
                    name: "Quiz",
                    params: {
                        quizz_id: mission.mission_quizz.id,
                        campaign_id: mission.id,
                        review_mode: false,
                    },
                });
            } else if (
                [
                    "CONTENT",
                    "CONTEÚDO + QUIZ",
                    "HTML",
                    "TEXTO",
                    "VIDEO",
                    "IMAGEM",
                    "PDF",
                    "SCORM",
                ].includes(mission.c_type)
            ) {
                // Launch content
                this.$router
                    .push({
                        name: "Content Details",
                        params: {
                            content_id: mission.mission_content.id,
                            campaign_id: mission.id,
                            review_mode: false,
                            timestamp: new Date().getTime(), // Force reload
                        },
                    })
                    .catch((err) => {
                        // Ignore the vuex err regarding  navigating to the page they are already on.
                        if (
                            err.name !== "NavigationDuplicated" &&
                            !err.message.includes(
                                "Avoided redundant navigation to current location"
                            )
                        ) {
                            // But print any other errors to the console
                            logError(err);
                        }
                    });
            } else {
                this.$noty.error("Houve um erro ao encontrar a missão.");
            }
        },
    },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/_variables";

.missions-screen {
    display: flex;
    flex-direction: column;
    padding: 2.2rem 1.5rem;

    &__header {
        display: flex;
        align-items: center;

        & > h1 {
            font-size: 1.75rem;
            color: white;
            font-weight: 400;
            text-shadow: 0.25rem 0.1875rem 0rem #1e1e1e;
            flex-grow: 1;
        }

        &__tabs {
            display: flex;
            gap: 0.75rem;
            flex-grow: 1;

            &__tab {
                background: transparent;
                border: none;
                border-radius: 0;
                color: white;
                outline: none;
                font-size: 1.5rem;
                font-weight: 500;

                &:focus {
                    outline: none;
                }

                &.is-active {
                    border-bottom: 0.3125rem solid #1eb28d;
                    color: #1eb28d;
                }
            }
        }
    }

    &__container {
        margin-top: 1.875rem;
        display: flex;

        &__list {
            flex-shrink: 0;
            position: relative;
            width: 28rem;
            height: 44rem;
            padding: 1.25rem 2rem;
            background: #221b38;
            display: flex;
            flex-direction: column;
            gap: 0.625rem;

            & > h4 {
                font-weight: 700;
                font-size: 1.5rem;
                color: white;
                margin-left: 1.25rem;
            }

            &__missions {
                overflow: auto;
                color: white;

                &__mission {
                    padding: 1.5625rem 1.25rem;

                    &.is-active {
                        background: #362a52;
                        color: white;
                    }
                }
            }
        }

        &__mission-details {
            background: white;
            padding: 1.25rem 1.4375rem;
            flex-grow: 1;
        }
    }
}

.main-box {
    background: #221b38;
    border-radius: 0;
    padding: 0;
    max-height: 44.125rem;
    height: 100%;
}

.page-title {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.9375rem;
    color: #ffffff;
    text-shadow: 0.25rem 0.1875rem 0rem #1e1e1e;
}

.secondary-title {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.9375rem;
    color: #ffffff;
    text-shadow: 0.25rem 0.1875rem 0rem #1e1e1e;
}

.secondary-title.dark {
    color: #221b38;
    text-shadow: none;
    font-weight: 700;
}

.subtitle {
    font-size: 1.5rem;
    line-height: 2.9375rem;
    color: #221b38;
    font-weight: 700;
}

.box.is-active {
    background: #362a52;
    color: white;
}

.progress {
    border-radius: 0;
}

.progress-bar .progress {
    width: calc(100% - 3rem);
    height: 0.625rem;
}

.progress-metric {
    position: absolute;
    right: 0;
    top: -1.125rem;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    background-image: url("/hex_white.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.is-tab-button {
    background: transparent;
    border: none;
    border-radius: 0;
    color: white;
    outline: none !important;
}

.is-tab-button:focus {
    outline: none !important;
}

.is-tab-button.is-active {
    border-bottom: 0.125rem solid white;
}

.mission-detail-column {
    background: #ffffff;
    padding: 1.5rem;
}

.mission-box {
    border-radius: 0;
    box-shadow: none;
    background: #221b38;
    color: #ffffff;
    margin-bottom: 0;
}

.mission-box:not(:last-child) {
    border-bottom: 0.0625rem solid #362a52;
}

.mission-title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem;
}

.mission-description,
.mission-date {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
}

.kpi-name {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #222e42;
}

.kpi-value {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.5625rem;
    color: #1eb28d;
}

.leaderboard-container,
.rewards-container {
    max-height: 12.5rem;
    overflow-y: auto;
}

.leaderboard-table {
    width: 100%;
}

.leaderboard-table tr.active {
    border-bottom: 0.125rem solid #1eb28d;
    background: #ddd9e7;
}

.leaderboard-table tr {
    border-top: 0.125rem solid #ddd9e7;
}

.leaderboard-table tr:last-of-type {
    border-bottom: 0.125rem solid #ddd9e7;
}

.leaderboard-table tr td {
    padding: 0.625rem;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    letter-spacing: 0.01em;
    color: #221b38;
}

.reward h5 {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    letter-spacing: 0.01em;
    color: #221b38;
}

.reward p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    letter-spacing: 0.01em;
    color: #221b38;
}

.mission-status {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        align-items: center;
        gap: 1.25rem;

        & > svg {
            width: 2.6rem;
            flex-shrink: 0;
            color: #221b38;
        }

        &__info {
            color: #221b38;
            width: 100%;

            &__title {
                font-size: 1.25rem;
                font-weight: 700;
            }

            &__description {
                display: inline-block;
                font-weight: 500;
                font-size: 0.875rem;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 80%;
                white-space: nowrap;
            }
        }
    }

    &__progress {
        display: flex;
        gap: 0.3rem;
        align-items: center;
        color: $main-color;
        margin-top: -0.625rem;

        & > progress {
            height: 0.625rem;
            margin-bottom: 0;
            color: $main-color;
            background-color: #ddd9e7;

            &::-moz-progress-bar,
            &::-webkit-progress-value {
                background: $main-color;
            }
        }

        figure {
            position: relative;
            display: flex;

            & > .incomplete {
                color: #ddd9e7;
            }

            & > figcaption {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #221b38;
                font-weight: 400;
                font-size: 0.75rem;
            }
        }
    }
}
</style>
