const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;

import Product from "@/entities/Product";
const model = Product;

const parseAquiredProduct = (apiAquiredProduct) => {
    const parsedAquiredProduct = {
        id: apiAquiredProduct.product_id,
        name: apiAquiredProduct.product_name,
        description: '',
        price: apiAquiredProduct.points,
        image: apiAquiredProduct.product_image,
        purchase_status: apiAquiredProduct.status ?? null,
        store_request_id: apiAquiredProduct.store_request_id,
        status_date: apiAquiredProduct.status_date,
    };
    return new model(parsedAquiredProduct);
};

export default class StoreService {
    static getProductsList() {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/products`)
                .then((response) => {
                    let products = response.data;
                    resolve(products);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static getProductDetail(id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/products/detail`, { id })
                .then((response) => {
                    let product = response.data;
                    resolve(product);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static getAcquiredProductsList() {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/products`)
                .then((response) => {
                    let products = response.data.map(parseAquiredProduct);
                    resolve(products);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static purchaseProduct(id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/products/redeem`, { product_id: id })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }
}