<template>
    <div class="details-wrapper">
        <header>
            <span v-if="content">{{ content.name }}</span>
        </header>
        <div class="details-content">
            <section v-if="content && content.type == 'HTML'" class="details">
                <div v-html="content.html"></div>
                <button class="main-btn next" @click="executeNext()">
                    Seguinte
                </button>
            </section>
            <div v-else-if="content && content.type == 'IMAGE'" class="image">
                <img
                    :src="
                        this.backOfficeUrl +
                        '/storage/contents/' +
                        content.image
                    "
                    alt=""
                />
                <button @click="executeNext()" class="main-btn next">
                    Seguinte
                </button>
            </div>
            <div v-else-if="content && content.type == 'VIDEO'" class="video">
                <iframe
                    v-if="videoIsYoutube"
                    width="774"
                    height="439"
                    :src="content.video"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
                <video controls v-else>
                    <source :src="content.video" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <button class="main-btn next" @click="executeNext()">
                    Seguinte
                </button>
            </div>
            <div
                v-else-if="content && content.type == 'PDF'"
                class="PDF"
                style="text-align: center; width: 100%"
            >
                <PdfViewer
                    class="pdf-container"
                    :name="content.name"
                    :url="pdfUrl"
                />
                <button
                    class="main-btn next"
                    style="margin: 10px auto"
                    @click="executeNext()"
                >
                    Seguinte
                </button>
            </div>
            <div
                v-else-if="content && content.type == 'SCORM'"
                class="scorm-content"
            >
                <iframe
                    :src="scormUrl"
                    width="1010px"
                    height="705px"
                    frameBorder="0"
                    scrolling="no"
                    style="overflow: hidden"
                ></iframe>
            </div>
            <div v-else class="loader" style="width: 3em; height: 3em">
                <div></div>
            </div>

            <!-- <previous-people /> @TODO uncomment once integrated in BO -->
        </div>

        <!-- TODO: Will have to evolve to consider multiple types of missions (ex.: multiple contents, quizzes, etc.) -->
        <MissionResultsModal
            :show="showModalComplete == true"
            :contentTitle="contentTitle"
            :points="contentPoints"
            :score="scormScore"
            :totalScore="scormTotalScore"
            @close="showModalComplete = false"
        />
    </div>
</template>

<script>
import PreviousPeople from "../../components/Content/PreviousUsers.vue";
import MissionsService from "@/services/missions.service";
import PdfViewer from "../../components/Content/PdfViewer.vue";
import MissionResultsModal from "@/components/Missions/MissionResultsModal";

export default {
    components: {
        PreviousPeople,
        PdfViewer,
        MissionResultsModal,
    },
    props: {
        content_id: {
            type: Number | String,
            required: true,
        },
        campaign_id: {
            type: Number,
            required: false,
        },
        review_mode: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showingVideo: false,
            scormScore: 0,
            scormTotalScore: 100,
            contentPoints: 0,
            contentTitle: "",
            showModalComplete: false,
            content: null,
            backOfficeUrl: process.env.VUE_APP_BO_BASE_URL,
            appBaseUrl: process.env.VUE_APP_BASE_URL_HTTPS,
            showNativePDFViewer: true,
            user: this.$store.getters["user/userProfile"],
        };
    },
    created() {
        this.getContentCampaignDetail();
    },
    computed: {
        videoIsYoutube() {
            return this.content.video.includes("youtube");
        },
        pdfUrl() {
            if (!this.content) {
                return null;
            }

            return `${this.backOfficeUrl}/storage/contents/${this.content.file}`;
        },
        scormUrl() {
            return `${this.backOfficeUrl}/scorm-player?campaign_id=${this.campaign_id}&user_id=${this.user.id}`;
        },
    },
    methods: {
        getContentCampaignDetail() {
            MissionsService.getContentDetail(this.content_id)
                .then((resp) => {
                    if (resp == null) {
                        this.goToContentList();
                        this.$noty.error("O conteúdo não foi encontrado.");
                    }

                    this.content = resp;
                })
                .catch((err) => {
                    this.goToContentList();
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        goToContentList() {
            this.$root.handleInternalNavigationTo("Content");
        },
        executeNext() {
            if (this.review_mode) {
                this.goToContentList();
            } else {
                if (this.campaign_id == null) {
                    this.$noty.error("Missão não encontrada.");
                    this.$root.handleInternalNavigationTo("Missions");
                }

                this.completeContentCampaign(this.campaign_id);
            }
        },
        completeContentCampaign(campaign_id) {
            if (campaign_id == null) {
                this.$noty.error("Erro ao completar a missão.");
                this.$root.handleInternalNavigationTo("Missions");
            }

            // this.$noty.success("Parabéns, missão de conteúdo completada!");
            // this.$root.handleInternalNavigationTo("Missions");

            // let type = this.content.type == "HTML" ? this.content.type : this.content.type.charAt(0).toUpperCase() + this.content.type.slice(1).toLowerCase();

            MissionsService.completeContentCampaign(this.campaign_id)
                .then((resp) => {
                    // Update points resp.user_points resp.rewards description: "Continua a tentar" id: 1 image: null name: "0 Pontos" type: "POINTS" value: "750.00"
                    this.$store.dispatch(
                        "user/updateUserPoints",
                        resp.user_points
                    );

                    // @TODO Launch mission success popup
                    this.$noty.success(
                        "Parabéns, a missão de conteúdo foi completada!"
                    );
                    this.$root.handleInternalNavigationTo("Missions");
                })
                .catch((err) => {
                    this.goToContentList();
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        showResultsModal(event) {
            // If event.data contains the keys: points, title, score, totalScore then it's a SCORM content and we should show the results modal
            if(this.content !== null && this.content.type === "SCORM" && event.data.points && event.data.title && event.data.score && event.data.totalScore) {
                // If the user is just reviewing, no need to show the results modal
                if(this.review_mode) {
                    return;
                }
                this.contentPoints = event.data.points;
                // If title is empty, uses a placeholder string
                this.contentTitle =
                    event.data.title && event.data.title.length > 0
                        ? event.data.title
                        : "Nome do Conteúdo";
                this.scormScore = event.data.score;
                this.scormTotalScore = event.data.totalScore;
                this.showModalComplete = true;
            } else {
                // DO NOTHING
            }
        },
    },
    mounted() {
        window.addEventListener("message", this.showResultsModal);
    },
};
</script>
<style scoped lang="scss">
.details-wrapper {
    header {
        display: flex;
        align-items: center;
        background-color: #221b38;
        height: 4.4375rem;
        color: white;
        font-weight: 700;
        font-size: 1.5rem;
        padding: 0 1.4375rem;
        margin-bottom: 2.1875rem;
    }

    .details-content {
        display: flex;
        /* padding: 0 21.25rem 60px; */
        padding: 0 10rem 60px;
        justify-content: center;
        gap: 1.5rem;
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;

        .video {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            align-items: center;
        }

        .image {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            align-items: center;
        }

        .next {
            width: 14.5rem;
            margin: 0 auto;
        }
    }

    .details {
        background-color: white;
        padding: 3.75rem 9.875rem;
        display: flex;
        flex-direction: column;
        gap: 3rem;

        h1 {
            font-weight: 600;
            font-size: 1.75rem;
        }

        &__preview {
            width: 100%;
            height: 20rem;
            background-color: #5d1f8e;
        }
    }
}

.pdf-container {
    width: 100%;
    height: 900px;
    overflow: auto;
}

.scorm-content {
    margin-top: -30px;
}
</style>
