<template>
  <div class="message-content">
    <div v-if="message" class="message-content__header">
      <div class="message-content__header__details">
        <img
          v-if="!needImageFallback"
          class="message-content__header__details__icon"
          :src="apiUrl + '/' + message.icon"
          @error="changeToFallback"
        />
        <div v-else class="message-content__header__details__icon">
          <message-defaul-icon />
        </div>
        <div>
          <p class="message-content__header__details__title">{{ message.subject }}</p>
          <p>{{ message.type }}</p>
        </div>
      </div>
      <span class="message-content__header__date">{{ message.date }}</span>
    </div>
    <div v-if="message" class="message-content__text">
      <span v-html="message.body"></span>
    </div>
    <div v-else class="message-content__empty">
      <computer-icon class="message-content__empty__laptop" />
      <p>{{ messagesCount ? "Nenhuma mensagem selecionada" : "Sem Mensagens" }}</p>
    </div>
  </div>
</template>

<script>
import ComputerIcon from "../Utils/Icons/ComputerIcon.vue";
import MessageDefaulIcon from "../Utils/Icons/MessageDefaulIcon.vue";
export default {
  components: { ComputerIcon, MessageDefaulIcon },
  props: { message: Object, messagesCount: { type: Number, default: 0 } },
  data() {
    return {
      apiUrl: process.env.VUE_APP_BO_BASE_URL,
      needImageFallback: false,
    };
  },
  methods: {
    changeToFallback() {
      this.needImageFallback = true;
    },
  },
};
</script>

<style scoped lang="scss">
.message-content {
  background-color: white;
  position: relative;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 1.37rem 2.875rem 1.125rem 2.875rem;
    border-bottom: #ddd9e7 0.125rem solid;

    &__details {
      display: flex;
      gap: 0.5rem;

      &__icon {
        width: 3.125rem;
        height: 3.125rem;
        flex-shrink: 0;
        background-color: #403c56;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }

      &__title {
        color: #403c56;
        font-weight: 700;
        font-size: 1.25rem;
      }
    }
    &__date {
      align-self: flex-end;
      color: #8f81b2;
      font-weight: 400;
      font-size: 0.875rem;
    }
  }

  &__text {
    font-size: 1.125rem;
    padding: 2.4rem 2.875rem;
    overflow: auto;

    z-index: 2;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__laptop {
      width: 8.75rem;
      height: 6.25rem;
      margin-bottom: 1.25rem;
    }

    & > p {
      font-weight: 600;
      font-size: 1.5rem;
      color: #8f81b2;
    }
  }

  &::before {
    content: "";
    background: url("../../assets/images/messages/circles-message.svg");
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 17rem;
    height: 26rem;
    z-index: 1;
  }
}
</style>
