<template>
    <svg width="100%" height="100%" viewBox="0 0 42 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9146 14.939C25.0399 14.939 28.3841 11.5948 28.3841 7.46951C28.3841 3.34421 25.0399 0 20.9146 0C16.7893 0 13.4451 3.34421 13.4451 7.46951C13.4451 11.5948 16.7893 14.939 20.9146 14.939ZM20.9146 13.0183C23.9202 13.0183 26.3567 10.5818 26.3567 7.57622C26.3567 4.57065 23.9202 2.13415 20.9146 2.13415C17.909 2.13415 15.4725 4.57065 15.4725 7.57622C15.4725 10.5818 17.909 13.0183 20.9146 13.0183Z" :fill="color"/>
        <path d="M19.6727 9.81485V8.52091C19.9736 8.28687 20.2444 8.02607 20.4851 7.73853C20.7259 7.45099 20.9164 7.14674 21.0569 6.82576C21.1973 6.4981 21.2675 6.1604 21.2675 5.81268C21.2675 5.56526 21.2207 5.38806 21.1271 5.28106C21.0402 5.16739 20.9098 5.11055 20.7359 5.11055C20.5754 5.11055 20.4484 5.15736 20.3547 5.25097C20.2678 5.34459 20.2243 5.47499 20.2243 5.64216C20.2243 5.74915 20.2344 5.84612 20.2544 5.93305C20.2812 6.01329 20.3113 6.10022 20.3447 6.19384L18.8702 6.53488C18.8167 6.37439 18.7733 6.22059 18.7398 6.07347C18.7131 5.91967 18.6997 5.75918 18.6997 5.59201C18.6997 5.00355 18.8769 4.5455 19.2313 4.21783C19.5924 3.88348 20.1073 3.71631 20.776 3.71631C21.4514 3.71631 21.9696 3.89351 22.3307 4.24792C22.6985 4.60234 22.8824 5.1072 22.8824 5.76253C22.8824 6.16375 22.8156 6.54491 22.6818 6.906C22.5481 7.2671 22.3508 7.60814 22.09 7.92911C21.8359 8.25009 21.525 8.551 21.1572 8.83186V9.81485H19.6727ZM19.6225 11.9513V10.3966H21.2174V11.9513H19.6225Z" :fill="color"/>
        <path d="M5.33521 21.6616C5.33521 21.0133 5.86072 20.4878 6.50899 20.4878H8.85655C9.50481 20.4878 10.0303 21.0133 10.0303 21.6616C10.0303 22.3098 9.50481 22.8354 8.85655 22.8354H6.50899C5.86072 22.8354 5.33521 22.3098 5.33521 21.6616Z" :fill="color"/>
        <path d="M31.5852 21.6616C31.5852 21.0133 32.1107 20.4878 32.759 20.4878H35.1065C35.7548 20.4878 36.2803 21.0133 36.2803 21.6616C36.2803 22.3098 35.7548 22.8354 35.1065 22.8354H32.759C32.1107 22.8354 31.5852 22.3098 31.5852 21.6616Z" :fill="color"/>
        <path d="M17.713 21.7681C17.713 22.8289 16.8531 23.6889 15.7923 23.6889C14.7315 23.6889 13.8716 22.8289 13.8716 21.7681C13.8716 20.7074 14.7315 19.8474 15.7923 19.8474C16.8531 19.8474 17.713 20.7074 17.713 21.7681Z" :fill="color"/>
        <path d="M22.8351 21.7681C22.8351 22.8289 21.9752 23.6889 20.9144 23.6889C19.8536 23.6889 18.9937 22.8289 18.9937 21.7681C18.9937 20.7074 19.8536 19.8474 20.9144 19.8474C21.9752 19.8474 22.8351 20.7074 22.8351 21.7681Z" :fill="color"/>
        <path d="M27.9569 21.7681C27.9569 22.8289 27.097 23.6889 26.0362 23.6889C24.9754 23.6889 24.1155 22.8289 24.1155 21.7681C24.1155 20.7074 24.9754 19.8474 26.0362 19.8474C27.097 19.8474 27.9569 20.7074 27.9569 21.7681Z" :fill="color"/>
        <path d="M5.33521 27.6372C5.33521 26.9889 5.86072 26.4634 6.50899 26.4634H8.85655C9.50481 26.4634 10.0303 26.9889 10.0303 27.6372C10.0303 28.2854 9.50481 28.8109 8.85655 28.8109H6.50899C5.86072 28.8109 5.33521 28.2854 5.33521 27.6372Z" :fill="color"/>
        <path d="M31.5852 27.6372C31.5852 26.9889 32.1107 26.4634 32.759 26.4634H35.1065C35.7548 26.4634 36.2803 26.9889 36.2803 27.6372C36.2803 28.2854 35.7548 28.8109 35.1065 28.8109H32.759C32.1107 28.8109 31.5852 28.2854 31.5852 27.6372Z" :fill="color"/>
        <path d="M17.713 27.7437C17.713 28.8045 16.8531 29.6645 15.7923 29.6645C14.7315 29.6645 13.8716 28.8045 13.8716 27.7437C13.8716 26.6829 14.7315 25.823 15.7923 25.823C16.8531 25.823 17.713 26.6829 17.713 27.7437Z" :fill="color"/>
        <path d="M22.8351 27.7437C22.8351 28.8045 21.9752 29.6645 20.9144 29.6645C19.8536 29.6645 18.9937 28.8045 18.9937 27.7437C18.9937 26.6829 19.8536 25.823 20.9144 25.823C21.9752 25.823 22.8351 26.6829 22.8351 27.7437Z" :fill="color"/>
        <path d="M27.9569 27.7437C27.9569 28.8045 27.097 29.6645 26.0362 29.6645C24.9754 29.6645 24.1155 28.8045 24.1155 27.7437C24.1155 26.6829 24.9754 25.823 26.0362 25.823C27.097 25.823 27.9569 26.6829 27.9569 27.7437Z" :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.4594 7.89642C26.4621 7.82562 26.4634 7.75447 26.4634 7.68301C26.4634 6.84397 26.2772 6.04842 25.9438 5.33545H40.5488C41.256 5.33545 41.8293 5.90874 41.8293 6.61594V33.7196C41.8293 34.4268 41.256 35.0001 40.5488 35.0001H1.28049C0.573293 35.0001 0 34.4268 0 33.7196V6.61594C0 5.90874 0.573294 5.33545 1.28049 5.33545H15.8855C15.5521 6.04842 15.3659 6.84397 15.3659 7.68301C15.3659 7.75447 15.3672 7.82562 15.3699 7.89642H2.34756V32.4391H39.4817V7.89642H26.4594Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    props: { 
        color: { 
            type: String,
            default: "#8F81B2" 
        } 
    },
};
</script>
<style scoped lang="scss">
</style>