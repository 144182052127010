const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;

export default class LoungeService {
    /**
     * Get lounge users.
    */
    static getLounge(group_scope = null) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/lounge`, { group_scope })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }
}