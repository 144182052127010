<template>
    <div class="lounge" v-if="heroes.length >= 4">
        <div class="row">
            <LoungeContent
                @open-kudos-modal="handleKudosButton"
                :hero="heroes[0]"
                class="row-content first cyan"
            />
            <LoungeContent
                @open-kudos-modal="handleKudosButton"
                :hero="heroes[1]"
                :invert="true"
                class="row-content second yellow"
            />
        </div>
        <div class="row">
            <LoungeContent
                @open-kudos-modal="handleKudosButton"
                :hero="heroes[2]"
                :invert="true"
                class="row-content first red"
            />
            <LoungeContent
                @open-kudos-modal="handleKudosButton"
                :hero="heroes[3]"
                class="row-content second purple"
            />
        </div>
    </div>
    <div class="empty-lounge" v-else></div>
</template>

<script>
import LoungeService from "@/services/lounge.service";
import HeroStats from "../Hero/HeroStats.vue";
import LoungeContent from "./LoungeContent.vue";
import { mapGetters } from "vuex";

export default {
    components: { HeroStats, LoungeContent },
    data() {
        return {
            heroes: [],
        };
    },
    created() {
        this.getLoungeUsers();
    },
    computed: {
        ...mapGetters("user", ["authUser"]),
    },
    methods: {
        getLoungeUsers() {
            LoungeService.getLounge(2)
                .then((resp) => {
                    this.heroes = resp.users.map(function (u) {
                        let attributes = JSON.parse(u.attributes);

                        if (
                            attributes != null &&
                            attributes.hasOwnProperty("default_avatar")
                        ) {
                            u.default_avatar = attributes.default_avatar;
                        } else {
                            u.default_avatar = "hero-dummy";
                        }

                        return u;
                    });

                    this.heroes = this.heroes.filter((hero) => {
                        return hero.user_id != this.authUser.id;
                    });
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        handleKudosButton(lounge_user) {
            this.$emit("open-kudos-modal", lounge_user);
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_mixins.scss";
@import "@/assets/scss/_variables.scss";

.lounge {
    position: relative;
    background: rgba(0, 0, 0, 0.2);
    margin-top: 1rem;
    background: white;
    filter: drop-shadow(0.125rem 0.125rem 0rem #000000);
    display: grid;
    grid-template-rows: auto auto;
    padding: 0.3rem;
    row-gap: 0.3rem;

    @include screen-min($screen-lg) {
        margin-top: 2rem;
    }
}

.empty-lounge {
    position: relative;
    background-image: url("../../../assets/images/Lounge/salas.svg");
    background-size: cover;
    margin-top: 1rem;
    filter: drop-shadow(0.125rem 0.125rem 0rem #000000);
    display: grid;
    grid-template-rows: auto auto;
    padding: 0.3rem;
    row-gap: 0.3rem;

    @include screen-min($screen-lg) {
        margin-top: 2rem;
    }
}

.row {
    display: flex;
}

.row-content {
    height: 100%;
    background-repeat: no-repeat;
    background-position: left bottom, right top, center;
    background-size: 40%, 30%, 140%;
    padding: 0.3rem;

    @include screen-min($screen-lg) {
        padding: 1rem;
    }
}

.first {
    width: calc(50% + 2.5rem);
    clip-path: polygon(0 0, 100% 0, calc(100% - 2.8rem) 100%, 0% 100%);
    padding-right: 3.5rem;
}

.second {
    clip-path: polygon(2.8rem 0, 100% 0, 100% 100%, 0% 100%);
    width: calc(50% + 2.45rem);
    margin-left: -2.45rem;
    padding-left: 3.5rem;
}

.red {
    background-image: url("../../../assets/images/Lounge/bubbles-left.svg"),
        url("../../../assets/images/Lounge/bubbles-right.svg"),
        url("../../../assets/images/Lounge/red-background.svg");
        /* url("../../../assets/images/Lounge/xmas_red_bg.svg"); */
}

.purple {
    background-image: url("../../../assets/images/Lounge/bubbles-left.svg"),
        url("../../../assets/images/Lounge/bubbles-right.svg"),
        url("../../../assets/images/Lounge/purple-background.svg");
        /* url("../../../assets/images/Lounge/xmas_purple_bg.svg"); */
}

.yellow {
    background-image: url("../../../assets/images/Lounge/bubbles-left.svg"),
        url("../../../assets/images/Lounge/bubbles-right.svg"),
        url("../../../assets/images/Lounge/yellow-background.svg");
        /* url("../../../assets/images/Lounge/xmas_yellow_bg.svg"); */
}

.cyan {
    background-image: url("../../../assets/images/Lounge/bubbles-left.svg"),
        url("../../../assets/images/Lounge/bubbles-right.svg"),
        url("../../../assets/images/Lounge/cyan-background.svg");
        /* url("../../../assets/images/Lounge/xmas_cyan_bg.svg"); */
}
</style>
