<template>
  <div class="main" :style="`width: ${size}; height: ${size}`">
    <svg class="svg" :viewBox="`${DEFAULT_SIZE / 2} ${DEFAULT_SIZE / 2} ${DEFAULT_SIZE} ${DEFAULT_SIZE}`">
      <circle
        class="full-circle"
        :cx="DEFAULT_SIZE"
        :cy="DEFAULT_SIZE"
        :r="(DEFAULT_SIZE - THICKNESS) / 2"
        fill="rgba(0,0,0,0.3)"
        :stroke-dasharray="2 * Math.PI * ((DEFAULT_SIZE - THICKNESS) / 2)"
        :stroke-width="THICKNESS"
      />
      <circle
        class="circle"
        :style="`stroke: ${color}`"
        :cx="DEFAULT_SIZE"
        :cy="DEFAULT_SIZE"
        :r="(DEFAULT_SIZE - THICKNESS) / 2"
        fill="none"
        ref="dash"
        :stroke-dasharray="2 * Math.PI * ((DEFAULT_SIZE - THICKNESS) / 2)"
        :stroke-width="THICKNESS"
      />
      <circle
        class="circle"
        :style="`stroke: ${colorExtra}`"
        :cx="DEFAULT_SIZE"
        :cy="DEFAULT_SIZE"
        :r="(DEFAULT_SIZE - THICKNESS_EXTRA) / 2"
        fill="none"
        ref="dashExtra"
        :stroke-dasharray="2 * Math.PI * ((DEFAULT_SIZE - THICKNESS_EXTRA) / 2)"
        :stroke-width="THICKNESS_EXTRA"
      />
      <text
        :y="DEFAULT_SIZE"
        :x="DEFAULT_SIZE"
        text-anchor="middle"
        fill="white"
        class="text"
        dy="0.35em"
        :transform="`rotate(90, ${DEFAULT_SIZE}, ${DEFAULT_SIZE})`"
      >
        {{ percentageText }}{{ units }}
      </text>
    </svg>
  </div>
</template>

<script>
const THICKNESS = 3.6;
const THICKNESS_EXTRA = THICKNESS / 2;
const DEFAULT_SIZE = 44;

export default {
  name: "CircularProgress",
  props: {
    percentage: { type: Number, required: true },
    result: { type: Number | String, required: true },
    units: { type: String, default: "" },
    size: { type: String, default: "20%" },
    color: { type: String, default: "#fff" },
    colorExtra: { type: String, default: "#fff" },
  },
  created() {
    this.THICKNESS = THICKNESS;
    this.THICKNESS_EXTRA = THICKNESS_EXTRA;
    this.DEFAULT_SIZE = DEFAULT_SIZE;
  },
  mounted() {
    this.calculateCircleOffset(this.$refs.dash, this.percentage);
    this.calculateCircleOffset(this.$refs.dashExtra, this.extraPercentage);
  },
  methods: {
    calculateCircleOffset(elem, percentage) {
      if (percentage > 100) percentage = 100;
      else if (percentage < 0) percentage = 0;
      const r = elem.getAttribute("r") ?? 90;
      const c = Math.PI * (r * 2);

      const pct = ((100 - percentage) / 100) * c;
      elem.style["stroke-dashoffset"] = pct;
    },
  },
  computed: {
    percentageText() {
      return this.result.toString();
    },
    extraPercentage() {
      if (this.percentage > 100) return this.percentage - 100;
      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_mixins.scss";
@import "@/assets/scss/_variables.scss";

.svg {
  display: block;
}

.main {
  transform: rotate(-90deg);
  flex-shrink: 0;
}

circle {
  stroke: white;
}

.text {
  font-size: 1rem;

  @include screen-min($screen-lg) {
    font-size: 0.8rem;
  }

  @include screen-min($screen-xl) {
    font-size: 0.6rem;
  }
}
</style>
