<template>
  <div class="avatar-selection">
    <h1 class="avatar-selection__title">Seleção do Avatar</h1>
    <div class="avatar-selection__main">
      <button class="avatar-selection__main__arrow" @click="moveLeft">
        <img src="@/assets/images/icons/chevron-left.svg" alt="chevron left" />
      </button>
      <div class="avatar-selection__main__screen">
        <div class="avatar-selection__main__screen__avatars">
          <div
            class="avatar-selection__main__screen__avatars__wrapper"
            tabindex="-1"
            v-for="(avatar, i) in avatars"
            :key="i"
            @click="selectedAvatar = avatar"
          >
            <img
              :src="`/heros/${avatar.url}.svg`"
              :class="
                selectedAvatar == avatar
                  ? 'selected-avatar'
                  : 'not-selected-avatar'
              "
            />
          </div>
        </div>
      </div>
      <button class="avatar-selection__main__arrow" @click="moveRight">
        <img
          src="@/assets/images/icons/chevron-right.svg"
          alt="chevron right"
        />
      </button>
    </div>
    <button class="main-btn button" @click="saveAvatar">Escolher</button>
  </div>
</template>

<script>
import AvatarService from "@/services/avatar.service.js";
import { mapGetters } from 'vuex';

export default {
  name: "AvatarSelection",
  props: [],
  data() {
    return {
      avatars: [
        { id: 0, url: "hero-female" },
        { id: 1, url: "hero-dummy" },
        { id: 2, url: "hero-male" },
      ],
      selectedAvatar: null,
    };
  },
  created() {
    this.selectedAvatar = this.avatars[1];
  },
  computed: {
    ...mapGetters('user', ['getDefaultAvatar']),
  },
  methods: {
    selectAvatar(avatar) {
      this.selectedAvatar = avatar;
    },
    saveAvatar() {
      AvatarService.saveDefaultAvatar(`{ "default_avatar": "${this.selectedAvatar['url']}" }`)
        .then((resp) => {
          this.$store.dispatch('user/updateUserAttributes', resp.attributes);

          this.$root.handleInternalNavigationTo("Dashboard");
        })
        .catch((error) => {
          this.$noty.error(error.message);
        });
    },
    moveRight() {
      const index = this.avatars.indexOf(this.selectedAvatar);
      const newIndex = (index + 1) % this.avatars.length;
      this.selectedAvatar = this.avatars[newIndex];
    },
    moveLeft() {
      const index = this.avatars.indexOf(this.selectedAvatar);
      const newIndex = (index - 1 + this.avatars.length) % this.avatars.length;
      this.selectedAvatar = this.avatars[newIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-selection {
  margin-top: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__title {
    font-weight: 700;
    font-size: 2.25rem;
    color: white;
    margin-bottom: 17px;
  }

  &__main {
    display: flex;
    align-items: center;
    margin-bottom: 5.5rem;
    gap: 0.4375rem;

    &__arrow {
      width: 2.9375rem;
      height: 6.75rem;
      background: #221b38;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        width: 1.7rem;
      }
    }

    &__screen {
      width: 57.6875rem;
      height: 25.5rem;
      background: url("../../assets/images/avatar-selection-background.svg")
        no-repeat;
      background-color: #6b3ac7;
      background-size: cover;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &__avatars {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        &__wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10rem;
          cursor: pointer;

          & img {
            flex-shrink: 0;
            transition-property: all;
            transition-duration: 0.3s;
            pointer-events: none;

            &.selected-avatar {
              height: 18.75rem;
            }

            &.not-selected-avatar {
              height: 12.5rem;
              filter: brightness(0.4);
            }
          }
        }
      }
    }
  }
}

.button {
  max-width: 18.25rem;
}
</style>
