<template>
    <base-modal
        :show="show"
        maxWidth="50rem"
        @close="handleClose"
        backgroundColor="#362A52"
    >
        <div class="mission">
            <h1 class="mission__title">Missão Concluída!</h1>
            <p class="mission__type">"{{ contentTitle }}""</p>

            <div class="mission__currency">
                <div>
                    <img
                        src="../../assets/images/currency.svg"
                        alt="currency"
                    />
                    <span>{{ points }} pts</span>
                </div>
                <!-- <div>
                    <img src="../../assets/images/badges/badge_placeholder.svg" alt="badge">
                    <span class="">{{ missionBadges }}x</span>
                </div> -->
            </div>

            <!-- <div v-else class="mission__failed">
                <img src="../../assets/images/missions/red-arrow.svg" alt="left arrow">
                <p>Oh... Da próxima consegues!</p>
                <img class="arrow-right" src="../../assets/images/missions/red-arrow.svg" alt="right arrow">
            </div> -->

            <!-- <div class="mission__results">
                <span>Respostas <em>5</em></span>
                <span>Possíveis <em>5</em></span>
                <span>Performance <em>{{ mission.performance }}%</em></span>
            </div> -->

            <div class="mission__results">
                <span>Score <em>{{ score }}</em>/<em>{{ totalScore }}</em> </span>
            </div>

            <button class="main-btn mission__button" @click="$router.push({ name: 'Missions'} );">
                Seguinte
            </button>
        </div>
    </base-modal>
</template>
<script>
import BaseModal from "@/components/BaseModal";

export default {
    name: "MissionResultsModal",
    components: { 
        BaseModal 
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        /* mission: {
            type: Object,
            required: true,
        }, */
        contentTitle: {
            type: String,
            required: false,
        },
        points: {
            type: Number,
            required: false, 
        },
        score: {
            type: Number,
            required: false, 
        },
        totalScore: {
            type: Number,
            required: false, 
        }
    },
    data() {
        return {
            // missionBadges: 2,
            // missionCurrency: 13200,
            // success: false,
        };
    },
    methods: {
        handleClose() {
            this.$emit("close");
        },
    },
};
</script>
<style scoped lang="scss">
$gap: 2.8rem;

.mission {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;

    & * {
        z-index: 2;
    }

    &__title {
        text-shadow: 0.27rem 0.27rem 0 black;
        font-weight: 700;
        font-size: 3rem;
        text-transform: uppercase;
    }

    &__type {
        font-weight: 600;
        font-size: 1.6rem;
        margin-bottom: 2.8rem;
        margin-bottom: $gap;
    }

    &__currency {
        font-weight: 700;
        font-size: 3.3rem;
        margin-bottom: $gap;
        display: flex;
        gap: 2.5rem;

        & div {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            & > img {
                height: 3.75rem;
            }
        }
    }

    &__failed {
        margin-bottom: $gap;
        display: flex;
        align-items: center;

        & > p {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.93rem;
            color: #f80f0f;
            text-shadow: 0 0.2rem 0 black;
            position: relative;
        }

        & img {
            width: 6.2rem;
            height: 3.75rem;
        }

        & .arrow-right {
            transform: rotate(180deg);
        }
    }

    &__results {
        display: flex;
        gap: 1.8rem;
        text-transform: uppercase;
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 6rem;

        & > span > em {
            font-style: normal;
            color: #3fc1d2;
        }
    }

    &__button {
        width: 25rem;
        font-weight: 600;
    }

    &::before {
        content: "";
        position: absolute;
        background: url("../../assets/images/missions/circles-topleft.svg");
        background-size: 100% 100%;
        top: 0;
        left: 0;
        width: 9.3rem;
        height: 14.1rem;
    }

    &::after {
        content: "";
        background: url("../../assets/images/missions/circles-right.svg");
        background-size: 100% 100%;
        position: absolute;
        top: 47.66%;
        right: 0;
        width: 9.3rem;
        height: 14.1rem;
    }
}
</style>
