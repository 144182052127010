<template>
  <section class="terms">
    <img class="terms__logo" src="/images/myarena-512.png" alt="logo" />

    <div class="terms__content">
      <h1>Termos & Condições de Uso</h1>
      <a href="/myarena-termos-e-condicoes.pdf" target="_blank">Termos e Condições</a>

      <div class="terms__content__actions">
        <button @click="accept">Li e aceito os Termos e Condições</button>
        <button @click="reject">Recusar</button>
      </div>
    </div>
  </section>
</template>

<script>
import UserService from "../../services/user.service";
export default {
  data() {
    return {};
  },
  methods: {
    accept() {
      UserService.acceptTermsAndConditions()
        .then((response) => {
          if(this.$store.getters["user/userProfile"].first_login)
            this.$root.handleInternalNavigationTo("RedefinePasswordFirstLogin");
          else
            this.$root.handleInternalNavigationTo("Dashboard");

          //this.$root.handleInternalNavigationTo("AvatarSelection");
        })
        .catch((error) => {
          this.$noty.error("Erro ao aceitar termos e condições. Tente novamente mais tarde");
        });
    },
    reject() {
      UserService.rejectTermsAndConditions()
        .then((response) => {
          this.$store.dispatch("user/logoutUser");
          this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          this.$noty.error("Erro ao recusar termos e condições. Tente novamente mais tarde");
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/variables";

.terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 2rem;

  &__logo {
    width: 5.9375rem;
  }

  &__content {
    margin-top: 4.4375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 38rem;

    & > h1 {
      font-size: 2.25rem;
      font-weight: 700;
      color: white;
      margin-bottom: 1.875rem;
    }

    & > a {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 2.375rem;
      text-decoration-line: underline;
      margin-bottom: 8rem;

      color: #ffffff;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      width: 100%;

      & > button {
        background: 0;
        color: white;
        font-size: 1.25rem;
        font-weight: 700;
        width: 100%;
        border: transparent 0.1875rem solid;
        height: 3.75rem;
        transition: border, color;
        transition-duration: 200ms;

        &:active,
        &:focus,
        &:hover {
          border: $main-color 0.1875rem solid;
          color: $main-color;
        }
      }
    }
  }
}
</style>
