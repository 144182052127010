<template>
  <div class="news-wrapper">
    <img src="../../assets/images/Dashboard/news-icon.svg" alt="news-icon" />
    <div class="news-content">
      <div class="ticker"
        :style="`animation-duration: ${tickerDuration}; -webkit-animation-duration: ${tickerDuration}s`">
        <div v-html="item" v-for="item in news" class="ticker__item news-message">{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import MessagesService from "@/services/messages.service";

const CENTER_GROUP_TYPE_ID = 2;
const SERVICE_GROUP_TYPE_ID = 3;

export default {
  data() {
    return {
      news: [],
    };
  },
  computed: {
    // Set duration per item. If a fixed value is set, the animation duration will be faster the more items it has
    tickerDuration: function () {
      return this.news.length * 8;
    }
  },
  created() {
    this.getSocialFeedMessages();
  },
  methods: {
    getSocialFeedMessages() {
      MessagesService.getFeedMessages({
        group_scope: SERVICE_GROUP_TYPE_ID, // Só mostra os do seu serviço (Técnico vs não Técnico)
        limit: 50
      })
        .then((resp) => {
          this.news = resp.map((msg) => msg.body);
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped lang="scss">
.news-wrapper {
  background: black;
  display: flex;
  align-items: center;
  padding: 0 1.25rem 0 1.25rem;
  overflow: hidden;

  & img {
    width: 2.8925rem;
    height: 2.525rem;
  }
}

.news-content {
  width: 100%;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  box-sizing: content-box;
  padding-left: 100%;
  overflow: clip;

  .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;

    &__item {
      display: inline-block;
      padding: 0 2rem;
      font-size: 2rem;
      color: white;
    }
  }
}

.news-message {
  color: white;
  font-size: 1.8rem;
}



@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
</style>