<template>
    <div class="message-content">
        <div v-if="mission" class="message-content__text">
            <div class="mission-detail">
                <h2 class="secondary-title dark">Detalhe da Missão</h2>

                <div class="media">
                    <div class="media-image">
                        <img
                            v-if="mission.image"
                            :src="
                                backOfficeUrl +
                                '/storage/campaigns/' +
                                mission.image
                            "
                            class="mt-2"
                            style="
                                width: 18.75rem;
                                height: 12.5rem;
                                background: #221b38;
                            "
                        />
                        <div
                            v-else
                            class="mt-2"
                            style="
                                width: 18.75rem;
                                height: 12.5rem;
                                background: #221b38;
                            "
                        ></div>
                    </div>
                    <div class="media-content pl-4">
                        <p>Válido até {{ mission.enddate }}</p>
                        <h1 class="title mt-2">{{ mission.name }}</h1>
                        <p class="description">
                            {{ mission.description }}
                        </p>

                        <div class="columns mt-3">
                            <div class="column is-9">
                                Progresso da missão
                                {{
                                    mission.status == 1
                                        ? parseInt(mission.date_progress || 0)
                                        : 100
                                }}%
                                <ProgressBar
                                    style="margin-top: 0.625rem"
                                    :progress="
                                        mission.status == 1
                                            ? parseInt(
                                                  mission.date_progress || 0
                                              )
                                            : 100
                                    "
                                />
                            </div>
                            <div class="column is-3">
                                <button
                                    v-if="
                                        [
                                            'FEEDBACK',
                                            'QUIZZ',
                                            'SURVEY',
                                            'CONTENT',
                                            'CONTEÚDO + QUIZ',
                                            'CONTENT_QUIZ',
                                            'HTML',
                                            'TEXTO',
                                            'PDF',
                                            'VIDEO',
                                            'IMAGEM',
                                            'SCORM',
                                        ].includes(mission.c_type) &&
                                        mission.status == 1
                                    "
                                    class="button is-secondary mt-4 is-fullwidth main-btn"
                                    style="height: 3rem"
                                    @click="$emit('start-mission', mission)"
                                >
                                    Começar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div class="columns">
                    <div
                        v-if="
                            ![
                                'FEEDBACK',
                                'QUIZZ',
                                'SURVEY',
                                'CONTENT',
                                'CONTEÚDO + QUIZ',
                                'HTML',
                                'TEXTO',
                                'PDF',
                                'VIDEO',
                                'IMAGEM',
                                'SCORM',
                            ].includes(mission.c_type)
                        "
                        class="column is-4"
                    >
                        <h3 class="subtitle">
                            KPI em Missão: #{{ mission.kpis.length }}
                        </h3>

                        <div class="columns is-multiline">
                            <div
                                v-for="(kpi, i) in mission.kpis"
                                :key="i"
                                class="column is-6"
                            >
                                <h5 class="kpi-name">{{ kpi.friendlyname }}</h5>
                                <div>
                                    <h4 class="kpi-value">
                                        {{
                                            kpi.result == null ? 0 : kpi.result
                                        }}
                                        {{ kpi.units }}
                                    </h4>
                                    <p v-if="kpi.goal != 0" class="kpi-goal">
                                        / {{ kpi.goal }} {{ kpi.units }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else class="column is-4">
                        <h3 class="subtitle">Estado da missão</h3>

                        <div
                            class="mission-status"
                            v-if="mission.mission_content"
                        >
                            <div class="mission-status__header">
                                <mission-icon />
                                <div class="mission-status__header__info">
                                    <p
                                        class="mission-status__header__info__title"
                                    >
                                        {{ mission.mission_content.name }}
                                    </p>
                                    <p
                                        title="Descrição da missão"
                                        class="mission-status__header__info__description"
                                    >
                                        {{
                                            mission.mission_content.description
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div class="mission-status__progress">
                                <progress
                                    class="progress"
                                    :value="
                                        mission.mission_content.performance *
                                        100
                                    "
                                    max="100"
                                >
                                    {{
                                        mission.mission_content.performance *
                                        100
                                    }}%
                                </progress>
                                <figure class="hex-icon">
                                    <check-hex-icon
                                        v-if="
                                            mission.mission_content
                                                .performance *
                                                100 >=
                                            100
                                        "
                                    />
                                    <hex-icon class="incomplete" v-else />
                                    <figcaption
                                        v-if="
                                            mission.mission_content
                                                .performance *
                                                100 <
                                            100
                                        "
                                    >
                                        {{
                                            mission.mission_content
                                                .performance * 100
                                        }}%
                                    </figcaption>
                                </figure>
                            </div>
                        </div>

                        <div
                            class="mission-status"
                            v-if="mission.mission_quizz"
                        >
                            <div class="mission-status__header">
                                <mission-icon />
                                <div class="mission-status__header__info">
                                    <p
                                        class="mission-status__header__info__title"
                                    >
                                        {{ mission.mission_quizz.name }}
                                    </p>
                                    <p
                                        title="Descrição da missão"
                                        class="mission-status__header__info__description"
                                    >
                                        {{ mission.mission_quizz.description }}
                                    </p>
                                </div>
                            </div>
                            <div class="mission-status__progress">
                                <progress
                                    class="progress"
                                    :value="
                                        mission.mission_quizz.performance * 100
                                    "
                                    max="100"
                                >
                                    {{
                                        mission.mission_quizz.performance * 100
                                    }}%
                                </progress>
                                <figure>
                                    <check-hex-icon
                                        v-if="
                                            mission.mission_quizz.performance *
                                                100 >=
                                            100
                                        "
                                    />
                                    <hex-icon class="incomplete" v-else />
                                    <figcaption
                                        v-if="
                                            mission.mission_quizz.performance *
                                                100 <
                                            100
                                        "
                                    >
                                        {{
                                            mission.mission_quizz.performance *
                                            100
                                        }}%
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4">
                        <h3 class="subtitle">Leaderboard</h3>

                        <div class="leaderboard-container">
                            <table class="leaderboard-table">
                                <tr
                                    v-for="(winner, idx) in mission.leaderboard"
                                    :key="idx"
                                    :class="{
                                        active:
                                            winner.id == authUser.id ||
                                            winner.id ==
                                                userProfile.current_group_id,
                                    }"
                                >
                                    <td>{{ idx + 1 }}</td>
                                    <td class="is-flex is-align-items-center">
                                        <img
                                            v-if="winner.profile_picture"
                                            :src="`${backOfficeUrl}/storage/users/${winner.profile_picture}`"
                                            class="image is-32x32 mr-2 rounded"
                                            onerror="this.src='/images/user-image.svg'"
                                        />
                                        <img
                                            v-else
                                            src="@/assets/images/misc/user-image.svg"
                                            class="image is-32x32 mr-2"
                                        />
                                        {{ winner.name }}
                                    </td>
                                    <td>
                                        {{
                                            winner.total_performance == null
                                                ? 0
                                                : parseInt(
                                                      winner.total_performance
                                                  )
                                        }}%
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!-- <div class="columns is-multiline">
                                            <template v-for="i in 3">
                                            <div style="border-top: 0.125rem solid #DDD9E7;">
                                                <div class="column is-2">
                                                    100
                                                </div>
                                                <div class="column is-7">
                                                    Ivo Jaime
                                                </div>
                                                <div class="column is-3">
                                                    100 PTS
                                                </div>
                                            </div>
                                            </template>
                                        </div> -->
                    </div>
                    <div class="column is-4">
                        <h3 class="subtitle">Recompensas</h3>
                        <div class="rewards-container">
                            <div
                                class="media reward"
                                v-for="(reward, idx) in mission.rewards"
                                :key="idx"
                            >
                                <div class="media-image">
                                    <!-- <img
                                        :src="rewardDisplayImage(reward)"
                                        :alt="rewardDisplayValue(reward)"
                                        class="mt-1 image is-48x48 mr-2"
                                    /> -->
                                    <img
                                        v-if="reward.type === 'POINTS'"
                                        :alt="rewardDisplayValue(reward)"
                                        src="@/assets/images/misc/currency_icon_no_circle.svg"
                                        class="mt-1 image reward-size mr-2"
                                    />
                                    <img
                                        v-else
                                        :src="rewardDisplayImage(reward)"
                                        :alt="rewardDisplayValue(reward)"
                                        class="mt-1 image reward-size mr-2"
                                    />
                                </div>
                                <div class="media-content">
                                    <h5 class="is-size-6">
                                        {{ rewardDisplayValue(reward) }}
                                    </h5>
                                    <p>{{ reward.type }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="message-content__empty">
            <computer-icon class="message-content__empty__laptop" />
            <p>
                {{
                    missionsCount ? "Nenhuma missão selecionada" : "Sem Missões"
                }}
            </p>
        </div>
    </div>
</template>

<script>
import ProgressBar from "@/components/Missions/ProgressBar.vue";
import CheckHexIcon from "@/components/Utils/Icons/CheckHexIcon.vue";
import HexIcon from "@/components/Utils/Icons/HexIcon.vue";
import MissionIcon from "@/components/Utils/Icons/MissionIcon.vue";
import ComputerIcon from "../Utils/Icons/ComputerIcon.vue";

import { mapGetters } from "vuex";

export default {
    components: {
        ComputerIcon,
        MissionIcon,
        HexIcon,
        CheckHexIcon,
        ProgressBar,
    },
    props: {
        mission: Object,
        missionsCount: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            backOfficeUrl: process.env.VUE_APP_BO_BASE_URL,
        };
    },
    computed: {
        ...mapGetters("user", ["authUser", "userProfile"]),
    },
    methods: {
        rewardDisplayImage(reward) {
            switch (reward.type) {
                case "POINTS":
                    return "@/assets/images/misc/currency_icon_no_circle.svg";
                case "ACHIEVEMENT":
                    return (
                        this.backOfficeUrl +
                        "/storage/badges/" +
                        JSON.parse(reward.extra).image
                    );
                case "STORE":
                    return (
                        this.backOfficeUrl +
                        "/storage/products/" +
                        JSON.parse(reward.extra).image
                    );
                case "GEAR":
                    return (
                        this.backOfficeUrl +
                        "/storage/avatar/" +
                        JSON.parse(reward.extra).body_part +
                        "/" +
                        JSON.parse(reward.extra).image
                    );
                default:
                    return "@/assets/images/badges/badge_placeholder.svg";
            }
        },
        rewardDisplayValue(reward) {
            if (reward.type == "POINTS") return parseInt(reward.value) + " PTS";

            return JSON.parse(reward.extra).name;
        },
    },
    watch: {
        mission(newMission) {
            // console.log(this.mission);
            if (newMission.leaderboard.length <= 0) return;
            setTimeout(() => {
                const line = document.querySelector("tr.active");
                if (!line) return;
                line.scrollIntoView();
            }, 10);
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.main-box {
    background: #221b38;
    border-radius: 0;
    padding: 0;
    max-height: 44.125rem;
    height: 100%;
}

.page-title {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.9375rem;
    color: #ffffff;
    text-shadow: 0.25rem 0.1875rem 0rem #1e1e1e;
}

.secondary-title {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.9375rem;
    color: #ffffff;
    text-shadow: 0.25rem 0.1875rem 0rem #1e1e1e;
}

.secondary-title.dark {
    color: #221b38;
    text-shadow: none;
    font-weight: 700;
}

.subtitle {
    font-size: 1.5rem;
    line-height: 2.9375rem;
    color: #221b38;
    font-weight: 700;
}

.box.is-active {
    background: #362a52;
    color: white;
}

.progress {
    border-radius: 0;
}

.progress-bar .progress {
    width: calc(100% - 3rem);
    height: 0.625rem;
}

.progress-metric {
    position: absolute;
    right: 0;
    top: -1.125rem;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    background-image: url("/hex_white.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.is-tab-button {
    background: transparent;
    border: none;
    border-radius: 0;
    color: white;
    outline: none !important;
}

.is-tab-button:focus {
    outline: none !important;
}

.is-tab-button.is-active {
    border-bottom: 0.125rem solid white;
}

.mission-detail-column {
    background: #ffffff;
    padding: 1.5rem;
}

.mission-box {
    border-radius: 0;
    box-shadow: none;
    background: #221b38;
    color: #ffffff;
    margin-bottom: 0;
}

.mission-box:not(:last-child) {
    border-bottom: 0.0625rem solid #362a52;
}

.mission-title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem;
}

.mission-description,
.mission-date {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
}

.kpi-name {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #222e42;
}

.kpi-value {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.5625rem;
    color: #1eb28d;
}
.kpi-goal {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2.2rem;
    color: #1eb28d;
}

.leaderboard-container,
.rewards-container {
    max-height: 12.5rem;
    overflow-y: auto;
}

.leaderboard-table {
    width: 100%;
}

.leaderboard-table tr.active {
    border-bottom: 0.125rem solid #1eb28d;
    background: #ddd9e7;
}

.leaderboard-table tr {
    border-top: 0.125rem solid #ddd9e7;
}

.leaderboard-table tr:last-of-type {
    border-bottom: 0.125rem solid #ddd9e7;
}

.leaderboard-table tr td {
    padding: 0.625rem;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    letter-spacing: 0.01em;
    color: #221b38;
}

.reward h5 {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    letter-spacing: 0.01em;
    color: #221b38;
}

.reward p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    letter-spacing: 0.01em;
    color: #221b38;
}

.media-image {
    flex-shrink: 0;
}

.mission-status {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        align-items: center;
        gap: 1.25rem;

        & > svg {
            width: 2.6rem;
            flex-shrink: 0;
            color: #221b38;
        }

        &__info {
            color: #221b38;
            width: 100%;

            &__title {
                font-size: 1.25rem;
                font-weight: 700;
            }

            &__description {
                display: inline-block;
                font-weight: 500;
                font-size: 0.875rem;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 80%;
                white-space: nowrap;
            }
        }
    }

    &__progress {
        display: flex;
        gap: 0.3rem;
        align-items: center;
        color: $main-color;
        margin-top: -0.625rem;

        & > progress {
            height: 0.625rem;
            margin-bottom: 0;
            color: $main-color;
            background-color: #ddd9e7;

            &::-moz-progress-bar,
            &::-webkit-progress-value {
                background: $main-color;
            }
        }

        figure {
            position: relative;
            display: flex;
            width: 3rem;
            height: 3rem;

            & > .incomplete {
                color: #ddd9e7;
            }

            & > figcaption {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #221b38;
                font-weight: 400;
                font-size: 0.75rem;
            }
        }
    }
}
.reward-size {
    width: 3rem;
    height: 3rem;
}

.rounded {
    border-radius: 50%;
}
</style>
