<template>
  <div class="performance-screen">
    <h1>Performance</h1>
    <HeroStats class="performance-screen__stats" :percentage="parseFloat(performanceDiff)" />
    <div class="performance-screen__avatar">
      <hero
        :percentage="parseFloat(latestPerformance)"
        :inLounge="true"
        :health="latestPerformance"
        :defaultAvatar="getDefaultAvatar"
        :avatar="getUserEquippedGear.full_profile_picture"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Hero from "../Dashboard/Hero/Hero.vue";
import HeroStats from "../Dashboard/Hero/HeroStats.vue";

export default {
  components: { Hero, HeroStats },
  name: "SummaryRewards",
  props: {
    resumeData: { type: Object, required: false }
  },
  data() {
    return {
      performance: 50,
    };
  },
  computed: {
    ...mapGetters("user", ["authUser", "getDefaultAvatar", "getUserEquippedGear"]),
    performanceDiff() {
      if (!this.resumeData) {
        return 0
      }
  
      return Math.round(this.resumeData.default_kpi.variation, 2);
    },
    latestPerformance() {
      if (!this.resumeData) {
        return 0
      }
  
      return this.resumeData.default_kpi.user_stats[0].performance;
    }
  },
  // computed: {
  //   ...mapGetters("user", ["authUser", "getDefaultAvatar", "getUserEquippedGear"]),
  //   userHealth() {
  //     if (this.userPerformance <= 100) {
  //       return 0;
  //     }

  //     return this.userPerformance - 100;
  //   },
  // },
};
</script>
<style scoped lang="scss">
.performance-screen {
  display: flex;
  flex-direction: column;
  padding: 2.875rem;
  align-items: center;
  text-transform: uppercase;
  height: 100%;
  overflow: hidden;
  position: relative;

  & > h1 {
    font-weight: 700;
    color: white;
    font-size: 1.75rem;
    margin-bottom: 2.875rem;
  }

  &__stats {
    position: absolute;
    left: 30.77%;
    top: 29.17%;
  }

  &__avatar {
    height: 100%;
    width: 100%;

    & > div {
      width: 100%;
      height: 230%;
    }
  }
}
</style>
