<template>
    <div class="kpis-screen kpis-page-container">
        <div class="header">
            <p>
                Total:
                <span class="counter">{{
                    new String(kpis.length).padStart(3, "0")
                }}</span>
            </p>

            <div class="filter-container">
                <p>Filtrar por Ciclo:</p>
                <MSelect
                    :options="cycles"
                    @updated-select="updateCycle($event)"
                />
            </div>
        </div>

        <div class="kpis-main">
            <div
                class="columns"
                style="margin: 0; align-items: gap: 1rem; stretch; max-height: 44.125rem; height: 44.125rem"
            >
                <div class="column is-4 kpis-column">
                    <h3 class="mb-3 secondary-title">Lista de KPIs</h3>
                    <div class="top-kpis-list">
                        <div
                            class="box is-clickable kpi-box"
                            @click="selectKPI(kpi)"
                            :class="{
                                'is-active': kpi === kpiSelected,
                            }"
                            v-for="(kpi, i) in kpis"
                            :key="i"
                        >
                            <div class="media">
                                <div class="media-image">
                                    <div
                                        class="is-pulled-right progress-metric"
                                    >
                                        {{ parseInt(getKpiResult(kpi))
                                        }}{{ kpi.units }}
                                    </div>
                                </div>
                                <div class="media-content pl-4">
                                    <h3 class="kpi-name">{{ kpi.name }}</h3>
                                    <p>
                                        <small>/{{ getKpiGoal(kpi) }}</small>
                                    </p>
                                    <p>
                                        <small>{{ getKpiDate(kpi) }}</small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="column is-8"
                    style="padding: 0; margin-left: 1.25rem"
                >
                    <div class="box kpis-box">
                        <div
                            class="has-text-centered is-flex is-justify-content-center is-align-items-center"
                        >
                            <button
                                :class="[
                                    'button is-tab-button',
                                    {
                                        'is-active': currentTab === 'results',
                                    },
                                ]"
                                @click="changeTab('results')"
                            >
                                Resultados
                            </button>
                            <button
                                :class="[
                                    'button is-tab-button',
                                    {
                                        'is-active': currentTab === 'evolution',
                                    },
                                ]"
                                @click="changeTab('evolution')"
                            >
                                Evolução
                            </button>
                            <span class="kpi-last-update">
                                última atualização
                                {{ kpiLastUpdate }}
                            </span>
                        </div>

                        <div
                            class="tab table-container"
                            v-if="currentTab === 'results'"
                        >
                            <table class="results-table">
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th style="text-align: right">
                                            Resultado
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="kpiResult in selectedKpiResults"
                                        :key="kpiResult.id"
                                    >
                                        <td width="55%">
                                            {{
                                                formatDate(
                                                    kpiResult.date,
                                                    "dd/MM"
                                                )
                                            }}
                                        </td>
                                        <td width="45%" class="result">
                                            {{ kpiResult.result }}/{{
                                                kpiResult.goal
                                            }}
                                            <span
                                                >{{
                                                    kpiResult.performance
                                                }}%</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            class="tab"
                            v-if="currentTab === 'evolution'"
                            style="height: 100%"
                        >
                            <KpiLineChart :chart-data="lineData" style="height: 90%" />
                            <img
                                class="watermark"
                                src="@/assets/images/misc/evolution_watermark.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import trend from "vuetrend";
import KpiLineChart from "@/components/Charts/KpiLineChart";
import MSelect from "@/components/Utils/MSelect.vue";
import KPISService from "@/services/kpis.service";
import UserService from "@/services/user.service";
import { format, parse } from "date-fns";

export default {
    name: "KPIS",
    components: {
        trend,
        KpiLineChart,
        MSelect,
    },
    data() {
        return {
            kpis: [],
            kpiSelected: null,
            currentTab: "results",
            lineData: {
                labels: ["Mar", "Apr", "May"],
                datasets: [
                    {
                        data: [0, 70, 100],
                        fill: false,
                        borderColor: "#3FC1D2",
                        backgroundColor: "#3FC1D2",
                        borderWidth: 4,
                        pointStyle: "rect",
                        pointRadius: 6,
                    },
                ],
            },
            selectedKpiResults: [],
            selectedCycle: null,
        };
    },
    computed: {
        ...mapGetters("user", ["authUser", "currentCycleId", "getCyclesList"]),
        cycles() {
            return this.getCyclesList.map(function (c) {
                return {
                    value: c.id,
                    displayName: c.name,
                };
            });
        },
        kpiLastUpdate() {
            return format(
                new Date(this.kpiSelected.last_updated_at),
                "dd-MM-yyyy 'às' HH:mm"
            );
        },
    },
    mounted() {
        this.selectedCycle = this.currentCycleId;
        this.fetchKPIS();
    },
    methods: {
        // @TODO(Nuno) do this properly getKpiGoal getKpiResult getKpiPerformance
        getKpiResult(kpi) {
            if (kpi.evolution.length > 0) {
                if (kpi.evolution[kpi.evolution.length - 1].result != null) {
                    return kpi.evolution[kpi.evolution.length - 1].result
                        .result;
                }
            }

            return "---";
        },
        getKpiPerformance(kpi) {
            if (kpi.evolution.length > 0) {
                if (kpi.evolution[kpi.evolution.length - 1].result != null) {
                    return kpi.evolution[kpi.evolution.length - 1].result
                        .performance;
                }
            }

            return "---";
        },
        getKpiGoal(kpi) {
            if (kpi.evolution.length > 0) {
                if (kpi.evolution[kpi.evolution.length - 1].result != null) {
                    return kpi.evolution[kpi.evolution.length - 1].result.goal;
                }
            }

            return "---";
        },
        getKpiDate(kpi) {
            if (kpi.evolution.length > 0) {
                if (kpi.evolution[kpi.evolution.length - 1].result != null) {
                    return kpi.evolution[kpi.evolution.length - 1].result.date;
                }
            }

            return "--/--/----";
        },
        selectKPI(kpi) {
            this.kpiSelected = kpi;
            // this.selectedKpiResults;
            this.fetchKPIDetails();
        },
        changeTab(tab) {
            this.currentTab = tab;
        },
        selectedKpi() {
            return this.kpis.find((kpi) => kpi.id === this.kpiSelected.id);
        },
        fetchKPIS() {
            KPISService.getUserTopKpis({ cycle_id: this.selectedCycle })
                .then((resp) => {
                    this.kpis = resp;
                    if (this.kpis.length > 0) {
                        this.selectKPI(this.kpis[this.kpis.length - 1]);
                    }
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        fetchKPIDetails() {
            KPISService.getUserTopKpiDetail({
                cycle_id: this.selectedCycle,
                kpi_id: this.kpiSelected.id,
            })
                .then((resp) => {
                    // Sort data in place to use for both results and chart
                    resp.user_stats.sort(function (a, b) {
                        return new Date(b.date) - new Date(a.date);
                    });
                    this.selectedKpiResults = resp.user_stats;

                    // For the chart, reverse order in place so it respects natural date progression
                    var chartData = resp.user_stats.slice().reverse();

                    this.lineData.datasets[0].data = chartData.map(
                        (item) => item.result
                    );
                    this.lineData.labels = chartData.map((item) =>
                        this.formatDate(item.date, "dd/MM")
                    );
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        updateCycle($event) {
            this.selectedCycle = $event.target.value;
            this.fetchKPIS();
        },
        formatDate(date, option = "dd/MM/yyyy") {
            return format(
                parse(date, "yyyy-MM-dd HH:mm:ss", new Date()),
                option
            );
        },
    },
};
</script>

<style scoped>
.kpis-page-container {
    padding: 1.6rem 11rem;
}

.header {
    /* width: 83.33333337%; */
    /* margin: 1.875rem auto 0; */
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.68rem;
}
.header p {
    font-weight: 400;
    font-size: 1.75rem;
    line-height: 2.9375rem;
    color: #ffffff;
    text-shadow: 0.25rem 0.1875rem 0rem #1e1e1e;
}
.header .counter {
    font-weight: 700;
}

.filter-container {
    display: flex;
    align-items: center;
    margin-right: -1rem;
}
.filter-container p {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.9375rem;
    text-shadow: none;
    margin-right: 0.625rem;
}

.kpis-column {
    background: #221b38;
}
.secondary-title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.9375rem;
    color: #ffffff;
}

.is-tab-button {
    background: transparent;
    border: none;
    border-radius: 0;
    color: #8f81b2;
    outline: none !important;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.9375rem;
}
.is-tab-button:focus,
.is-tab-button:active {
    outline: none !important;
    box-shadow: none;
}
.is-tab-button.is-active {
    color: #1eb28d;
    border-bottom: 0.1875rem solid #1eb28d;
}

.top-kpis-list {
    height: 100%;
    max-height: 38.75rem;
    overflow-y: auto;
}
.kpi-box {
    position: relative;
    background: #221b38;
    border-radius: 0;
    color: #ffffff;
}
.kpi-box.is-active {
    background: #362a52;
}

.progress-metric {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;

    width: 3rem;
    height: 3rem;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    background-image: url("/hex_green.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
}
.kpi-name {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
}
.kpi-box p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
}

.kpis-box {
    border-radius: 0;
    background: #f5f6fb;
    height: 100%;
    position: relative;
}

.table-container {
    overflow-y: auto;
    max-height: 37.8125rem;
}

.kpis-box .results-table {
    width: 100%;
    /* margin-top: 1.25rem; */
    /* border-collapse: collapse; */
    border-collapse: separate;
    border-spacing: 0 0.625rem;
}
.kpis-box .results-table th {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    letter-spacing: 0.01em;
    color: #403c56;
    padding: 0.375rem 1.125rem;
    text-transform: uppercase;

    position: sticky;
    top: 0;
    background: #f5f6fb;
    z-index: 1;
}
.kpis-box .results-table td {
    background: #ffffff;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    letter-spacing: 0.01em;
    color: #403c56;
    padding: 0.375rem 1.125rem;
}
.kpis-box .results-table td.result {
    color: #ffffff;
    background: #221b38;
    text-align: right;
}
.kpis-box .results-table td.result span {
    color: #3fc1d2;
    margin-left: 0.625rem;
}
.watermark {
    position: absolute;
    right: 0;
    bottom: 0.625rem;
    z-index: 1;
    opacity: 0.4;
    height: 25.6875rem;
    width: 17.0625rem;
}

.kpi-last-update {
    color: #8f81b2;
    font-weight: 400;
    font-size: 0.875rem;
    right: 1.625rem;
    position: absolute;
}
</style>
