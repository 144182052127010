<template>

  <div class="badge-component box box-badge has-text-centered" :class="{'not-owned': badge.user_has_badge == null}">
    <!-- <img src="../../assets/images/badges/badge_placeholder.svg" alt=""> -->    
    <!-- <img :src="[badge.image ? (backOfficeUrl + '/storage/badges/' + badge.image ) : '../../assets/images/badges/badge_placeholder.svg']" alt=""> -->
    <img class="badge-image" v-if="badge.image" :src="backOfficeUrl + '/storage/badges/' + badge.image" alt="">
    <img class="badge-image" v-else src="../../assets/images/badges/badge_placeholder.svg" alt="">
    <h3 class="badge-title">{{ badge.title }}</h3>
    <p class="badge-description">
      {{ badge.description }}
    </p>

    <h4 class="top">
      Top 5 com o mesmo  Badge:
    </h4>

    <div class="group-user-5-combo is-flex">
      <div v-for="other in getOtherUsers(badge).slice(0, 5)" :key="other.name" class="user-5-combo user-profile">
        <!-- TODO: TROCAR QUANDO SE RESOLVER O AVATAR -->
        <!-- <img v-if="other.profile_image" :src="backOfficeUrl + '/storage/users/' + other.profile_image" :title="other.name">
        <img v-else src="../../assets/images/badges/hero_placeholder.svg" :title="other.name"> -->
        <img src="../../assets/images/badges/hero_placeholder.svg" :title="other.name">
      </div>
    </div>

  </div>

</template>
<script>
import ImageChecker from '@/tools/ImageChecker';

export default {
  name: 'BadgeComponent',
  data() {
    return {
      backOfficeUrl: process.env.VUE_APP_BO_BASE_URL
    }
  },
  props: [
    'badge'
  ],
  mounted() {
    this.getOtherUsers(this.badge);
  },
  methods: {
    getOtherUsers(badge) {

      // badge.outros = 'Alexandra Silveira|2_thu_eLHJuvIFtnI8uWJb4vvF6QkOtTBdYBAXlbDnaDvZ.png,Ana Cristina Vicente Fragoso Simoes|6_thu_miLjjzaDIxtXc8MJv10wQOkZp8jBGQoPGk207WhZ.png,Ana Rita Rodrigues Bruno|,Andrea Santos De Souza|,Andreia Sofia Da Silva Teixeira E Costa|24_thu_QQCosy2RcWplDcs97FYExIAgTQy5v8k6eg7vIoYR.png,Carina Manuela Rocha Fonseca|32_thu_ZknYjS5UDHR6gj6Eu5VFpxjQ9wrPlLMiQy3uXgG6.png,Carina Sofia Martins Dos Santos|,Carla Alexandra Barros Leitao|35_thu_xm1UV2XKHgfceG41m6vZA31IdfoLH89F2xxASoDO.png,Carla Margarida Santos Cardoso|,Celina Alexandra Teixeira Barcia|,Cristiana Daniela Machado Coelho|46_thu_L4gwUnTersz2oqVlYJzjhX5TOTG6JFV7y8WvGyDv.png';
      if (badge.outros == null) {
        return [];
      }

      let others = badge.outros.split(",").map((item) => {
        item = item.split("|");

        let img = "../../assets/images/badges/hero_placeholder.svg";
        // ImageChecker.checkIfImageExists((img_loaded) => {
        //   console.log("img_loaded:")
        //   console.log(img_loaded)
        //   if (img_loaded === true) {
        //     img = require("/storage/" + item[1]);
        //   }
        // }, "/storage/" + item[1], true);

        return {
          name: item[0],
          profile_image: item[1]
        }
      });
      
      return others;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixins.scss";

.badge-component {
  height: 100%;
  border-radius: 0;
  background-color: #221B38;
  padding: 1.25rem 1.25rem 2.5rem;
  box-shadow: 0.25rem 0.1875rem #8A69CA;
}

.group-user-5-combo {
  margin-top: 0.625rem;
  justify-content: center;
}
.group-user-5-combo div:not(:first-child) {
  margin-left: -0.5rem;
}

.badge-image {
  height: 5.625rem;
  filter: brightness(0) saturate(100%) invert(81%) sepia(79%) saturate(419%) hue-rotate(348deg) brightness(151%) contrast(98%);
}

.badge-title {
  color: #8F81B2;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 2.5625rem;
  letter-spacing: 0.01em;
  margin-bottom: 0.8125rem;
}

.badge-description {
  color: #fff;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.6875rem;
  letter-spacing: 0.01em;
  margin-bottom: 2.5rem;
  height: 6.875rem;

  @include screen-min($screen-lg) {
    font-size: 1.125rem;
  }
}

.top {
  color: #8F81B2;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  letter-spacing: 0.01em;
}

.not-owned {
  filter: grayscale(100%);
  box-shadow: 0.25rem 0.1875rem #4e3f6a;
}

.user-profile {
  width: 2.5rem;
  height: 2.5rem;
}
</style>