<template>
  <button @click.prevent="goToKudosModal()" class="circle">
    <img src="../../../assets/images/Dashboard/news-icon.svg" alt="comment icon" class="icon" />
  </button>
</template>

<script>
export default {
  props: {
    hero: { type: Object, required: true },
  },
  methods: {
    goToKudosModal() {
      this.$emit('kudos-button-clicked', this.hero);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/_variables.scss";

.circle {
  background-color: $main-color;
  filter: drop-shadow(0.125rem 0.125rem 0rem black);
  padding: 0.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  border: 0;
}

.icon {
  width: 100%;
  height: 100%;
}
</style>
