<template>
    <div class="wrapper">
        <img
            src="../../../assets/images/misc/ranking-dialog.svg"
            class="ranking-dialog"
            alt=""
        />
        <div class="flex-container">
            <div
                class="container"
                v-for="(ranking, index) in rankings"
                :key="index"
                @click="goToRankings()"
            >
                <RankingItemComponent
                    :username="ranking.username"
                    :picture="ranking.avatar"
                    :index="ranking.position"
                    :active="isCurrentPlayer(ranking)"
                />
                <span class="experience">{{ ranking.performance }} %</span>
            </div>
        </div>
    </div>
</template>

<script>
import RankingItemComponent from "@/components/Dashboard/Rankings/RankingItem.vue";
import RankingsService from "@/services/rankings.service";
import StringManipulator from "@/tools/StringManipulator";
import { mapGetters } from "vuex";

const CENTER_GROUP_TYPE_ID = 2;
const SERVICE_GROUP_TYPE_ID = 3;
// TODO: no futuro deveria vir do backend
const SUPERVISOR_JOBS = [
    2, 3, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
];

export default {
    name: "RankingsComponent",
    components: {
        RankingItemComponent,
    },
    data() {
        return {
            rankings: [],
            backOfficeUrl: process.env.VUE_APP_BO_BASE_URL,
        };
    },
    created() {
        this.getRankings();
    },
    computed: {
        ...mapGetters("user", ["authUser", "userProfile"]),
    },
    methods: {
        goToRankings() {
            this.$router.push({ name: "Rankings" }).catch(() => {});
        },
        getRankings() {
            RankingsService.getRankingv2({
                jobs: this.userProfile.job.toLowerCase().includes("supervisor")
                    ? SUPERVISOR_JOBS
                    : null,
                include_group_leaders: this.userProfile.job
                    .toLowerCase()
                    .includes("supervisor"),
                include_top_kpis: true,
                groups: [
                    this.userProfile.groups.filter(
                        (g) => g.type_id === SERVICE_GROUP_TYPE_ID
                    )[0].id,
                    this.userProfile.groups.filter(
                        (g) => g.type_id === CENTER_GROUP_TYPE_ID
                    )[0].id,
                ]
            })
                .then((resp) => {
                    let position = 1;
                    this.rankings = resp.leaderboard
                        .sort((a, b) => {
                            // Sort by performance and name
                            const aPerformance = parseFloat(a.performance);
                            const bPerformance = parseFloat(b.performance);
                            if (aPerformance === bPerformance)
                                return a.name > b.name ? 1 : -1;
                            return aPerformance > bPerformance ? -1 : 1;
                        })
                        .map((user, idx) => {
                            // If there is a tie the position on the leaderboard should repeat 1º, 1º, 2º, 3º
                            if (
                                idx > 0 &&
                                resp.leaderboard[position - 1].performance !==
                                    user.performance
                            )
                                position++;

                            
                            return {
                                id: user.id,
                                username: user.display_name,
                                avatar: user.picture,
                                performance: user.performance ?? 0,
                                position: position,
                            };
                        });

                    // Find the index of the user in the leaderboard
                    const userIndex = this.rankings.findIndex(
                        (user) => user.id === this.userProfile.id
                    );

                    // Define the number of users to include above and below the user
                    let numUsersAbove = 1; // Adjust as needed
                    let numUsersBelow = 2; // Adjust as needed

                    if (userIndex === 0) {
                        numUsersAbove = 0;
                        numUsersBelow = 3;
                    } else if (userIndex === this.rankings.length - 1) {
                        numUsersAbove = 3;
                        numUsersBelow = 0;
                    }

                    // Calculate the start and end indices for slicing the array
                    let startIndex = Math.max(userIndex - numUsersAbove, 0);
                    let endIndex = Math.min(
                        userIndex + numUsersBelow + 1,
                        this.rankings.length
                    );

                    // Extract the subset of users centered around userA
                    this.rankings = this.rankings.slice(startIndex, endIndex);
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        isCurrentPlayer(ranking) {
            return ranking.id === this.authUser.id;
        },
    },
};
</script>

<style scoped>
.wrapper {
    padding-top: 1%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5%;
    background: linear-gradient(
        180deg,
        #221b38 65.86%,
        rgba(34, 27, 56, 0) 112.94%
    );
}

.ranking-dialog {
    width: 55%;
    position: relative;
    left: -15%;
    bottom: -5%;
}

.flex-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 4%;
}

.container {
    width: 100%;
    max-height: 20%;
}

.experience {
    font-weight: 700;
    font-size: 1.15rem;
    line-height: 27px;
    letter-spacing: 0.01rem;
    color: #ffffff;
    display: flex;
    justify-content: center;
    padding-top: 2%;
}
</style>
