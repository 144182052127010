<template>
  <svg width="146" height="106" viewBox="0 0 146 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="14" y="3" width="118" height="72" rx="6" :fill="color" :stroke="color" stroke-width="6" />
    <path
      d="M3 92C3 88.6863 5.68629 86 9 86H52.5C54.7091 86 56.5 87.7909 56.5 90V90C56.5 92.2091 58.2909 94 60.5 94H86C88.2091 94 90 92.2091 90 90V90C90 87.7909 91.7909 86 94 86H137C140.314 86 143 88.6863 143 92V97C143 100.314 140.314 103 137 103H9C5.6863 103 3 100.314 3 97V92Z"
      :stroke="color"
      stroke-width="6"
    />
  </svg>
</template>

<script>
export default {
  props: { color: { type: String, default: "#8F81B2" } },
};
</script>
