const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;

export default class RankingsService {
    /**
     * Get user ranking. This is the ranking by performance of the default KPI, for the current cycle.
     * @param {Number} limit
    */
    static getUserRanking(limit = 0) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/ranking/performance`, { limit })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Get user dashboard ranking. This is the ranking by performance of the default KPI, for the current cycle, with a max of $limit users.
     * @param {Number} limit
    */
    static getUserDashboardRanking(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/ranking/performance/dashboard`, { ...params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Ranking of the users, ordered by general performance and also returns a list of the top kpis performance per user.
     * @param {Number} limit
     * @param {Number} cycle_id
    */
    static getUserTopKpisRanking(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/ranking/performance/top-kpis`, { ...params, include_group_leaders: false })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Ranking of the users, ordered by general performance and also returns a list of the top kpis performance per user.
     * @param {Number} limit
     * @param {Number} cycle_id
    */
    static getRankingv2(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/ranking/v2`, { ...params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static getUserTopGroup(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/groups/groupByLevel`, { ...params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Ranking of the users, ordered by general performance and also returns a list of the top kpis performance per user.
     * @param {Number} limit
     * @param {Number} cycle_id
    */
    static getGroupsTopKpisRanking(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/groups/ranking/performance/top-kpis`, { ...params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * 
     * @param {Number} cycle_id
    */
    static getGlobalRankingPerformance(cycle_id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/ranking/performance/global`, { cycle_id })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }
}