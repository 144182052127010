export default class DateHelper {
    static getDateFromDateTime(date, date_format = "[DD]/[MM]/[YYYY]") {
        if (!date) {
            return "";
        }

        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('/');

        // return (new Date(date)).format(date_format);
    }

    static getDateDiff(start_date, end_date) {
        if (start_date == null || end_date == null) {
            return "0d 0h 0m"
        }

        var d1 = new Date(start_date);
        var d2 = new Date(end_date);

        var seconds = Math.floor((d2 - (d1))/1000);
        var minutes = Math.floor(seconds/60);
        var hours = Math.floor(minutes/60);
        var days = Math.floor(hours/24);

        hours = hours-(days*24);
        minutes = minutes-(days*24*60)-(hours*60);
        seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

        return days + "d " + hours + "h " + minutes + "m";
    }
}