<template>
  <div class="store-screen">
    <section class="store-screen__header">
      <p>
        Total: <span class="counter">{{ new String(acquiredProducts.length).padStart(3, "0") }}</span>
      </p>
      <div class="store-screen__header__tabs">
        <button
          class="button is-tab-button"
          :class="[{ 'is-active': currentTab === 'products' }]"
          @click="changeTab('products')"
        >
          Produtos
        </button>
        <button
          class="button is-tab-button"
          :class="[{ 'is-active': currentTab === 'purchases' }]"
          @click="changeTab('purchases')"
        >
          Encomendas
        </button>
      </div>
    </section>

    <section class="store-screen__content">
      <span v-if="loading">
          <div
              class="loader"
              style="width: 2em; height: 2em; margin: 0 auto"
          >
              <div></div>
          </div>
      </span>
      <template v-if="!loading && currentTab === 'products' && products.length > 0">
        <div v-for="product in products" :key="product.id" class="store-screen__content__item">
          <product-component :product="product" @purchase="handleProductPurchase" />
        </div>
      </template>

      <template v-if="!loading && currentTab === 'purchases' && acquiredProducts.length > 0">
        <div v-for="product in acquiredProducts" :key="product.store_request_id" class="store-screen__content__item">
          <product-component :product="product" :acquired="true" />
        </div>
      </template>
    </section>

    <product-detail-modal
      v-if="selectedProduct"
      @close="closeProductModal"
      @confirmPurchase="handlePurchaseConfirmation"
      :show="showProductDetail"
      :product="selectedProduct"
    />
  </div>
</template>
<script>
import ProductComponent from "@/components/Store/Product";
import ProductDetailModal from "@/components/Store/ProductDetailModal";
import { mapGetters } from "vuex";
import StoreService from "@/services/store.service";

export default {
  name: "StoreScreen",
  components: {
    ProductComponent,
    ProductDetailModal,
  },
  props: [],
  data() {
    return {
      products: [],
      acquiredProducts: [],
      currentTab: "products",
      selectedProduct: null,
      showProductDetail: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("user", ["authUser"]),
  },
  created() {
    this.fetchProducts();
    this.fetchAcquiredProducts();
  },
  mounted() {},
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
    },
    fetchProducts() {
      this.loading = true;
      StoreService.getProductsList({ cycle_id: 8 })
        .then((resp) => {
          this.products = resp;
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
        .finally(() => (this.loading = false));
    },
    fetchAcquiredProducts() {
      this.loading = true;
      StoreService.getAcquiredProductsList({ cycle_id: 8 })
        .then((resp) => {
          this.acquiredProducts = resp;
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
        .finally(() => (this.loading = false));
    },
    openProductModal() {
      this.showProductDetail = true;
    },
    closeProductModal() {
      this.showProductDetail = false;
      this.selectedProduct = null;
    },
    handleProductPurchase(product) {
      this.selectedProduct = product;
      if (this.selectedProduct != null) {
        this.openProductModal();
      } else {
        this.$noty.error("Error opening product.");
      }
    },
    handlePurchaseConfirmation() {
      if (this.selectedProduct != null) {
        StoreService.purchaseProduct(this.selectedProduct.id)
          .then((resp) => {
            this.products = resp.products;

            // @TODO(Nuno) use transformer
            // this.acquiredProducts = resp.purchased_products;
            this.fetchAcquiredProducts();

            this.$store.dispatch("user/updateUserPoints", resp.points_after_purchase);

            this.selectedProduct = null;

            this.$noty.success("Produto comprado com sucesso!");
          })
          .catch((err) => {
            this.$noty.error(err.message);
          })
          .finally(() => this.closeProductModal());
      } else {
        this.$noty.error("Erro ao comprar o produto.");
        this.closeProductModal();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.store-screen {
  padding: 1.6875rem 11.5rem;

  &__header {
    display: flex;
    margin-bottom: 2.5rem;
    align-items: center;

    & p {
      font-weight: 400;
      font-size: 1.75rem;
      color: #ffffff;
      text-shadow: 0.25rem 0.1875rem 0rem #1e1e1e;
      flex-grow: 1;

      & .counter {
        font-weight: 700;
      }
    }
    &__tabs {
      flex-grow: 1;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;

    &__item {
      width: 20%;
      flex: none;
      padding: 0.75rem;
    }
  }
}

.is-tab-button {
  background: transparent;
  border: none;
  border-radius: 0;
  color: white;
  outline: none !important;
  font-weight: 700;
  font-size: 1.5rem;
}
.is-tab-button:focus {
  outline: none !important;
}
.is-tab-button.is-active {
  color: #1eb28d;
  border-bottom: 0.1875rem solid #1eb28d;
}
</style>
