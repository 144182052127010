const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;

import DateHelper from "@/tools/DateHelper";

import KudosRecord from "@/entities/KudosRecord";
const model = KudosRecord;

const parseKudos = (apiKudos) => {
    const parsedKudos = {
        kudos_id: apiKudos.kudos_id,
        title: apiKudos.title,
        image: apiKudos.image,
        description: apiKudos.description,
        date: DateHelper.getDateFromDateTime(apiKudos.created_at),
        giver_id: apiKudos.giver_id,
        receiver_id: apiKudos.receiver_id,
        total: apiKudos.total ?? null,
        givers: apiKudos.givers ? JSON.parse(apiKudos.givers).map((user) => {
            return {
                name: user.name,
                profile_image: user.image
            }
        }) : [],
        receivers: apiKudos.receivers ? JSON.parse(apiKudos.receivers).map((user) => {
            return {
                name: user.name,
                profile_image: user.image
            }
        }) : [],
    };
    return new model(parsedKudos);
};

export default class KudosService {
    /**
     * Get list of existing kudos.
    */
    static getList() {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/kudos/list`)
                .then((response) => {
                    let kudos = response.data.kudos;
                    resolve(kudos);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * List of kudos given and received by user.
    */
    static getUserKudos() {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/kudos`)
                .then((response) => {

                    let kudos = {
                        given_kudos: response.data.given_kudos.map(parseKudos),
                        received_kudos: response.data.received_kudos.map(parseKudos)
                    }

                    resolve(kudos);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Give kudos to target user.
     * @param {integer} kudos_id - Id of the kudos to be given.
     * @param {integer} target_user_id - Id of the user that will receive the kudos.
    */
    static giveKudos(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/kudos/give`, { ...params })
                .then((response) => {
                    let kpi = response.data;
                    resolve(kpi);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }
}