<template>
  <div class="herostats-container">
    <p class="hero-title">{{ heroName || "Heroimetro" }}</p>
    <p class="hero-percentage">{{ percentage }}%</p>
    <div class="badges">
      <img
        v-for="(badge, i) in badges"
        :key="i"
        :src="backOfficeUrl + '/storage/badges/' + badge.image"
        :alt="badge.title"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: { type: Number, required: true },
    heroName: { type: String, default: null },
    badges: { type: Array, default: [] },
  },
  data() {
    return {
      backOfficeUrl: process.env.VUE_APP_BO_BASE_URL
    }
  },
};
</script>

<style scoped lang="scss">
.herostats-container {
  padding-left: 0.8rem;
  position: relative;
}

.hero-title,
.hero-percentage {
  filter: drop-shadow(0.125rem 0.125rem 0rem #000);
  letter-spacing: 0.01rem;
}

.hero-title {
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.1rem;
}

.hero-percentage {
  color: #ffec55;
  font-size: 1.5rem;
  font-weight: 700;
  top: -0.65rem;
  position: relative;
}

.badges {
  display: flex;
  flex-direction: column;
  margin-top: 0.6rem;
  gap: 0.6rem;
}

.badges > img {
  width: 3rem;
}
</style>