<template>
    <svg width="100%" height="100%" viewBox="0 0 47 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.85714 2.85714V25.2381H43.5714V2.85714H2.85714ZM1.42857 0C0.639593 0 0 0.639594 0 1.42857V26.6667C0 27.4556 0.639593 28.0952 1.42857 28.0952H45C45.789 28.0952 46.4286 27.4556 46.4286 26.6667V1.42857C46.4286 0.639593 45.789 0 45 0H1.42857Z" :fill="color"/>
        <path d="M3.89697 33.8096C3.89697 33.1521 4.42997 32.6191 5.08745 32.6191H40.6533C41.3108 32.6191 41.8438 33.1521 41.8438 33.8096C41.8438 34.4671 41.3108 35.0001 40.6533 35.0001H5.08745C4.42997 35.0001 3.89697 34.4671 3.89697 33.8096Z" :fill="color"/>
        <path d="M17.3433 8.53886C17.3433 7.597 18.3852 7.02814 19.1775 7.53746L27.6802 13.0035C28.4092 13.4721 28.4092 14.5377 27.6802 15.0063L19.1775 20.4724C18.3852 20.9817 17.3433 20.4128 17.3433 19.471V8.53886Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    props: { 
        color: { 
            type: String,
            default: "#8F81B2" 
        } 
    },
};
</script>
<style scoped lang="scss">
</style>