<template>
  <div class="progress-bar">
    <div class="progress-inner" :style="{width: progress + '%'}"></div>
  </div>
</template>

<script>
export default {
  props: { progress: { type: Number, default: 0 } },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
// color: #1eb28d;

.progress-bar {
  height: 1rem;
  display: block;
  height: 1rem;
  overflow: hidden;
  padding: 0;
  width: 100%;
  background-color: #ededed;

  .progress-inner {
    height: 100%;
    background-color: $main-color;
  }
}

.mission-status {
  display: flex;
  flex-direction: column;

  &__progress {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    color: $main-color;
    margin-top: -0.625rem;

    & > progress {
      height: 0.625rem;
      margin-bottom: 0;
      color: $main-color;
      background-color: #ddd9e7;

      &::-moz-progress-bar,
      &::-webkit-progress-value {
        background: $main-color;
      }
    }

    figure {
      position: relative;
      display: flex;

      & > .incomplete {
        color: #ddd9e7;
      }

      & > figcaption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #221b38;
        font-weight: 400;
        font-size: 0.75rem;
      }
    }
  }
}
</style>
