<template>
  <div class="main-select" v-show="isVisible">
    <select @change="$emit('updated-select', $event)" :disabled="isDisabled">
      <option v-for="o in options" :key="o.value" :value="o.value != null ? o.value : o" :selected="o.value == defaultOption">
        {{ o.displayName != null ? o.displayName : o }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    options: { type: Array, required: true },
    defaultOption: { type: Object | String | Number, default: null },
    isDisabled: { type: Boolean, default: false},
    isVisible: { type: Boolean, default: true}
  }
}
</script>

<style scoped lang="scss">
.main-select select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-shadow: none;
  color: #fff;
  background-color: #8A69CA;
  cursor: pointer;

  font-family: 'Oswald';
  font-weight: 700;
  font-size: 1.5rem;
}

.main-select select::-ms-expand {
  display: none;
}

.main-select {
  display: grid;
  max-width: 18rem;
  width: 18rem;
  height: 3.5rem;
  cursor: pointer;
  padding: 0.25em 0.5em;

  background: #8A69CA;
  box-shadow: 0.125rem 0.125rem 0rem #000000;

  // https://moderncss.dev/custom-select-styles-with-pure-css/
  grid-template-areas: "select";
  align-items: center;
}

select,
.main-select::after {
  grid-area: select;
}

/* Arrow */
.main-select::after {
  content: "";
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  background-color: #fff;

  width: 0.8rem;
  height: 0.8rem;
  justify-self: end;
}
</style>