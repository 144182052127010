<template>
  <div v-if="!hideButton" class="scroll-container" @click="scrollToTop">
    <img class="disable-select" style="pointer-events: none;" src="../../assets/images/scroll-top.svg" alt="scroll to top">
  </div>
</template>

<script>
export default {
  data() {
    return {
      hideButton: true,
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onScroll() {
      if (window.scrollY <= 50) this.hideButton = true;
      else this.hideButton = false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

.scroll-container {
  background-color: $main-color;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  cursor: pointer;
}

.scroll-container img {
  width: 100%;
  height: 100%;
  // background-color: red;
}
</style>