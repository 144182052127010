<template>
  <base-modal :show="show" maxWidth="31.25rem" @close="handleClose">
    <div class="body">
      <img
        class="kudos-img"
        src="@/assets/images/kudos/kudos_placeholder.svg"
        alt=""
      />
      <h3 class="kudos-title">{{ kudos.title }}</h3>
      <p class="kudos-date">{{ kudos.date }}</p>
      <!-- <p class="kudos-description">{{ kudos.description }}</p> -->
      <p class="kudos-description">{{ kudos.description }}</p>

      <div class="stats-wrapper">
        <div class="stats">
          <p class="stats__number">{{ kudos.givers.length }}</p>
          <p class="stats__type">Recebidos</p>
          <div class="stats__images">
            <template v-for="(r, i) in kudos.givers">
              <div v-if="i < 4" :key="i" :title="r.name">
                <img
                  :src="backOfficeUrl + '/storage/users/' + r.profile_image"
                  :alt="r.name"
                  onerror="this.src='/images/user-image.svg'"
                />
              </div>
            </template>
          </div>
        </div>
        <div class="stats">
          <p class="stats__number">{{ kudos.receivers.length }}</p>
          <p class="stats__type">Atribuídos</p>
          <div class="stats__images">
            <template v-for="(g, i) in kudos.receivers">
              <div :key="i" v-if="i < 4" :title="g.name">
                <img
                    :src="backOfficeUrl + '/storage/users/' + g.profile_image"
                    :alt="g.name"
                    onerror="this.src='/images/user-image.svg'"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/BaseModal";

export default {
  name: "KudosDetailModal",
  components: { BaseModal },
  data() {
    return {
      backOfficeUrl: process.env.VUE_APP_BO_BASE_URL
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    kudos: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.body {
  padding: 0.5rem 1.25rem;
  text-align: center;
  font-size: 1.25rem;
}

.kudos-img {
  margin-bottom: 1.25rem;
}

.kudos-title {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9375rem;
  letter-spacing: 0.01em;
  color: #403c56;
}
.kudos-date {
  font-weight: 700;
  color: #8f81b2;
  margin-bottom: 1.25rem;
}
.kudos-description {
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: 0.003em;
  color: #403c56;
  margin-bottom: 1.25rem;
}

.stats-wrapper {
  display: flex;
  gap: 4.8125rem;
  justify-content: center;

  & .stats {
    &__number {
      font-weight: 700;
      font-size: 1.75rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: $main-color;
    }
    &__type {
      font-weight: 700;
      font-size: 1.125rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      margin-bottom: 0.625rem;
    }
    &__images {
      display: flex;
      gap: 2px;

      & > div {
        width: 2.5rem;
        height: 2.5rem;
        flex-shrink: 0;
        
        & > img {
          width: 100%;
          height: 100%;
          border: 0.17rem solid black;
          border-radius: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
    }
  }
}
</style>