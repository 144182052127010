<template>
  <div ref="pdfContainer"></div>
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
    name: { type: String, required: true }
  },
  data() {
    return {}
  },
  mounted() {
    const previewConfig = {
      defaultViewMode: 'FIT_WIDTH',
      showAnnotationTools: false,
      showDownloadPDF: false,
      showPrintPDF: false,
      showLeftHandPanel: true,
      showAnnotationTools: false,
      showThumbnails: false,
      showBookmarks: false,
      showFullScreen: false,
      // embedMode: "IN_LINE"
    }

    let viewer = document.createElement("div");
    viewer.id = "pdf-viewer";
    this.$refs.pdfContainer.innerHTML = "";
    this.$refs.pdfContainer.appendChild(viewer);

    let adobeDCView = new AdobeDC.View({
      clientId: process.env.VUE_APP_ADOBE_PDF_CLIENT_KEY,
      divId: "pdf-viewer",
      hasReadOnlyAccess: true,
    });

    this.previewFilePromise = adobeDCView.previewFile({
      content: { location: { url: this.url } },
      metaData: { fileName: this.name },
    }, previewConfig)
  }
}
</script>
<style scoped lang="scss">

</style>