<template>
  <div style="display: flex;">
    <content-step
        :first="first"
        :last="last"
        :active="active"
        :height="stepHeight"
      />
    <div class="tab">
      <input type="checkbox" :id="item.id" :class="{ 'open': checked }" v-model="checked" @click.prevent="openFAQ(pos)"/>
      <label class="tab-label" :for="item.id">{{ item.title }}</label>
      <div class="tab-content" ref="description">
        {{ item.description }}
      </div>
    </div>

  </div>
</template>

<script>
import ContentIcon from "../Utils/Icons/ContentIcon.vue";
import VideoIcon from "../Utils/Icons/VideoIcon.vue";
import ContentStep from "./FAQStep.vue";
export default {
  name: "FAQItem",
  components: { ContentIcon, VideoIcon, ContentStep },
  props: {
    active: { type: Boolean, default: false },
    item: Object,
    pos: Number,
    checked: { type: Boolean, default: false },
    first: { type: Boolean, default: false },
    last: { type: Boolean, default: false },
    penultimate: { type: Boolean, default: false },
  },
  data() {
    return {
      backOfficeUrl: process.env.VUE_APP_BO_BASE_URL,
      stepHeight: "2em",
    };
  },
  methods: {
    openFAQ(pos) {
      this.$emit('toggle-faq', pos);
    }
  },
  watch: {
    checked: function() {
      if (this.checked) {
        if (this.penultimate)
          this.stepHeight = "7em"
        else
          this.stepHeight = "9.6em"
      }
      else {
        this.stepHeight = "2em"
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/_variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.18rem;
  cursor: pointer;
}

.line {
  background-color: white;
  padding: 1.7rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;

  &__details {
    display: flex;
    gap: 3.62rem;
    align-items: center;

    &__type-image {
      width: 2.8rem;
      height: 2.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & > svg {
        flex-shrink: 0;
        width: 2rem;
      }

      & > span {
        font-weight: 700;
        font-size: 0.875rem;
        text-transform: uppercase;
        color: #8f81b2;
      }

      & > span.active {
        color: $main-color;
      }
    }

    &__info {
      display: flex;
      justify-content: center;
      gap: 1.125rem;

      & > img {
        width: 6.8rem;
        height: 3.75rem;
        background-color: #0e1a2e;
      }

      & > span {
        font-size: 1.25rem;
        font-weight: 700;
      }
    }
  }

  &__locked {
    display: flex;
    align-items: center;
    gap: 1rem;
    min-width: 11rem;

    & > img {
      width: 2.3rem;
      height: 2.3rem;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 0.875rem;

      &__status {
        font-weight: 700;
        color: black;
        text-transform: uppercase;
      }

      &__date {
        font-weight: 400;
        color: #403c56;
      }
    }
  }
}
.description {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 30px;
  color: #403c56;
  // padding: 15px 50px;
  padding: 1.2rem 4rem;
}


.tab {
  width: 100%;
  color:#403C56;
  overflow: hidden;
  margin-left: 2rem;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: white;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 30px;
    cursor: pointer;
    /* Icon */
    // &:hover {
    //   background: darken(white, 10%);
    // }
    &::after {
      content: "\276F";
      width: 1em;
      height: 1.5em;
      text-align: center;
      font-size: 1rem;
      transition: all .35s;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: #403C56;
    transition: all .35s;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 30px;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: white;
    cursor: pointer;
    &:hover {
      background: darken(white, 10%);
    }
  }
}
input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
// :checked
input:checked {
  + .tab-label {
    background: darken(white, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }
}

input.open {
  + .tab-label {
    background: darken(white, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }
}

</style>