<template>
    <svg width="100%" height="100%" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.6075 23.7994C33.084 23.7994 30.8152 23.1035 28.8799 22.0576C26.6646 22.4282 24.161 23.3588 21.8593 24.1206C16.7205 32.9735 13.7063 28.9464 14.044 27.3241C14.4516 25.2694 17.2269 23.6388 19.2775 22.5806C20.6199 20.0976 21.8346 17.5488 22.9134 14.9423C22.1846 11.9982 21.744 9.70056 22.1846 8.04527C22.6787 6.22939 26.1252 5.95762 26.5575 8.65056C27.0393 10.2317 26.401 12.7476 26.0057 14.3988C26.6687 16.3794 28.0069 18.0594 29.7857 19.1506C31.5728 18.9241 35.1428 18.5988 36.3987 19.8217C37.4899 20.9006 37.2304 23.7953 34.6116 23.7953L34.6075 23.7994ZM19.191 24.2194C18.5857 24.7712 16.5969 26.4347 16.0699 27.81C16.0987 27.88 17.2434 27.3282 19.191 24.2194ZM27.5704 20.2953C26.294 19.3153 25.2399 18.0759 24.4822 16.6553C23.7822 18.5906 22.934 20.4682 21.9457 22.2717C23.5722 21.6417 25.4334 20.74 27.5663 20.2953H27.5704ZM24.6304 12.4306C25.1328 11.5412 25.1822 8.24292 24.4493 8.24292C24.194 8.2388 24.1857 11.0059 24.6304 12.4306ZM31.6263 20.9623C34.9122 22.3664 35.4681 21.7488 35.4681 21.7488C35.8222 21.5182 35.2293 20.6864 31.6263 20.9623Z" :fill="color"/>
        <path d="M42.75 12.9227V34.7168H10.25V2.2168H31.4224V10.6727C31.4224 11.9153 32.4297 12.9227 33.6724 12.9227H42.75Z" :stroke="color" stroke-width="2.5" stroke-miterlimit="10"/>
        <path d="M31.1323 1.99609L42.9706 13.3196" :stroke="color" stroke-width="2.5"/>
        <path d="M9.75 33.4668V34.7168H11H33.75V38.7168H1.25V5.2168H9.75V33.4668Z" :stroke="color" stroke-width="2.5" stroke-miterlimit="10"/>
    </svg>
</template>

<script>
export default {
    props: { 
        color: { 
            type: String, 
            default: "#8F81B2",
        } 
    },
};
</script>

<style scoped lang="scss">
</style>
