<template>
  <div class="countdown-container">
    <img src="../../assets/images/clock.svg" alt="clock" class="icon">
    <span class="time">{{ time }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: "9d 23h 2m",
    }
  },
}
</script>

<style scoped>
.countdown-container {
  background-color: #f9ea03;
  color: black;
  font-size: 0.85em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4em;
  padding: 0.1em 0.5em 0.1em 0.3em;
  border-radius: 100px;
  width: max-content;
}

.icon {
  width: 1.2em;
  height: 1.2em;
}
</style>