<template>
    <div class="wrapper">
        <div class="player-container" :class="{ active: active }">
            <img
                v-if="picture"
                class="player-image"
                :src="`${backOfficeUrl}/storage/users/${picture}`"
                :alt="`User Profile - ${username}`"
                onerror="this.src='/images/user-image.svg'"
            />
            <img
                v-else
                class="player-image"
                src="@/assets/images/misc/user-image.svg"
                :alt="`User Profile - ${username}`"
            />
        </div>
        <div class="badge">
            <span class="badge-rank">{{ index }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "RankingItemComponent",
    props: ["index", "active", "username", "picture"],
    data() {
        return {
            backOfficeUrl: process.env.VUE_APP_BO_BASE_URL,
        };
    },
};
</script>

<style scoped>
.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: linear-gradient(
        180deg,
        #221b38 65.86%,
        rgba(34, 27, 56, 0) 112.94%
    );
    gap: 0.3rem;
    cursor: pointer;
}

.player-container {
    background: white;
    height: 40%;
    width: 40%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.player-container.active {
    border: 5px solid #e3b50f;
}

.player-image {
    background: white;
    /* width: 100%; */
    /* height: 100%; */
    width: 100px;
    height: 80px;
    max-width: 100px;
    border-radius: 50%;
    object-fit: contain;
    filter: drop-shadow(0.1875rem 0rem 0rem black);
}

.player-container.active .player-image {
    filter: none;
}

.badge {
    background-image: url("../../../assets/images/badge-number.svg");
    position: absolute;
    height: 40%;
    width: 40%;

    bottom: 0;
    right: 18%;

    background-repeat: no-repeat;
    background-size: contain, cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    filter: drop-shadow(0.125rem 0.125rem 0rem black);
}

.badge-rank {
    font-weight: 600;
    letter-spacing: 0.01rem;
    text-transform: uppercase;
    color: white;
    font-size: 0.8rem;
}
</style>
