<template>
    <section class="conteudo">
        <div class="conteudo__header">
            <p>Perguntas Frequentes</p>
        </div>
        <div class="conteudo__main">
            <FAQItem
                v-for="(faq, i) in faqs"
                :key="i"
                :pos="i"
                :item="faq"
                :active="i == checkedIndex"
                :checked="i == checkedIndex"
                :first="i == 0 && faqs.length > 1"
                :last="i == faqs.length - 1"
                :penultimate="i == faqs.length - 2"
                @toggle-faq="toggleFAQ($event)"
            />
        </div>
    </section>
</template>
<script>
import ContentItem from "../../components/Content/ContentItem.vue";
import FAQItem from "../../components/FAQ/FAQItem.vue";
// import ContentStep from "../../components/Content/ContentStep.vue";
import MSelect from "../../components/Utils/MSelect.vue";

import MissionsService from "@/services/missions.service";
import GeneralResourcesService from "@/services/general_resources.service";

export default {
    components: { MSelect, ContentItem, FAQItem },
    name: "FAQ",
    data() {
        return {
            total: 0,
            faqs: [],
            checkedIndex: null,
        };
    },
    created() {
        this.getFAQsList();
        // this.getContentsList();
    },
    methods: {
        getFAQsList() {
            GeneralResourcesService.getFAQs()
                .then((resp) => {
                    this.faqs = resp;
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        toggleFAQ(e) {
            this.checkedIndex = e == this.checkedIndex ? null : e;
        },
        openContent(content_id) {
            this.$router
                .push({
                    name: "Content Details",
                    params: { content_id: content_id },
                    timestamp: new Date().getTime(), // Force reload
                })
                .catch((err) => {
                    // Ignore the vuex err regarding  navigating to the page they are already on.
                    if (
                        err.name !== "NavigationDuplicated" &&
                        !err.message.includes(
                            "Avoided redundant navigation to current location"
                        )
                    ) {
                        // But print any other errors to the console
                        logError(err);
                    }
                });
        },
    },
};
</script>
<style scoped lang="scss">
.conteudo {
    padding: 1.6rem 11rem;

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.68rem;

        & > p {
            font-weight: 400;
            font-size: 1.75rem;
            color: white;
            text-shadow: 0.125rem 0.125rem 0px #1e1e1e;

            & > span {
                font-weight: 700;
            }
        }

        &__filters {
            display: flex;
            gap: 1.875rem;
        }
    }

    &__main {
        background-color: #f5f6fb;
        display: flex;
        padding: 1.875rem;
        padding-left: 3rem;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow: hidden;

        & * {
            z-index: 1;
        }

        &::before {
            content: "";
            background: url("../../assets/images/messages/circles-message.svg");
            background-size: 100% 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 17rem;
            height: 26rem;
            z-index: 0;
        }
    }
}
</style>
