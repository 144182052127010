<template>
    <svg width="100%" height="100%" viewBox="0 0 45 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M38.75 11.6727V34.7168H6.25V2.2168H28.6724L38.75 2.21701V11.6727Z" :stroke="color" stroke-width="2.5" stroke-miterlimit="10"/>
        <path d="M13 22.1183V23.9668C13 24.2429 13.2239 24.4668 13.5 24.4668H31.6667C31.9428 24.4668 32.1667 24.2429 32.1667 23.9668V19.4942C32.1667 19.4036 32.142 19.3146 32.0954 19.2369L28.6628 13.5159C28.4955 13.237 28.1105 13.1896 27.8805 13.4196L20.9565 20.3436C20.788 20.5122 20.5239 20.5383 20.3256 20.4061L17.1744 18.3053C16.9761 18.1731 16.712 18.1992 16.5435 18.3678L13.1464 21.7648C13.0527 21.8586 13 21.9857 13 22.1183Z" :fill="color"/>
        <circle cx="20.6666" cy="15.5224" r="2.55556" :fill="color"/>
        <path d="M5.75 14.6962V28.7168H1.25V9.2168H4.73439H4.73447L5.75 9.21687V14.6962Z" :stroke="color" stroke-width="2.5" stroke-miterlimit="10"/>
        <path d="M43.75 14.6962V28.7168H39.25V9.2168H42.7344H42.7345L43.75 9.21687V14.6962Z" :stroke="color" stroke-width="2.5" stroke-miterlimit="10"/>
    </svg>
</template>

<script>
export default {
    props: { 
        color: { 
            type: String, 
            default: "#8F81B2"
        } 
    },
};
</script>

<style scoped lang="scss">
</style>
