<template>
  <div class="messages">
    <div class="messages__header">
      <p>
        Mensagens
        <span>{{ totalMessages }}</span>
      </p>
      <MSelect
        :options="filterOptions"
        @updated-select="updateFilter($event)"
      />
    </div>

    <div class="messages__main">
      <div class="messages__main__list">
        <MessagePreview
          v-for="(m, i) in messages"
          :message="m"
          :active="selectedMessage == m"
          :key="i"
          @click.native="() => selectMessage(m)"
        />
      </div>
      <MessageContent
        :message="selectedMessage"
        :messagesCount="messages.length || 0"
      />
    </div>
  </div>
</template>

<script>
import MessageContent from "../../components/Messages/MessageContent.vue";
import MessagePreview from "../../components/Messages/MessagePreview.vue";
import MSelect from "../../components/Utils/MSelect.vue";
import MessagesService from "@/services/messages.service.js";

export default {
  components: { MSelect, MessagePreview, MessageContent },
  data() {
    return {
      filterOptions: ["Todas"],
      messages: [],
      messagesApi: [],
      selectedMessage: null,
    };
  },
  computed: {
    totalMessages() {
      return (this.messages ? this.messages?.length : 0)
        .toString()
        .padStart(3, 0);
    },
  },
  methods: {
    selectMessage(m) {
      if (m.status === "UNREAD") {
        MessagesService.readUserMessage(m.id);
      }
      m.status = "READ";
      this.selectedMessage = m;
    },
    updateFilter(e) {
      const filterOption = e.target.value;
      this.messages = this.messagesApi.filter((m) => {
        if (filterOption === "Todas") {
          return true;
        }
        return m.type === filterOption;
      });
    },
    fetchUserMessages() {
      MessagesService.getUserMessages()
        .then((response) => {
          let fixed_messages = response.data.map((m) => {
            if (m.type == null) {
              m.type = "Geral";
            }
          });
          this.messages = response.data;
          this.messagesApi = response.data;
          this.filterOptions.push(
            ...new Set(this.messagesApi.map((m) => m.type))
          );
        })
        .catch((error) => {
          this.$noty.error(error.message);
        });
    },
  },
  created() {
    this.fetchUserMessages();
  },
};
</script>

<style scoped lang="scss">
.messages {
  padding: 1.6rem 11rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.68rem;

    & > p {
      font-weight: 400;
      font-size: 1.75rem;
      color: white;
      text-shadow: 0.125rem 0.125rem 0px #1e1e1e;

      & > span {
        font-weight: 700;
      }
    }
  }

  &__main {
    display: flex;
    height: 36.5rem;
    max-height: 36.5rem;

    &__list {
      background-color: #242424;
      min-width: 28rem;
      max-width: 28rem;
      padding: 1.25rem 0 1.25rem 1.5rem;
      overflow: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.18rem;
    }
  }
}
</style>
