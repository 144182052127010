const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;

export default class MessagesService {

    /**
     * Get messages list.
    */
    static getUserMessages(limit = 0) {
        return httpClient.post(`${baseApiUrl}/users/messages`)
    }

    /**
     * Get user last message and number of total unread messages.
    */
    static getUserLatestMessage(limit = 0) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/messages/check`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Marked message as read by the user.
     * @param {Number} message_id
    */
    static readUserMessage(message_id) {
        return httpClient.post(`${baseApiUrl}/users/messages/read`, { message_id })
    }

    /**
     * Get social feed messages.
    */
    static getFeedMessages(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/messages/feed`, { ...params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }
}