<template>
  <div class="page-container">
    <NotificationsContainer />
    <Hero
      :percentage="parseFloat(userPerformance)"
      :health="userHealth"
      :defaultAvatar="getDefaultAvatar"
      @click.native="goToProfile"
      class="cursor-pointer"
    />
    <HeroStats
      :percentage="parseFloat(userPerformance)"
      :badges="userBadges"
      @click.native="goToProfile"
      class="cursor-pointer"
    />
    <Lounge @open-kudos-modal="openKudosModal" />
    <!-- Add padding to center lounge  -->
    <div />
    <Stats />
    <Rankings class="rankings-container" />
    <News />

    <KudosListModal
      v-if="selectedLoungeUser"
      @close="closeKudosModal"
      :show="showKudosModal"
      :targetUser="selectedLoungeUser"
    />

    <daily-summary
      :show="showDailyResume"
      @close="showDailyResume = false"
      :resumeData="dailyResumeData"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Stats from "@/components/Dashboard/BottomStats/Stats";
import Rankings from "@/components/Dashboard/Rankings/Rankings";
import KudosListModal from "@/components/Kudos/KudosListModal";
import Hero from "../../components/Dashboard/Hero/Hero.vue";
import HeroStats from "../../components/Dashboard/Hero/HeroStats.vue";
import Lounge from "../../components/Dashboard/Lounge/Lounge.vue";
import MissionIcon from "../../components/Dashboard/Missions/Atomic/MissionIcon.vue";
import News from "../../components/Dashboard/News.vue";
import DailySummary from "@/components/Summary/DailySummary.vue";
import NotificationsContainer from "../../components/Dashboard/Notification/NotificationsContainer.vue";
import router from "../../router";

import UserService from "@/services/user.service";

export default {
  components: {
    Rankings,
    Stats,
    MissionIcon,
    News,
    Hero,
    NotificationsContainer,
    HeroStats,
    Lounge,
    KudosListModal,
    DailySummary,
  },
  name: "Dashboard",
  props: [],
  data() {
    return {
      hero: {
        percentage: 98,
        health: 40,
      },
      showKudosModal: false,
      selectedLoungeUser: {
        // @TODO(Nuno) send actual user once lounge is integrated
        id: 1,
        name: "Raúl Penha",
      },
      showDailyResume: false,
      dailyResumeData: null,
    };
  },
  computed: {
    ...mapGetters("user", ["authUser", "userProfile", "getDefaultAvatar"]),
    userPerformance() {
      return this.userProfile?.performance || 0;
    },
    userHealth() {
      if (this.userPerformance <= 100) {
        return 0;
      }

      return this.userPerformance - 100;
    },
    userBadges() {
      if (this.userProfile?.badges) {
        return this.userProfile.badges.slice(0, 2);
      }
      return [];
    },
  },
  created() {
    // TODO: Reativar após correçao
    /* if (this.userProfile.first_login_of_the_day == true) {
      this.showDailyResume = true;
      this.$store.dispatch("user/updateUserFirstLogin", false);
    } */
  },
  mounted() {
    // TODO: Reativar após correçao
    //this.getDailyResume();
  },
  methods: {
    openKudosModal(lounge_user) {
      this.showKudosModal = true;
      this.selectedLoungeUser = lounge_user;
    },
    closeKudosModal() {
      this.showKudosModal = false;
      this.selectedLoungeUser = null;
    },
    goToProfile() {
      router.push({ name: "Profile" });
    },
    getDailyResume() {
      UserService.getDailyResume()
        .then((resp) => {
          this.dailyResumeData = resp;
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
@import "@/assets/scss/_mixins.scss";

.page-container {
  height: calc(100vh - $m-navbar-height);
  display: grid;
  grid-template-columns: 20% 10% auto 10% 13%;
  grid-template-rows: 37% auto 23% 8%;

  @include screen-min($screen-xl) {
    grid-template-rows: 37% auto 20% 8%;
  }

  & > :nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    z-index: 10;
  }

  & > :nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 4;
    z-index: 1;
  }

  & > :nth-child(3) {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  & > :nth-child(4) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  & > :nth-child(5) {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  & > :nth-child(6) {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 3;
  }

  & > :nth-child(7) {
    grid-column-start: 5;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  & > :nth-child(8) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 4;
    z-index: 2;
  }
}

.rankings-container {
  height: 100%;
  align-self: flex-end;
}
</style>
