var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kpis-screen kpis-page-container"},[_c('div',{staticClass:"header"},[_c('p',[_vm._v(" Total: "),_c('span',{staticClass:"counter"},[_vm._v(_vm._s(new String(_vm.kpis.length).padStart(3, "0")))])]),_c('div',{staticClass:"filter-container"},[_c('p',[_vm._v("Filtrar por Ciclo:")]),_c('MSelect',{attrs:{"options":_vm.cycles},on:{"updated-select":function($event){return _vm.updateCycle($event)}}})],1)]),_c('div',{staticClass:"kpis-main"},[_c('div',{staticClass:"columns",staticStyle:{"margin":"0","align-items":"gap: 1rem","max-height":"44.125rem","height":"44.125rem"}},[_c('div',{staticClass:"column is-4 kpis-column"},[_c('h3',{staticClass:"mb-3 secondary-title"},[_vm._v("Lista de KPIs")]),_c('div',{staticClass:"top-kpis-list"},_vm._l((_vm.kpis),function(kpi,i){return _c('div',{key:i,staticClass:"box is-clickable kpi-box",class:{
                            'is-active': kpi === _vm.kpiSelected,
                        },on:{"click":function($event){return _vm.selectKPI(kpi)}}},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-image"},[_c('div',{staticClass:"is-pulled-right progress-metric"},[_vm._v(" "+_vm._s(parseInt(_vm.getKpiResult(kpi)))+_vm._s(kpi.units)+" ")])]),_c('div',{staticClass:"media-content pl-4"},[_c('h3',{staticClass:"kpi-name"},[_vm._v(_vm._s(kpi.name))]),_c('p',[_c('small',[_vm._v("/"+_vm._s(_vm.getKpiGoal(kpi)))])]),_c('p',[_c('small',[_vm._v(_vm._s(_vm.getKpiDate(kpi)))])])])])])}),0)]),_c('div',{staticClass:"column is-8",staticStyle:{"padding":"0","margin-left":"1.25rem"}},[_c('div',{staticClass:"box kpis-box"},[_c('div',{staticClass:"has-text-centered is-flex is-justify-content-center is-align-items-center"},[_c('button',{class:[
                                'button is-tab-button',
                                {
                                    'is-active': _vm.currentTab === 'results',
                                } ],on:{"click":function($event){return _vm.changeTab('results')}}},[_vm._v(" Resultados ")]),_c('button',{class:[
                                'button is-tab-button',
                                {
                                    'is-active': _vm.currentTab === 'evolution',
                                } ],on:{"click":function($event){return _vm.changeTab('evolution')}}},[_vm._v(" Evolução ")]),_c('span',{staticClass:"kpi-last-update"},[_vm._v(" última atualização "+_vm._s(_vm.kpiLastUpdate)+" ")])]),(_vm.currentTab === 'results')?_c('div',{staticClass:"tab table-container"},[_c('table',{staticClass:"results-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.selectedKpiResults),function(kpiResult){return _c('tr',{key:kpiResult.id},[_c('td',{attrs:{"width":"55%"}},[_vm._v(" "+_vm._s(_vm.formatDate( kpiResult.date, "dd/MM" ))+" ")]),_c('td',{staticClass:"result",attrs:{"width":"45%"}},[_vm._v(" "+_vm._s(kpiResult.result)+"/"+_vm._s(kpiResult.goal)+" "),_c('span',[_vm._v(_vm._s(kpiResult.performance)+"%")])])])}),0)])]):_vm._e(),(_vm.currentTab === 'evolution')?_c('div',{staticClass:"tab",staticStyle:{"height":"100%"}},[_c('KpiLineChart',{staticStyle:{"height":"90%"},attrs:{"chart-data":_vm.lineData}}),_c('img',{staticClass:"watermark",attrs:{"src":require("@/assets/images/misc/evolution_watermark.png"),"alt":""}})],1):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Data")]),_c('th',{staticStyle:{"text-align":"right"}},[_vm._v(" Resultado ")])])])}]

export { render, staticRenderFns }