<template>
    <div class="ranking-wrapper">
        <Podium :podium="podium" style="margin-bottom: 2rem" />
        <Leaderboard
            :data="tableData"
            :kpis="kpis"
            :cycles="cycles"
            :jobs="jobs"
            :userCenter="userCenter"
            :centers="centers"
            :groupTypes="groupTypes"
            :rankingType="rankingType"
            :rankingTypeFilterIsDisabled="
                selectedRankingType == 'USER' ? true : false
            "
            :individualFilterSelected="
                selectedRankingType == 'USER' ? false : true
            "
            :centerGroupTypesSelected="selectedGroupType == 2 ? true : false"
            @cycle-filter-updated="updateCycle($event)"
            @jobs-filter-updated="updateJob($event)"
            @centers-filter-updated="updateCenter($event)"
            @groupTypes-filter-updated="updateGroupType($event)"
            @rankingType-filter-updated="updateRankingType($event)"
        />
        <ScrollTop />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import RankingsService from "@/services/rankings.service";
import KPISService from "@/services/kpis.service";
import UserService from "@/services/user.service";

import StringManipulator from "@/tools/StringManipulator";
import Leaderboard from "../../../components/Rankings/Leaderboard.vue";
import Podium from "../../../components/Rankings/Podium.vue";
import ScrollTop from "../../../components/Utils/ScrollTop.vue";

const CENTER_GROUP_TYPE_ID = 2;
const SERVICE_GROUP_TYPE_ID = 3;
// TODO: no futuro deveria vir do backend
const WORKING_JOBS = [2, 3, 4, 5];
// TODO: no futuro deveria vir do backend
const SUPERVISOR_JOBS = [
    2, 3, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
];

export default {
    name: "Rankings",
    components: { Podium, Leaderboard, ScrollTop },
    data() {
        return {
            kpis: [],
            rawTableData: [],
            selectedCycle: null,
            selectedJob: null,
            selectedCenter: null,
            selectedGroupType: null,
            userTopGroup: null,
            userCenter: null,
            selectedRankingType: "USER",
            podium: [],
            jobs: [{ value: "*", displayName: "Todas as Funções" }],
            centers: [{ value: "*", displayName: "Todos os Centros" }],
            groupTypes: [],
            rankingType: [
                { value: "USER", displayName: "Individual" },
                { value: "GROUP", displayName: "Coletivo" },
            ],
            backOfficeUrl: process.env.VUE_APP_BO_BASE_URL,
        };
    },
    created() {
        this.selectedCycle = this.currentCycleId;

        this.fetchJobs();
        this.fetchCenters();
        this.fetchGroupTypes();
        this.fetchUserTopGroup();
        this.fetchUserCenter();
        this.fetchTopKPIs();
        //this.fetchUserTopKpisRanking();
    },
    computed: {
        ...mapGetters("user", [
            "userProfile",
            "currentCycleId",
            "getCyclesList",
        ]),
        cycles() {
            return this.getCyclesList.map(function (c) {
                return {
                    value: c.id,
                    displayName: c.name,
                };
            });
        },
        tableData: function () {
            const aux = [...this.rawTableData];
            const sortedData = aux.sort((a, b) => a.position > b.position);
            // return sortedData.slice(3);
            return sortedData;
        },
    },
    methods: {
        updateCycle($event) {
            this.selectedCycle = $event.target.value;
            this.fetchRanking();
        },
        updateJob($event) {
            this.selectedJob = $event.target.value;
            this.fetchRanking();
        },
        updateCenter($event) {
            this.selectedCenter = $event.target.value;
            this.fetchRanking();
        },
        updateGroupType($event) {
            this.selectedGroupType = $event.target.value;
            this.fetchRanking();
        },
        resetDefaultCenter() {
            if (this.selectedRankingType == "GROUP") {
                this.centers = [
                    { value: "*", displayName: "Todos os Centros" },
                ];
                this.selectedCenter = null;
                this.fetchCenters();
            }
        },
        resetDefaultGroupType() {
            // TODO Hackaroo para ter um default
            if (this.selectedRankingType == "USER") {
                this.selectedGroupType = null;
                this.groupTypes = [];
                this.fetchGroupTypes();
            }
        },
        updateRankingType($event) {
            this.selectedRankingType = $event.target.value;
            this.resetDefaultGroupType();
            this.resetDefaultCenter();
            this.fetchRanking();
        },
        fetchTopKPIs() {
            KPISService.getUserTopKpis({ cycle_id: this.selectedCycle })
                .then((resp) => {
                    this.kpis = resp.map((kpi) => {
                        return {
                            id: kpi.id,
                            name: kpi.name,
                            friendlyname: kpi.friendlyname,
                        };
                    });
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        fetchJobs() {
            UserService.getJobsList({
                filter_type: true,
            })
                .then((resp) => {
                    let working_jobs = resp.filter((j) =>
                        WORKING_JOBS.includes(j.id)
                    );

                    this.jobs.push(
                        ...new Set(
                            working_jobs.map(function (j) {
                                return {
                                    value: j.id,
                                    displayName: j.name,
                                };
                            })
                        )
                    );

                    this.selectedJob = this.jobs[0].value;
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        fetchUserTopGroup() {
            RankingsService.getUserTopGroup({
                group_scope: SERVICE_GROUP_TYPE_ID, // Só mostra os do seu serviço (Técnico vs não Técnico)
            })
                .then((resp) => {
                    this.userTopGroup = resp;
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        fetchUserCenter() {
            RankingsService.getUserTopGroup({
                group_scope: CENTER_GROUP_TYPE_ID,
            })
                .then((resp) => {
                    this.userCenter = resp;
                    this.selectedCenter = this.userCenter;

                    //this.fetchUserTopKpisRanking();
                    this.fetchUserTopKpisRankingv2();
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        fetchCenters() {
            UserService.getGroupsList({
                output_scope: CENTER_GROUP_TYPE_ID, // Precisa dos centros
                group_scope: SERVICE_GROUP_TYPE_ID, // Só mostra os do seu serviço (Técnico vs não Técnico)
            })
                .then((resp) => {
                    let center_groups = resp.filter(
                        (c) => c.type_id == CENTER_GROUP_TYPE_ID
                    );

                    this.centers.push(
                        ...new Set(
                            center_groups.map(function (c) {
                                return {
                                    value: c.id,
                                    displayName: c.name,
                                };
                            })
                        )
                    );

                    this.selectedCenter =
                        this.userCenter ?? this.centers[0].value;
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        fetchGroupTypes() {
            UserService.getGroupTypesList()
                .then((resp) => {
                    let group_types = resp.filter(
                        (c) => c.id < SERVICE_GROUP_TYPE_ID
                    );

                    this.groupTypes.push(
                        ...new Set(
                            group_types.map(function (gt) {
                                return {
                                    value: gt.id,
                                    displayName: gt.name,
                                };
                            })
                        )
                    );

                    this.selectedGroupType = this.groupTypes[0].value;
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        fetchRanking() {
            if (this.selectedRankingType == "USER") {
                this.fetchUserTopKpisRankingv2();
            } else {
                this.fetchGroupsTopKpisRankingv2();
            }
        },
        fetchUserTopKpisRankingv2() {
            RankingsService.getRankingv2({
                cycle: this.selectedCycle,
                jobs: this.userProfile.job.toLowerCase().includes("supervisor")
                    ? SUPERVISOR_JOBS
                    : null,
                include_group_leaders: this.userProfile.job
                    .toLowerCase()
                    .includes("supervisor"),
                include_top_kpis: true,
                groups:
                    this.selectedCenter == "*"
                        ? [
                              this.userProfile.groups.filter(
                                  (g) => g.type_id === SERVICE_GROUP_TYPE_ID
                              )[0].id,
                          ]
                        : [
                              this.userProfile.groups.filter(
                                  (g) => g.type_id === SERVICE_GROUP_TYPE_ID
                              )[0].id,
                              this.selectedCenter,
                          ],
            })
                .then((resp) => {
                    let position = 1;
                    this.rawTableData = resp.leaderboard
                        .sort((a, b) => {
                            // Sort by performance and name
                            const aPerformance = parseFloat(a.performance);
                            const bPerformance = parseFloat(b.performance);
                            if (aPerformance === bPerformance)
                                return a.name > b.name ? 1 : -1;
                            return aPerformance > bPerformance ? -1 : 1;
                        })
                        .map((user, idx) => {
                            let top_kpis = [];

                            user.all_kpis.forEach((k) => {
                                top_kpis[k.kpi_id] = {
                                    id: k.kpi_id,
                                    name: k.kpi_name,
                                    friendlyname: k.kpi_friendlyname,
                                    performance: k.result,
                                };
                            });

                            // If there is a tie the position on the leaderboard should repeat 1º, 1º, 2º, 3º
                            if (
                                idx > 0 &&
                                resp.leaderboard[position - 1].performance !==
                                    user.performance
                            )
                                position++;

                            return {
                                id: user.id,
                                position: position,
                                username: user.display_name,
                                avatar: user.picture
                                    ? `${this.backOfficeUrl}/storage/users/${user.picture}`
                                    : null,
                                performance: user.performance ?? 0,
                                top_kpis: top_kpis,
                            };
                        });

                    // Sort as per podium order:     1
                    //                            2   3
                    this.podium = this.rawTableData.slice(0, 3).sort((a, b) => {
                        if (a.position == 2) {
                            return -1;
                        } else if (a.position == 1 && b.position == 3) {
                            return -1;
                        } else return 1;
                    });
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        fetchGroupsTopKpisRankingv2() {
            RankingsService.getRankingv2({
                principal: "GROUP",
                cycle: this.selectedCycle,
                include_top_kpis: true,
                group_scope:
                    this.selectedGroupType == "*"
                        ? null
                        : this.selectedGroupType,
                groups:
                    this.selectedCenter == "*" ||
                    this.selectedGroupType == CENTER_GROUP_TYPE_ID
                        ? [
                              this.userProfile.groups.filter(
                                  (g) => g.type_id === SERVICE_GROUP_TYPE_ID
                              )[0].id,
                          ]
                        : [
                              this.userProfile.groups.filter(
                                  (g) => g.type_id === SERVICE_GROUP_TYPE_ID
                              )[0].id,
                              this.selectedCenter ??
                                  this.userProfile.groups.filter(
                                      (g) => g.type_id === CENTER_GROUP_TYPE_ID
                                  )[0].id,
                          ],
            })
                .then((resp) => {
                    let position = 1;
                    this.rawTableData = resp.leaderboard
                        .sort((a, b) => {
                            // Sort by performance and name
                            const aPerformance = parseFloat(a.performance);
                            const bPerformance = parseFloat(b.performance);
                            if (aPerformance === bPerformance)
                                return a.name > b.name ? 1 : -1;
                            return aPerformance > bPerformance ? -1 : 1;
                        })
                        .map((group, idx) => {
                            let top_kpis = [];

                            group.all_kpis.forEach((k) => {
                                top_kpis[k.kpi_id] = {
                                    id: k.kpi_id,
                                    name: k.kpi_name,
                                    friendlyname: k.kpi_friendlyname,
                                    performance: k.result,
                                };
                            });

                            // If there is a tie the position on the leaderboard should repeat 1º, 1º, 2º, 3º
                            if (
                                idx > 0 &&
                                resp.leaderboard[position - 1].performance !==
                                    group.performance
                            )
                                position++;

                            return {
                                id: group.id,
                                position: position,
                                username: group.display_name,
                                avatar:
                                    "https://ui-avatars.com/api/?name=" +
                                    StringManipulator.getNameFirstAndLastLetters(
                                        group.display_name
                                    ),
                                performance: group.performance ?? 0,
                                top_kpis: top_kpis,
                            };
                        });

                    // Sort as per podium order:     1
                    //                            2   3
                    this.podium = this.rawTableData.slice(0, 3).sort((a, b) => {
                        if (a.position == 2) {
                            return -1;
                        } else if (a.position == 1 && b.position == 3) {
                            return -1;
                        } else return 1;
                    });
                })
                .catch((err) => {
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        handleKudosButton(lounge_user) {
            this.$emit("open-kudos-modal", lounge_user);
        },
    },
};
</script>

<style scoped>
.ranking-wrapper {
    margin: 2rem 10rem 0 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
