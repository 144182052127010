<template>
  <base-modal
    :show="show"
    maxWidth="31.25rem"
    @close="handleClose"
    backgroundColor="#221B38"
    textColor="#ffffff"
  >
    <template>
      <div class="body">
        <img
          class="player-thumbnail"
          :src="targetAvatarPicture()"
          alt=""
        />
        <p class="player-name">{{ heroName() }}</p>
        <ul class="kudos-list" v-if="!loading">
          <li
            v-for="kudo in kudos"
            :key="kudo.id"
            class="kudos-item"
            @click="selectedKudosId = kudo.id"
            :class="{ selected: selectedKudosId == kudo.id }"
          >
            {{ kudo.title }}
          </li>
        </ul>
        <button @click="giveKudos" class="main-btn">Dar Kudos</button>
      </div>
    </template>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/BaseModal";
import KudosService from "@/services/kudos.service";
import StringManipulator from "@/tools/StringManipulator";

export default {
  name: "KudosListModal",
  components: { BaseModal },
  data() {
    return {
      kudos: [],
      selectedKudosId: null,
      loading: false,
      backOfficeUrl: process.env.VUE_APP_BO_BASE_URL
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    targetUser: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getKudosList();
  },
  methods: {
    heroName() {
      return StringManipulator.getFirstAndLastName(this.targetUser.name);
    },
    targetAvatarPicture() {
      if (this.targetUser.avatar) {
        return this.backOfficeUrl + '/storage/users/' + this.targetUser.avatar
      } else {
        return "https://ui-avatars.com/api/?name=" +
                StringManipulator.getNameFirstAndLastLetters(this.targetUser.name)
      }
    },
    handleClose() {
      this.$emit("close");
    },
    getKudosList() {
      this.loading = true;
      KudosService.getList()
        .then((resp) => {
          console.log("kudos list", resp);
          this.kudos = resp;
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
        .finally(() => (this.loading = false));
    },
    giveKudos() {
      if (!this.targetUser) {
        this.$noty.error("O utilizador não está selecionado, tente novamente.");
        this.$emit("close");
      } else if (!this.selectedKudosId) {
        this.$noty.warning('Seleciona um Kudos');
      } else {
        KudosService.giveKudos({
          kudos_id: this.selectedKudosId,
          target_user_id: this.targetUser.user_id
        })
        .then((resp) => {
          this.$noty.success("O kudos foi enviado!");
          this.selectedKudosId = null;
          this.targetUser = null;
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
        .finally(() => this.$emit("close"));
      }
    }
  },
};
</script>
<style scoped>
.body {
  padding: 0.5rem 1.25rem;
  text-align: center;
}
.player-thumbnail {
  margin-bottom: 1.25rem;
  border-radius: 50%;
}

.player-name {
  font-weight: 700;
  font-size: 1.625rem;
  color: #1eb28d;
}

.kudos-list {
  margin: 1.25rem auto;
}
.kudos-item {
  padding: 0.3125rem 0.625rem;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.625rem;
  margin: 0.3125rem 0;
}
.kudos-item:hover {
  background: #362a52;
}
.kudos-item.selected {
  background: #362a52;
}

.product-title {
  color: #403c56;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9375rem;
  letter-spacing: 0.01em;
  margin-bottom: 0.8125rem;
}
.product-description {
  color: #403c56;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: 0.01em;
  margin-bottom: 2.5rem;
}

.buy-btn {
  max-width: 13.75rem;
  margin: 0.3125rem auto;
  padding: 0.4375rem;
  background: #6c4bac;
  cursor: pointer;
  text-align: center;
}
.buy-btn.acquired {
  cursor: grab;
}
.buy-text {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.875rem;
  text-transform: uppercase;
  color: #ffffff;
  /* margin-bottom: 0.125rem; */
}
.price-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8a69ca;
}
.price-tag .price {
  margin-left: 0.3125rem;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.75rem;
  color: #ffffff;
}
</style>