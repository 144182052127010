<template>
  <div class="py-4 onboarding-screen">
    <img src="../../assets/images/logo_icon.svg" style="margin-bottom: 1em; height: 6rem;" alt="" />
    <!-- <img src="../../assets/images/xmas_logo.png" style="margin-bottom: 1em; height: 6rem;" alt="" /> -->
    <div class="slider">
      <div class="slides">
        <div class="slide">
          <img :src="activeSlide.image" style="width: 100%; height: 100%;" alt="">
        </div>
      </div>
    </div>

    <div class="slider-indicators">
      <ul>
        <li v-for="slide in slides" :key="slide.index" :class="{active:slide.index === activeIdx}" @click="change(slide.index)">
          
        </li>
        <!-- <li v-for="i in slides"></li> -->
        <!-- <li class="active"></li>
        <li></li>
        <li></li> -->
      </ul>
    </div>
    

    <div class="bottom-tab">
      <div class="bottom-container is-flex">
        <img src="../../assets/images/onboarding/onboarding_hero.svg" class="silhouette" style="max-width: 25em; z-index: 2;" alt="" />
        <div class="slider-content">
          <div class="slider-text has-text-left">
            <h2 class="is-size-3 has-text-white">{{ activeSlide.title }}</h2>
            <p class="has-text-white">{{ activeSlide.text }}</p>
          </div>
          <button @click="nextStep" class="main-btn">Continuar</button>
        </div>
      </div>
      <img src="../../assets/images/onboarding/banner.svg" class="frame" alt="">
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StringManipulator from "../../tools/StringManipulator";

export default {
  name: "Onboarding",
  props: [

  ],
  data() {
    return {
      activeIdx: 0,
    };
  },
  created() {
    
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters('user', ['authUser']),
    slides() {
      return [
        {
          index: 0,
          image: require('../../assets/images/onboarding/onboarding_1.png'),
          title: "Bem-Vind@!",
          text: `Olá ${StringManipulator.getFirstAndLastName(this.authUser.name)}, bem vind@ à tua Arena! Nesta nova forma de olhares para o teu dia-a-dia, vamos desafiar-te a chegares mais longe e a mostrares-nos o teu lado de super-herói ou super-heroína ou ambos!`
        },
        {
          index: 1,
          image: require('../../assets/images/onboarding/onboarding_2.png'),
          title: " O Que Podes Fazer?",
          text: "Aqui podes acompanhar o teu progresso e o dos teus pares, nos vários indicadores, escolher as missões que queres aceitar, ganhar medalhas por metas excepcionais e transformares-te no que já sabemos que consegues ser! "
        },
        {
          index: 2,
          image: require('../../assets/images/onboarding/onboarding_3.png'),
          title: "O Que Podes Ganhar?",
          text: "Supera as tuas metas, leva a tua equipa à vitória e recebe pontos pelo teu desempenho para os trocares por prémios e benefícios. Achas que consegues superar todos os teus colegas na MyArena? Vamos a isso!"
        }
      ]
    },
    activeSlide() {
      return this.slides.filter(slide => {
        return slide.index == this.activeIdx;
      })[0];
    }
  },
  methods: {
    nextStep() {
      if (this.activeIdx < this.slides.length - 1) {
        this.activeIdx++;
      } else {
        //this.$root.handleInternalNavigationTo("TermsAndConditions"); 
        this.$root.handleInternalNavigationTo("AvatarSelection"); 
      }
    },
    change(index) {
      this.activeIdx = index;
    }
  },
};
</script>

<style scoped >
.onboarding-screen {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-image: url("../../assets/images/background.svg");
  /* background-image: url('../../assets/images/xmas_bg.svg'); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

ul {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
}
li {
  cursor: pointer;
  width: 1.5em;
  height: 12px;
  background-color: #ffffff;
  transition: all .25s ease;

}
li:not(:last-of-type) {
  margin-right: 1em;
}
li.active {
  height: 1.5em;
  background-color: #1EB28D;
}

.bottom-tab {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.bottom-container {
  margin: 150px auto 0;
  /* max-width: 900px; */
  padding: 0 40px;
  bottom: 10px;
  align-items: center;
}
.silhouette {
  position: absolute;
  bottom: 0;
  /* left: 0; */
  /* width: 100%; */
}
.frame {
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  z-index: 1; 
}

.slider-content {
  max-width: 60rem;
  z-index: 2;
  margin: 0 auto 2em;
  display: flex;
  align-items: center;
}
.slider-text {
  padding: 20px 30px;
  z-index: 3;
}

.main-btn {
  max-width: 290px;
  z-index: 3;
}

.slider {
  width: 100%;
}
.slides {
  max-width: 923px;
  width: 100%;
  margin: 0 auto;
}
.slide {
  padding: 0 2rem;
  width: 60em;
  height: 24.2em;
  max-width: 100%;
  margin: 0 auto;
  /* width: 100%; */
  /* height: 414px; */
  /* background: #000; */
}


.fadeout {
  animation: fadeout 2s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>